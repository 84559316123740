import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { formatNumValue } from '../../../../../../utils/helpers/numbers';
import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './ActiveSubstancesCell.styles';

interface IActiveSubstancesCell {
  activeSubstanceValue?: number | string;
  dosage?: number;
  dataTestId?: string;
  isTitleRow?: boolean;
}

const ActiveSubstancesCell: FC<IActiveSubstancesCell> = ({
  activeSubstanceValue,
  dataTestId,
  dosage,
  isTitleRow,
}) => {
  const { ref } = useShowTooltip();

  const isActiveSubstanceNumber = typeof activeSubstanceValue === 'number';

  return (
    <AutoTooltip
      content={parseFloat((dosage * Number(activeSubstanceValue)).toFixed(6))}
      position="top"
      disabled={!isActiveSubstanceNumber}
    >
      <Styled.Item
        data-test-id={`dosage-cell-item-${dataTestId}`}
        $isTitleRow={isTitleRow}
        ref={ref}
      >
        {isActiveSubstanceNumber
          ? formatNumValue(activeSubstanceValue * dosage)
          : activeSubstanceValue}
      </Styled.Item>
    </AutoTooltip>
  );
};

ActiveSubstancesCell.displayName = 'ActiveSubstancesCell';

export default memo(ActiveSubstancesCell);
