import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { IExperimentFactTable } from '../../../models/as-fields/experiments/ExperimentFactTable';

type TRequest = { auditId: string; stepId: string };

type TResponse = IExperimentFactTable;

export const getFactTableByCopiedAudit: TApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ auditId }) => `/api/as-fields/experimentSteps/fromAudit/${auditId}`,
  method: 'GET',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
