import React, { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ZonesBlockedTypeIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__zones__blocked-type-icon.
   */
  dataTestId: string;
}

const ZonesBlockedTypeIcon: FC<IProps> = ({ dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__zones__blocked-type-icon`);

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#F3F4F8" />
      <path
        d="M15.125 9.88889V8.77778C15.125 7.24366 13.7259 6 12 6C10.2741 6 8.875 7.24366 8.875 8.77778V9.88889M10 16H14C15.0501 16 15.5751 16 15.9762 15.8183C16.3291 15.6586 16.6159 15.4036 16.7956 15.09C17 14.7334 17 14.2668 17 13.3333V12.5556C17 11.6221 17 11.1554 16.7956 10.7989C16.6159 10.4853 16.3291 10.2303 15.9762 10.0706C15.5751 9.88889 15.0501 9.88889 14 9.88889H10C8.9499 9.88889 8.42485 9.88889 8.02377 10.0706C7.67096 10.2303 7.38413 10.4853 7.20436 10.7989C7 11.1554 7 11.6221 7 12.5556V13.3333C7 14.2668 7 14.7334 7.20436 15.09C7.38413 15.4036 7.67096 15.6586 8.02377 15.8183C8.42485 16 8.9499 16 10 16Z"
        stroke="#7E8B9D"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Wrapper>
  );
};

ZonesBlockedTypeIcon.displayName = 'ZonesBlockedTypeIcon';

export default memo(ZonesBlockedTypeIcon);
