import { useContext } from 'react';

import { DropdownFactoryContext, IDropdownFactoryContext } from '../../DropdownFactoryContext';

const useDropdownFactoryContext = (): IDropdownFactoryContext => {
  const context = useContext(DropdownFactoryContext);

  return context;
};

export default useDropdownFactoryContext;
