import React, { FC, memo } from 'react';

import { TDropdownFactoryOption as TOption } from '../../types';

import { DropdownFactoryOptionDefault as OptionDefault } from './components';

const DropdownFactoryOption: FC<TOption> = props => {
  switch (props.type) {
    case 'default':
      return <OptionDefault {...props} />;

    default:
      return <></>;
  }
};

DropdownFactoryOption.displayName = 'DropdownFactoryOption';

export default memo(DropdownFactoryOption);
