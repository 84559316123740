import { FC } from 'react';
import { Typography } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../common/utils/hooks/locators';

import { ReactComponent as Counter } from './assets/icons/counter.svg';
import Styled from './ZonesAddFactZoneModalTitle.styles';

interface IProps {
  mode: 'create' | 'edit';
}

const ZonesAddFactZoneModalTitle: FC<IProps> = ({ mode }) => {
  const getDataTestId = useDataTestIdV2('zones__add-fact-zone__title');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Typography dataTestId={getDataTestId()['data-test-id']} variant={'h5'}>
        {mode === 'edit' ? 'Фактический участок' : 'Добавить фактический участок'}
      </Typography>

      {mode === 'create' ? <Counter {...getDataTestId('counter')} /> : null}
    </Styled.Wrapper>
  );
};

ZonesAddFactZoneModalTitle.displayName = 'ZonesAddFactZoneModalTitle';

export default ZonesAddFactZoneModalTitle;
