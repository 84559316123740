import styled, { css } from 'styled-components';

const Wrapper = styled.svg<{ $isDisabled: boolean; $isActive: boolean }>`
  ${({ $isDisabled }) => {
    if ($isDisabled) return;

    return css`
      cursor: pointer;
    `;
  }};

  ${({ $isActive }) => {
    if (!$isActive) {
      return css`
        transition: all 0.15s ease-in-out;

        & circle {
          fill: #f3f4f8;
        }

        & path {
          fill: #7e8b9d;
        }

        &:hover {
          transition: all 0.15s ease-in-out;

          & circle {
            fill: #e9ecf0;
          }

          & path {
            fill: #7e8b9d;
          }
        }
      `;
    }

    return css`
      transition: all 0.15s ease-in-out;

      & circle {
        fill: #fef5f5;
      }

      & path {
        fill: #df2e22;
      }

      &:hover {
        transition: all 0.15s ease-in-out;

        & circle {
          fill: #fcdddd;
        }

        & path {
          fill: #df2e22;
        }
      }
    `;
  }}
`;

const StyledZonesExperimentTypeIcon = {
  Wrapper,
};

export default StyledZonesExperimentTypeIcon;
