import styled, { createGlobalStyle } from 'styled-components';

const Content = styled.div``;

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: fixed;

  top: 0;
  left: 0;

  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);

  z-index: 20000;
`;

const HeaderHider = createGlobalStyle`
  .header-wrapper {
    z-index: 0;
  }
`;

const StyledOverlay = {
  Content,
  Wrapper,
  HeaderHider,
};

export default StyledOverlay;
