import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ZonesExperimentTypeIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__zones__experiment-type-icon.
   */
  dataTestId: string;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
}

const ZonesExperimentTypeIcon: FC<IProps> = ({
  dataTestId,
  onClick,
  isDisabled = false,
  isActive = false,
}) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__zones_control-type-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (isDisabled) return;

    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onClick={handleClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isDisabled={isDisabled}
      $isActive={isActive}
    >
      <circle cx="12" cy="12" r="12" fill="#F3F4F8" />
      <path
        d="M16 11.9822C16 12.7888 15.907 13.5006 15.7209 14.1174C15.5349 14.7343 15.2662 15.2622 14.9147 15.7011C14.5736 16.1281 14.1499 16.4543 13.6434 16.6797C13.1473 16.8932 12.5943 17 11.9845 17C11.4057 17 10.8734 16.8932 10.3876 16.6797C9.90181 16.4543 9.47804 16.1281 9.11628 15.7011C8.76486 15.2622 8.49096 14.7343 8.29457 14.1174C8.09819 13.5006 8 12.7888 8 11.9822C8 10.9146 8.16021 10.013 8.48062 9.27758C8.80103 8.53025 9.26098 7.96679 9.86047 7.58719C10.4703 7.19573 11.1886 7 12.0155 7C12.801 7 13.4884 7.19573 14.0775 7.58719C14.677 7.96679 15.1473 8.53025 15.4884 9.27758C15.8295 10.013 16 10.9146 16 11.9822ZM10 11.9822C10 12.6584 10.0672 13.2396 10.2016 13.726C10.3463 14.2005 10.5633 14.5623 10.8527 14.8114C11.1525 15.0605 11.5349 15.1851 12 15.1851C12.4755 15.1851 12.8579 15.0605 13.1473 14.8114C13.447 14.5623 13.6641 14.2005 13.7984 13.726C13.9328 13.2396 14 12.6584 14 11.9822C14 11.2942 13.9276 10.7189 13.7829 10.2562C13.6486 9.78173 13.4315 9.42586 13.1318 9.18861C12.8424 8.9395 12.4651 8.81495 12 8.81495C11.2972 8.81495 10.7855 9.08778 10.4651 9.63345C10.155 10.1791 10 10.962 10 11.9822Z"
        fill="#7E8B9D"
      />
    </Styled.Wrapper>
  );
};

ZonesExperimentTypeIcon.displayName = 'ZonesExperimentTypeIcon';

export default memo(ZonesExperimentTypeIcon);
