import { useCallback } from 'react';
import L from 'leaflet';

import { useStore } from '../../../../utils/helpers/mobx';
import { MapController } from '../../mobx/controllers';
import '../../assets/styles/map.styles.css';
import { MapStore } from '../../mobx/stores';
import { IFWExperimentCultureZone } from '../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

import { useMapHelpers } from './utils/helpers';

interface IRegisterMap {
  (mapKey: string): void;
}

interface IDrawCultureZoneList {
  (fwExpCultureZoneList: IFWExperimentCultureZone[]): IFWExperimentCultureZone[];
}

interface IChangeCultureZoneStyle {
  (fwExpCultureZone: IFWExperimentCultureZone): void;
}

interface IChangeCultureZoneName {
  (polyId: number, name: string);
}

interface ISelectPolygon {
  (polyId: number): void;
}

interface IClearStore {
  (): void;
}

interface IUseMap {
  (): {
    selectedPolygonId: number | null;
    mapInstance: L.Map | null;
    registerMap: IRegisterMap;
    drawCultureZone: (fwZone: IFWExperimentCultureZone) => number;
    drawCultureZoneList: IDrawCultureZoneList;
    changeCultureZoneStyle: IChangeCultureZoneStyle;
    changeCultureZoneName: IChangeCultureZoneName;
    selectPolygon: ISelectPolygon;
    clearStore: IClearStore;
    clearPolygonList: () => void;
    removePolygon: (id: number) => void;
  };
}

const useMap: IUseMap = () => {
  const { prevSelectedPolygonData, instance } = useStore(MapStore);

  const {
    initInstance,
    createPolygon,
    changePolygonStyle,
    changePolygonTooltip,
    setPolygon,
    setPolygonList,
    selectPolygon,
    clearStore,
    clearPolygonList,
    removePolygon,
  } = useStore(MapController);

  const handleRegisterMap = useCallback<IRegisterMap>(mapKey => {
    initInstance(mapKey);
  }, []);

  const drawCultureZone = (fwZone: IFWExperimentCultureZone): number => {
    const { createCultureZoneTooltip, createCultureZoneStyle } = useMapHelpers;

    if (!fwZone?.cultureZone?.geometry?.coordinates) {
      return;
    }

    const tooltip = createCultureZoneTooltip(fwZone.name);
    const style = createCultureZoneStyle(fwZone.styleType);

    const polygon = createPolygon(fwZone.cultureZone?.geometry, style, tooltip, {
      isAllowToSelectPolygon: Boolean(fwZone.type),
    }) as any;

    if (!polygon) {
      return;
    }

    setPolygon(polygon);

    return polygon._leaflet_id;
  };

  const handleDrawCultureZoneList = useCallback<IDrawCultureZoneList>(fwExpCultureZoneList => {
    const { createCultureZoneTooltip, createCultureZoneStyle } = useMapHelpers;

    const zoneListWithPolyId: IFWExperimentCultureZone[] = [];
    const polygonList: L.Polygon[] = [];

    const getPolyId = (zone: IFWExperimentCultureZone): number | undefined => {
      if (!zone?.cultureZone?.geometry?.coordinates) {
        return;
      }

      const tooltip = createCultureZoneTooltip(zone.name);
      const style = createCultureZoneStyle(zone.styleType);

      const polygon = createPolygon(zone.cultureZone?.geometry, style, tooltip, {
        isAllowToSelectPolygon: Boolean(zone.type),
      }) as any;

      if (!polygon) {
        return;
      }

      polygonList.push(polygon);

      return polygon._leaflet_id;
    };

    fwExpCultureZoneList.forEach(zone => {
      const polyId = getPolyId(zone);

      if (!polyId) return;

      const fwZoneWithPolyId: IFWExperimentCultureZone = {
        ...zone,
        polyId,
      };

      if (fwZoneWithPolyId?.factFwZone) {
        const factPolyId = getPolyId(fwZoneWithPolyId.factFwZone);

        if (factPolyId) {
          fwZoneWithPolyId.factFwZone = { ...fwZoneWithPolyId.factFwZone, polyId: factPolyId };
        }
      }

      zoneListWithPolyId.push(fwZoneWithPolyId);
    });

    setPolygonList(polygonList);

    return zoneListWithPolyId;
  }, []);

  const handleChangeCultureZoneStyle = useCallback<IChangeCultureZoneStyle>(
    ({ polyId, styleType }) => {
      const { createCultureZoneStyle } = useMapHelpers;

      const style = createCultureZoneStyle(styleType);

      changePolygonStyle(polyId, style);
    },
    []
  );

  const handleChangeCultureZoneName = useCallback<IChangeCultureZoneName>((polyId, name) => {
    const { createCultureZoneTooltip } = useMapHelpers;

    const tooltip = createCultureZoneTooltip(name);

    changePolygonTooltip(polyId, tooltip);
  }, []);

  const handleSelectPolygon = useCallback<ISelectPolygon>(polyId => {
    selectPolygon(polyId);
  }, []);

  const handleClearStore = useCallback<IClearStore>(() => {
    clearStore();
  }, []);

  return {
    selectedPolygonId: prevSelectedPolygonData?.id,
    mapInstance: instance,
    registerMap: handleRegisterMap,
    drawCultureZone,
    drawCultureZoneList: handleDrawCultureZoneList,
    changeCultureZoneStyle: handleChangeCultureZoneStyle,
    changeCultureZoneName: handleChangeCultureZoneName,
    selectPolygon: handleSelectPolygon,
    clearStore: handleClearStore,
    clearPolygonList,
    removePolygon,
  };
};

export type { IDrawCultureZoneList, IChangeCultureZoneStyle, IChangeCultureZoneName };

export default useMap;
