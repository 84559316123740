import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import {
  IExperimentCultureZone,
  IExperimentFactDataNutritionHistoryItemUpdate,
  IExperimentFactDataNutritionHistoryProtectionItemUpdate,
  IExperimentFactDataStepUpdate,
  IExperimentFactDataZoneUpdate,
  IExperimentStep,
} from '../../../../../../../../../../../api/models/as-fields/experiments';
import { TExecutionZone } from '../../../types';
import { ISelectOption } from '../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { IExperimentFactTableAudit } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';

@provide.singleton()
class ExecutionStore {
  /**
   * Отвечает за показ лоадеров на странице.
   */
  private _isPageLoading = true;

  /**
   * Перезагружает все запросы на странице (например после сохранения).
   */
  private _isNeedToRefreshPage = false;

  private _isEdited = false;

  private _selectedZoneId: string | null;

  private _unhandledInvalidRowId?: string = null;

  private _scrollStep = 0;

  private _zonesList: TExecutionZone[] = [];

  private _bbchList: ISelectOption<IDictionaryEntity>[] = [];

  private _stepsIds: Set<string> = new Set();

  private _fertilizerIdsByStepId: Map<string, Set<string>> = new Map();

  private _protectionIdsByStepId: Map<string, Set<string>> = new Map();

  private _experimentZonesById: Map<string, IExperimentCultureZone> = new Map();

  private _measureList: IDictionaryEntity[] = [];

  /**
   * Коллекция формата:
   * идентификатор зоны к модели обновленной зоны.
   */
  private _zonesForUpdateByZoneId: Map<string, IExperimentFactDataZoneUpdate> = new Map();

  /**
   * Коллекция формата:
   * идентификатор зоны к коллекции:
   * идентификатор шага к модели обновленного шага.
   */
  private _stepsForUpdateByStepIdByZoneId: Map<string, Map<string, IExperimentFactDataStepUpdate>> =
    new Map();

  /**
   * Коллекция формата:
   * идентификатор зоны к коллекции:
   * шаг опыта к коллекции:
   * идентификатор удобрения [fertilizerId] к модели обновленной ТМЦ.
   */
  private _itemsForUpdateByFertilizerIdByStepIdByZoneId: Map<
    string,
    Map<string, Map<string, IExperimentFactDataNutritionHistoryItemUpdate>>
  > = new Map();

  /**
   * Коллекция формата:
   * идентификатор зоны к коллекции:
   * шаг опыта к коллекции:
   * идентификатор СЗР [protectionId] к модели обновленной ТМЦ.
   */
  private _itemsForUpdateByProtectionIdByStepIdByZoneId: Map<
    string,
    Map<string, Map<string, IExperimentFactDataNutritionHistoryProtectionItemUpdate>>
  > = new Map();

  /**
   * Коллекция формата:
   * коллекция идентификаторов атрибутов, значения которых указны с ошибкой,
   * к идентификатору зоны.
   */
  private _invalidRowIdsByZoneId: Map<string, Set<string>> = new Map();

  /**
   * Коллекция формата:
   * идентификатор зоны к коллекции:
   * идентификатор ряда к опции.
   */
  private _selectedDictionaryOptionsByRowIdByZoneId: Map<
    string,
    Map<string, ISelectOption<IDictionaryEntity>>
  > = new Map();

  private _completedAuditOptionList: ISelectOption<IExperimentStep>[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get isPageLoading() {
    return this._isPageLoading;
  }

  public get isNeedToRefreshPage(): boolean {
    return this._isNeedToRefreshPage;
  }

  get isEdited() {
    return this._isEdited;
  }

  get selectedZoneId() {
    return this._selectedZoneId;
  }

  get currentZoneInfo() {
    return this.zoneList.find(zone => zone.id === this.selectedZoneId);
  }

  get zoneList() {
    return this._zonesList;
  }

  get bbchList() {
    return this._bbchList;
  }

  get stepIdList(): string[] {
    return [...this._stepsIds.values()];
  }

  get invalidRowIdsByZoneId() {
    return this._invalidRowIdsByZoneId;
  }

  get unhandledInvalidRowId() {
    return this._unhandledInvalidRowId;
  }

  get scrollStep() {
    return this._scrollStep;
  }

  get measureList() {
    return this._measureList;
  }

  getFertilizerIdList = (stepId: string): string[] => {
    const fertilizerIds = this._fertilizerIdsByStepId.get(stepId);

    if (fertilizerIds) {
      return [...fertilizerIds.values()];
    }

    return [];
  };

  getProtectionIdList = (stepId: string): string[] => {
    const protectionIds = this._protectionIdsByStepId.get(stepId);

    if (protectionIds) {
      return [...protectionIds.values()];
    }

    return [];
  };

  get zoneForUpdateList(): IExperimentFactDataZoneUpdate[] {
    return [...this._zonesForUpdateByZoneId.values()];
  }

  get currentStepForUpdateList(): IExperimentFactDataStepUpdate[] {
    const steps = this._stepsForUpdateByStepIdByZoneId.get(this._selectedZoneId);

    if (steps) {
      return [...steps.values()];
    }

    return [];
  }

  getZoneForUpdate = (zoneId: string): IExperimentFactDataZoneUpdate => {
    return this._zonesForUpdateByZoneId.get(zoneId);
  };

  hasZoneForUpdate = (zoneId: string): boolean => {
    return this._zonesForUpdateByZoneId.has(zoneId);
  };

  getStepForUpdateList = (zoneId: string): IExperimentFactDataStepUpdate[] => {
    const steps = this._stepsForUpdateByStepIdByZoneId.get(zoneId);

    if (steps) {
      return [...steps.values()];
    }

    return [];
  };

  getStepForUpdate = (zoneId: string, stepId: string): IExperimentFactDataStepUpdate => {
    return this._stepsForUpdateByStepIdByZoneId.get(zoneId)?.get?.(stepId);
  };

  getExperimentCultur = (zoneId: string, stepId: string): IExperimentFactDataStepUpdate => {
    return this._stepsForUpdateByStepIdByZoneId.get(zoneId)?.get?.(stepId);
  };

  hasStepForUpdate = (zoneId: string, stepId: string): boolean => {
    return this._stepsForUpdateByStepIdByZoneId.get(zoneId)?.has?.(stepId);
  };

  hasStepForUpdateList = (zoneId: string): boolean => {
    return this._stepsForUpdateByStepIdByZoneId.has(zoneId);
  };

  getItemForUpdateList = (
    zoneId: string,
    stepId: string
  ): IExperimentFactDataNutritionHistoryItemUpdate[] => {
    const items = this._itemsForUpdateByFertilizerIdByStepIdByZoneId.get(zoneId)?.get?.(stepId);

    if (items) {
      return [...items.values()];
    }

    return [];
  };

  hasItemForUpdateList = (zoneId: string, stepId: string): boolean => {
    return this._itemsForUpdateByFertilizerIdByStepIdByZoneId.get(zoneId)?.has?.(stepId);
  };

  hasProtectionItemForUpdateList = (zoneId: string, stepId: string): boolean => {
    return this._itemsForUpdateByProtectionIdByStepIdByZoneId.get(zoneId)?.has?.(stepId);
  };

  getItemForUpdate = (
    zoneId: string,
    stepId: string,
    fertilizerId: string
  ): IExperimentFactDataNutritionHistoryItemUpdate => {
    return this._itemsForUpdateByFertilizerIdByStepIdByZoneId
      .get(zoneId)
      ?.get?.(stepId)
      ?.get?.(fertilizerId);
  };

  getProtectionItemForUpdateList = (
    zoneId: string,
    stepId: string
  ): IExperimentFactDataNutritionHistoryProtectionItemUpdate[] => {
    const protectionItems = this._itemsForUpdateByProtectionIdByStepIdByZoneId
      .get(zoneId)
      ?.get?.(stepId);

    if (protectionItems) {
      return [...protectionItems.values()];
    }

    return [];
  };

  getProtectionItemForUpdate = (
    zoneId: string,
    stepId: string,
    protectionId: string
  ): IExperimentFactDataNutritionHistoryProtectionItemUpdate => {
    return this._itemsForUpdateByProtectionIdByStepIdByZoneId
      .get(zoneId)
      ?.get?.(stepId)
      ?.get?.(protectionId);
  };

  get invalidRowIdList(): string[] {
    return [...this._invalidRowIdsByZoneId.values()].flatMap(collection => [
      ...collection.values(),
    ]);
  }

  hasInvalidRowByZoneId(zoneId: string): boolean {
    return this._invalidRowIdsByZoneId.get(zoneId)?.size > 0;
  }

  setMeasureInfoList = (newMeasureList: IDictionaryEntity[]): void => {
    this._measureList = newMeasureList;
  };

  setScrollStep(step: number) {
    this._scrollStep = step;
  }

  getSelectedDictionaryOption = (
    zoneId: string,
    rowId: string
  ): ISelectOption<IDictionaryEntity> => {
    return this._selectedDictionaryOptionsByRowIdByZoneId.get(zoneId)?.get?.(rowId);
  };

  getExperimentZone = (id: string): IExperimentCultureZone => {
    return this._experimentZonesById.get(id);
  };

  get completedAuditOptionList(): ISelectOption<IExperimentStep>[] {
    return this._completedAuditOptionList;
  }

  get hasCompletedAuditOptionList(): boolean {
    return this._completedAuditOptionList.length > 0;
  }

  setIsPageLoading = (value: boolean): void => {
    this._isPageLoading = value;
  };

  public setIsNeedToRefreshPage = (value: boolean): void => {
    this._isNeedToRefreshPage = value;
  };

  setIsEdited = (isEdited): void => {
    this._isEdited = isEdited;
  };

  setSelectedZoneId = (zoneId: string): void => {
    this._selectedZoneId = zoneId;
  };

  setZoneList = (zoneList: TExecutionZone[]): void => {
    this._zonesList = zoneList;
  };

  setBbchList = (bbchList: ISelectOption<IDictionaryEntity>[]): void => {
    this._bbchList = bbchList;
  };

  setStepIdList = (stepIdList: string[]): void => {
    this._stepsIds = new Set(stepIdList);
  };

  setStepId = (stepId: string): void => {
    this._stepsIds.add(stepId);
  };

  setUnhandledInvalidRowId = (rowId: string) => {
    this._unhandledInvalidRowId = rowId;
  };

  setFertilizerIdList = (stepId: string, fertilizerIdList: string[]): void => {
    const collection = new Set(fertilizerIdList);

    this._fertilizerIdsByStepId.set(stepId, collection);
  };

  setFertiliserId = (stepId: string, fertilizerId: string): void => {
    const hasCollection = this._fertilizerIdsByStepId.has(stepId);

    if (hasCollection) {
      this._fertilizerIdsByStepId.get(stepId).add(fertilizerId);
    } else {
      const collection = new Set(fertilizerId);

      this._fertilizerIdsByStepId.set(stepId, collection);
    }
  };

  setProtectionIdList = (stepId: string, protectionIdList: string[]): void => {
    const collection = new Set(protectionIdList);

    this._protectionIdsByStepId.set(stepId, collection);
  };

  setProtectionId = (stepId: string, protectionId: string): void => {
    const hasCollection = this._protectionIdsByStepId.has(stepId);

    if (hasCollection) {
      this._protectionIdsByStepId.get(stepId).add(protectionId);
    } else {
      const collection = new Set(protectionId);

      this._protectionIdsByStepId.set(stepId, collection);
    }
  };

  setZoneForUpdate = (
    zoneForUpdate: IExperimentFactDataZoneUpdate,
    options?: {
      isUpdate?: boolean;
    }
  ): void => {
    this.setIsEdited(true);

    if (options?.isUpdate) {
      const previouslyAddedZone = this._zonesForUpdateByZoneId.get(
        zoneForUpdate.cultureZoneExperimentId
      );

      if (!previouslyAddedZone) {
        this._zonesForUpdateByZoneId.set(zoneForUpdate.cultureZoneExperimentId, zoneForUpdate);
      } else {
        this._zonesForUpdateByZoneId.set(zoneForUpdate.cultureZoneExperimentId, {
          ...previouslyAddedZone,
          ...zoneForUpdate,
        });
      }

      return;
    }

    this._zonesForUpdateByZoneId.set(zoneForUpdate.cultureZoneExperimentId, zoneForUpdate);
  };

  setStepForUpdate = (
    zoneId: string,
    stepForUpdate: IExperimentFactDataStepUpdate,
    options?: {
      isUpdate?: boolean;
    }
  ): void => {
    this.setIsEdited(true);

    const steps = this._stepsForUpdateByStepIdByZoneId.get(zoneId);

    if (!steps) {
      const collection: Map<string, IExperimentFactDataStepUpdate> = new Map();
      collection.set(stepForUpdate.id, stepForUpdate);

      this._stepsForUpdateByStepIdByZoneId.set(zoneId, collection);

      return;
    }

    if (options?.isUpdate) {
      const previouslyAddedStep = steps.get(stepForUpdate.id);

      if (!previouslyAddedStep) {
        this._stepsForUpdateByStepIdByZoneId.get(zoneId).set(stepForUpdate.id, stepForUpdate);
      } else {
        this._stepsForUpdateByStepIdByZoneId.get(zoneId).set(stepForUpdate.id, {
          ...previouslyAddedStep,
          ...stepForUpdate,
        });
      }

      return;
    }

    this._stepsForUpdateByStepIdByZoneId.get(zoneId).set(stepForUpdate.id, stepForUpdate);
  };

  setItemForUpdate = (
    zoneId: string,
    stepId: string,
    itemForUpdate: IExperimentFactDataNutritionHistoryItemUpdate,
    options?: {
      isUpdate?: boolean;
      isInitialise?: boolean;
    }
  ): void => {
    if (!options?.isInitialise) {
      this.setIsEdited(true);
    }

    const steps = this._itemsForUpdateByFertilizerIdByStepIdByZoneId.get(zoneId);

    if (!steps) {
      const itemCollection: Map<string, IExperimentFactDataNutritionHistoryItemUpdate> = new Map();
      itemCollection.set(itemForUpdate.fertilizerId, itemForUpdate);

      const stepCollection: Map<
        string,
        Map<string, IExperimentFactDataNutritionHistoryItemUpdate>
      > = new Map();
      stepCollection.set(stepId, itemCollection);

      this._itemsForUpdateByFertilizerIdByStepIdByZoneId.set(zoneId, stepCollection);

      return;
    }

    const items = this._itemsForUpdateByFertilizerIdByStepIdByZoneId.get(zoneId).get(stepId);

    if (!items) {
      const itemCollection: Map<string, IExperimentFactDataNutritionHistoryItemUpdate> = new Map();
      itemCollection.set(itemForUpdate.fertilizerId, itemForUpdate);

      this._itemsForUpdateByFertilizerIdByStepIdByZoneId.get(zoneId).set(stepId, itemCollection);

      return;
    }

    if (options?.isUpdate) {
      const previouslyAddedItem = items.get(itemForUpdate.fertilizerId);

      if (!previouslyAddedItem) {
        this._itemsForUpdateByFertilizerIdByStepIdByZoneId
          .get(zoneId)
          .get(stepId)
          .set(itemForUpdate.fertilizerId, itemForUpdate);
      } else {
        this._itemsForUpdateByFertilizerIdByStepIdByZoneId
          .get(zoneId)
          .get(stepId)
          .set(itemForUpdate.fertilizerId, { ...previouslyAddedItem, ...itemForUpdate });
      }

      return;
    }

    this._itemsForUpdateByFertilizerIdByStepIdByZoneId
      .get(zoneId)
      .get(stepId)
      .set(itemForUpdate.fertilizerId, itemForUpdate);
  };

  setProtectionItemForUpdate = (
    zoneId: string,
    stepId: string,
    protectionItemForUpdate: IExperimentFactDataNutritionHistoryProtectionItemUpdate,
    options?: {
      isUpdate?: boolean;
      isInitialise?: boolean;
    }
  ): void => {
    if (!options?.isInitialise) {
      this.setIsEdited(true);
    }

    const steps = this._itemsForUpdateByProtectionIdByStepIdByZoneId.get(zoneId);

    if (!steps) {
      const protectionItemCollection: Map<
        string,
        IExperimentFactDataNutritionHistoryProtectionItemUpdate
      > = new Map();
      protectionItemCollection.set(protectionItemForUpdate.protectionId, protectionItemForUpdate);

      const stepCollection: Map<
        string,
        Map<string, IExperimentFactDataNutritionHistoryProtectionItemUpdate>
      > = new Map();
      stepCollection.set(stepId, protectionItemCollection);

      this._itemsForUpdateByProtectionIdByStepIdByZoneId.set(zoneId, stepCollection);

      return;
    }

    const items = this._itemsForUpdateByProtectionIdByStepIdByZoneId.get(zoneId).get(stepId);

    if (!items) {
      const protectionItemCollection: Map<
        string,
        IExperimentFactDataNutritionHistoryProtectionItemUpdate
      > = new Map();
      protectionItemCollection.set(protectionItemForUpdate.protectionId, protectionItemForUpdate);

      this._itemsForUpdateByProtectionIdByStepIdByZoneId
        .get(zoneId)
        .set(stepId, protectionItemCollection);

      return;
    }

    if (options?.isUpdate) {
      const previouslyAddedItem = items.get(protectionItemForUpdate.protectionId);

      if (!previouslyAddedItem) {
        this._itemsForUpdateByProtectionIdByStepIdByZoneId
          .get(zoneId)
          .get(stepId)
          .set(protectionItemForUpdate.protectionId, protectionItemForUpdate);
      } else {
        this._itemsForUpdateByProtectionIdByStepIdByZoneId
          .get(zoneId)
          .get(stepId)
          .set(protectionItemForUpdate.protectionId, {
            ...previouslyAddedItem,
            ...protectionItemForUpdate,
          });
      }

      return;
    }

    this._itemsForUpdateByProtectionIdByStepIdByZoneId
      .get(zoneId)
      .get(stepId)
      .set(protectionItemForUpdate.protectionId, protectionItemForUpdate);
  };

  setSelectedDictionaryOption = (
    zoneId: string,
    rowId: string,
    option: ISelectOption<IDictionaryEntity>
  ): void => {
    const hasCollection = this._selectedDictionaryOptionsByRowIdByZoneId.has(zoneId);

    if (hasCollection) {
      this._selectedDictionaryOptionsByRowIdByZoneId.get(zoneId).set(rowId, option);
    } else {
      const newCollection: Map<string, ISelectOption<IDictionaryEntity>> = new Map();
      newCollection.set(rowId, option);

      this._selectedDictionaryOptionsByRowIdByZoneId.set(zoneId, newCollection);
    }
  };

  setInvalidRowId = (zoneId: string, rowId: string): void => {
    const hasCollection = this._invalidRowIdsByZoneId.has(zoneId);

    if (hasCollection) {
      this._invalidRowIdsByZoneId.get(zoneId).add(rowId);
    } else {
      this._invalidRowIdsByZoneId.set(zoneId, new Set([rowId]));
    }
  };

  setExperimentZone = (zone: IExperimentCultureZone): void => {
    this._experimentZonesById.set(zone.id, zone);
  };

  setCompletedAuditOptionList = (
    completedAuditOptionList: ISelectOption<IExperimentStep>[]
  ): void => {
    this._completedAuditOptionList = completedAuditOptionList;
  };

  deleteNutritionHistoryItemFromUpdate = (
    zoneId: string,
    stepId: string,
    fertilizerId: string
  ): void => {
    this._itemsForUpdateByFertilizerIdByStepIdByZoneId
      .get(zoneId)
      ?.get?.(stepId)
      ?.delete?.(fertilizerId);
  };

  deleteNutritionHistoryProtectionItemFromUpdate = (
    zoneId: string,
    stepId: string,
    protectionId: string
  ): void => {
    this._itemsForUpdateByProtectionIdByStepIdByZoneId
      .get(zoneId)
      ?.get?.(stepId)
      ?.delete?.(protectionId);
  };

  deleteStepFromUpdate = (zoneId: string, stepId: string): void => {
    this.setIsEdited(true);

    this._stepsForUpdateByStepIdByZoneId.get(zoneId).delete(stepId);
  };

  clearIsPageLoading = (): void => {
    this._isPageLoading = true;
  };

  clearIsNeedToRefreshPage = (): void => {
    this._isNeedToRefreshPage = false;
  };

  clearSelectedZoneId = (): void => {
    this._selectedZoneId = null;
  };

  clearZoneList = (): void => {
    this._zonesList = [];
  };

  clearBbchList = (): void => {
    this._bbchList = [];
  };

  clearStepIds = (): void => {
    this._stepsIds.clear();
  };

  deleteStepId = (fertilizerId: string): void => {
    this._stepsIds.delete(fertilizerId);
  };

  clearFertilizerIdsByStepId = (): void => {
    this._fertilizerIdsByStepId.clear();
  };

  deleteFertilizerId = (stepId: string, fertilizerId: string): void => {
    this._fertilizerIdsByStepId.get(stepId)?.delete?.(fertilizerId);
  };

  deleteFertilizersByStepId = (stepId: string): void => {
    this._fertilizerIdsByStepId.delete(stepId);

    this.setIsEdited(true);
  };

  clearProtectionIdsByStepId = (): void => {
    this._protectionIdsByStepId.clear();
  };

  deleteProtectionId = (stepId: string, protectionId: string): void => {
    this._protectionIdsByStepId.get(stepId)?.delete?.(protectionId);
  };

  deleteProtectionsByStepId = (stepId: string): void => {
    this._protectionIdsByStepId.delete(stepId);

    this.setIsEdited(true);
  };

  clearZonesForUpdateByZoneId = (): void => {
    this._zonesForUpdateByZoneId.clear();
  };

  clearStepsForUpdateByStepIdByZoneId = (): void => {
    this._stepsForUpdateByStepIdByZoneId.clear();
  };

  clearItemsForUpdateByItemIdByStepIdByZoneId = (): void => {
    this._itemsForUpdateByFertilizerIdByStepIdByZoneId.clear();
  };

  clearProtectionItemsForUpdateByItemIdByStepIdByZoneId = (): void => {
    this._itemsForUpdateByProtectionIdByStepIdByZoneId.clear();
  };

  clearInvalidRowIdsByZoneId = (): void => {
    this._invalidRowIdsByZoneId.clear();
  };

  deleteInvalidRowId = (zoneId: string, rowId: string): void => {
    this._invalidRowIdsByZoneId.get(zoneId)?.delete?.(rowId);
  };

  clearSelectedDictionaryOptionsByRowIdByZoneId = (): void => {
    this._selectedDictionaryOptionsByRowIdByZoneId.clear();
  };

  clearIsEdited = (): void => {
    this._isEdited = false;
  };

  clearUnhandledInvalidRowId = () => {
    this._unhandledInvalidRowId = null;
  };

  deleteSelectedDictionaryOption = (zoneId: string, rowId: string): void => {
    this._selectedDictionaryOptionsByRowIdByZoneId.get(zoneId)?.delete?.(rowId);
  };

  clearExperimentZonesById = (): void => {
    this._experimentZonesById.clear();
  };

  clearScrollStep = () => {
    this._scrollStep = 0;
  };

  clearCompletedAuditList = (): void => {
    this._completedAuditOptionList = [];
  };
}

export default ExecutionStore;
