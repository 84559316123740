import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';

import {
  createExpZoneName,
  ICultureZoneCell,
} from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../common/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CalculationStore } from '../../../../../../../calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import {
  ToolsBasketIcon,
  ToolsDotsIcon,
  ToolsPencilIcon,
} from '../../../../../../../../../../../../../common/components/ui/icons/tools';
import { IZonesAddFactZoneModalPayload } from '../../../../config/modals/ZonesAddFactZoneModal/components/ZonesAddFactZoneModalBody/ZonesAddFactZoneModalBody';
import { ZonesController } from '../../../../mobx/controllers';
import { useMap } from '../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { ZonesStore } from '../../../../mobx/stores';
import { ZonesDisableFactZoneModalConfig } from '../../../../config/modals/ZonesDisableFactZoneModal';
import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { DropdownFactory } from '../../../../../../../../../../../../../common/features/factories';
import {
  TDropdownFactoryOnChange,
  TDropdownFactoryOption,
} from '../../../../../../../../../../../../../common/features/factories/DropdownFactory/types';

import Styled from './ZoneListFactZoneActionsCell.styles';

enum EAction {
  Edit = 'edit',
  Disable = 'disable',
}

const ENABLED_STATUS_LIST = [
  EExperimentStatus.InProgress,
  EExperimentStatus.Approved,
  EExperimentStatus.InApproval,
];

interface IProps {
  rowData?: ICultureZoneCell;
}

const ZoneListFactZoneActionsCell: FC<IProps> = ({ rowData }) => {
  const createExpStore = useStore(CreateExperimentStore);
  const zonesStore = useStore(ZonesStore);
  const calculationStore = useStore(CalculationStore);

  const zonesController = useStore(ZonesController);

  const map = useMap();
  const modal = useModal();

  const handleEditClick = (): void => {
    const activeFwZone = zonesStore.fwExpCultureZoneList.find(
      fwZone => fwZone?.expZone.id === rowData.parentZoneId
    );

    calculationStore.setActiveCultureZone(activeFwZone.expZone);

    const payload: IZonesAddFactZoneModalPayload = {
      fwZone: rowData.fwExpCultureZone,
      parentFwZone: activeFwZone,
      formattedParenZoneName: createExpZoneName(activeFwZone.expZone.cultureZone),
    };

    modal.openModalByModalId('zones__edit-fact-zone-modal', payload);
  };

  const handleDisableClick = (): void => {
    const formattedName = rowData.fwExpCultureZone.variety ?? rowData.fwExpCultureZone.name;

    modal.registerModalList(
      [
        ZonesDisableFactZoneModalConfig(formattedName, () => {
          zonesController.disableFactZone(rowData.parentZoneId, () => {
            map.removePolygon(rowData.fwExpCultureZone.polyId);
          });
        }),
      ],
      'zones__fact-zone-actins'
    );

    modal.openModalByModalId('zones__disable-fact-zone');
  };

  const handleChange: TDropdownFactoryOnChange<EAction> = value => {
    switch (value) {
      case EAction.Edit:
        handleEditClick();
        break;

      case EAction.Disable:
        handleDisableClick();
        break;

      default:
    }
  };

  const checkIfButtonIsDisabled = (): boolean => {
    return !ENABLED_STATUS_LIST.includes(createExpStore.selectedExp?.status);
  };

  const optionList = useMemo<TDropdownFactoryOption[]>(() => {
    return [
      {
        type: 'default',
        value: EAction.Edit,
        icon: {
          type: 'custom',
          render: data => <ToolsPencilIcon dataTestId={data.dataTestId} />,
        },
        label: {
          type: 'default',
          value: 'Редактировать',
          styles: {
            color: '#151F32',
          },
        },
      },
      {
        type: 'default',
        value: EAction.Disable,
        icon: {
          type: 'custom',
          render: data => <ToolsBasketIcon dataTestId={data.dataTestId} />,
        },
        label: {
          type: 'default',
          value: 'Отключить',
          styles: {
            color: '#DF2E22',
          },
        },
      },
    ];
  }, []);

  const getDataTestId = useDataTestIdV2('zone-list__fact-zone__actions-cell');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <div>
        <DropdownFactory
          dataTestId={getDataTestId()['data-test-id']}
          field={{
            type: 'custom',
            render: data => <ToolsDotsIcon dataTestId={data.dataTestId} />,
          }}
          body={{
            type: 'default',
            styles: {
              width: '166px',
            },
          }}
          optionList={optionList}
          popoverProps={{
            positions: ['right'],
          }}
          isDisabled={checkIfButtonIsDisabled()}
          onChange={handleChange}
        />
      </div>
    </Styled.Wrapper>
  );
};

ZoneListFactZoneActionsCell.displayName = 'ZoneListFactZoneActionsCell';

export default observer(ZoneListFactZoneActionsCell);
