import { FC, MouseEvent } from 'react';
import { AutoTooltip, useModal } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ExecutionCalculationsCopyButton.styles';

const ExecutionCalculationsCopyButton: FC<{}> = () => {
  const modalActions = useModal();

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    event.stopPropagation();
    modalActions.openModalByModalId('executionCalculationCopy');
  };

  const getDataTestId = useDataTestIdV2(`execution-container-header-info-description__icon-copy`);

  return (
    <AutoTooltip content={'Перенести данные из наблюдения'}>
      <Styled.Button {...getDataTestId()} onClick={handleClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3108_13145)">
            <path
              d="M16.8649 2H5.83784C4.82703 2 4 2.81818 4 3.81818V16.5455H5.37838V3.36364H16.8649V2ZM19.6216 5.63636H9.51351C8.5027 5.63636 7.67568 6.45455 7.67568 7.45455V20.1818C7.67568 21.1818 8.5027 22 9.51351 22H19.6216C20.6324 22 21 21.1818 21 20.1818V13.8182V7.45455C21 6.45455 20.6324 5.63636 19.6216 5.63636ZM19.6216 20.6364H14.3378H9.05405V13.8182V7H14.3378H19.6216V20.6364Z"
              fill="#151F32"
            />
          </g>
          <defs>
            <clipPath id="clip0_3108_13145">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Styled.Button>
    </AutoTooltip>
  );
};

export default ExecutionCalculationsCopyButton;
