import { IShortChecklistInstance } from '../../../models/as-fields/checklists/Instance/ChecklistInstance.model';
import { TResponseList } from '../../../models/common/response';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetChecklistForTaskReq = {
  cultureId: string;
  organizationId: string;
  operationTypeId: string;
};

type TGetChecklistForTaskRes = TResponseList<IShortChecklistInstance>;

const getChecklistForTask: TApiRoute & {
  request: TGetChecklistForTaskReq;
  response: TGetChecklistForTaskRes;
} = {
  url: () => `/api/as-fields/checklist/findForTask`,
  method: 'GET',
  request: {} as TGetChecklistForTaskReq,
  response: {} as TGetChecklistForTaskRes,
  headers: {},
};

export type { TGetChecklistForTaskReq, TGetChecklistForTaskRes };

export { getChecklistForTask };
