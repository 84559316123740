import {
  ITableBuilderCellConfig,
  ITableBuilderColumnConfig,
  ITableBuilderConfig,
} from '../../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../../constants/configs';
import { CreateExperimentStore } from '../../../../../../../mobx/stores';
import {
  ZoneListPredecessorCell,
  ZoneListTypeSelectionCell as TypeSelectionCell,
  ZoneListSwitcherCell,
  ZoneListActionsCell as ActionsCell,
  ZoneListNonDraftStatusActionsCell as NonDraftStatusActionsCell,
  ZoneListFactZoneActionsCell as FactZoneActionsCell,
} from '../../../components/ZonesList/components';
import ZoneListCellWithDisabled from '../../../components/ZonesList/components/ZoneListCellWithDisabled/ZoneListCellWithDisabled';
import { ICultureZoneCell } from '../../../components/ZonesList/utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { ZoneListAhoCell } from '../../../components/ZonesList/components/ZoneListAhoCell';
import { EExperimentStatus } from '../../../../../../../../../../../../../api/models/as-fields/experiments';

type TBuilderConfig = ITableBuilderConfig;
type TColumnConfig = ITableBuilderColumnConfig;
type TCellConfig = ITableBuilderCellConfig;

@provide.transient()
class ZonesListConfigService {
  @lazyInject(CreateExperimentStore)
  protected createExpStore: CreateExperimentStore;

  public createConfig = (): TBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList();

    return {
      id: EExperimentsTableBuilderId.Zones,
      columnConfigList: columnList,
      cellConfigList: cellList,
    };
  };

  protected createColumnList = (): TColumnConfig[] => {
    const switcher = this.createSwitcherColumn();
    const name = this.createNameColumn();
    const predecessor = this.createPredecessorColumn();
    const variety = this.createVarietyColumn();
    const type = this.createTypeColumn();
    const aho = this.createAhoColumn();
    const actions = this.createActionsColumn();

    return [switcher, name, predecessor, variety, type, aho, actions];
  };

  protected createSwitcherColumn = (): TColumnConfig => {
    return {
      id: 'radioButton',
      name: '',
      width: {
        default: '56px',
      },
    };
  };

  protected createNameColumn = (): TColumnConfig => {
    return {
      id: 'name',
      name: 'Участок',
      width: {
        default: '1.5fr',
      },
    };
  };

  protected createPredecessorColumn = (): TColumnConfig => {
    return {
      id: 'predecessor',
      name: 'Культура-предшественник',
      width: {
        default: 'minmax(184px, 1fr)',
      },
    };
  };

  protected createVarietyColumn = (): TColumnConfig => {
    return {
      id: 'variety',
      name: 'Вариант опыта',
      width: {
        default: '110px',
      },
    };
  };

  protected createTypeColumn = (): TColumnConfig => {
    return {
      id: 'type',
      name: 'Тип участка',
      width: {
        default: '90px',
      },
    };
  };

  protected createAhoColumn = (): TColumnConfig => {
    return {
      id: 'aho',
      name: 'АХО',
      width: {
        default: '46px',
      },
    };
  };

  protected createActionsColumn = (): TColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '40px',
      },
    };
  };

  protected createCellList = (): TCellConfig[] => {
    const switcher = this.createSwitcherCell();
    const name = this.createNameCell();
    const predecessor = this.createPredecessorCell();
    const variety = this.createVarietyCell();
    const type = this.createTypeCell();
    const aho = this.createAhoCell();
    const actions = this.createActionsCell();

    return [switcher, name, predecessor, variety, type, aho, actions];
  };

  protected createSwitcherCell = (): TCellConfig => {
    return {
      id: 'id',
      customRenderConfig: {
        renderCell: (row: ICultureZoneCell) => {
          if (row.parentZoneId) return <div></div>;

          return <ZoneListSwitcherCell rowData={row} />;
        },
      },
    };
  };

  protected createNameCell = (): TCellConfig => {
    return {
      id: 'name',
      customRenderConfig: {
        renderCell: row => (
          <ZoneListCellWithDisabled rowData={row} text={row.name} dataTestId={'name-cell'} />
        ),
      },
    };
  };

  protected createPredecessorCell = (): TCellConfig => {
    return {
      id: 'predecessor',
      customRenderConfig: {
        renderCell: row => <ZoneListPredecessorCell rowData={row} />,
      },
    };
  };

  protected createVarietyCell = (): TCellConfig => {
    return {
      id: 'variety',
      customRenderConfig: {
        renderCell: (row: ICultureZoneCell) => {
          return (
            <ZoneListCellWithDisabled
              rowData={row}
              text={row.fwExpCultureZone.variety}
              dataTestId={'variety-cell'}
            />
          );
        },
      },
    };
  };

  protected createTypeCell = (): TCellConfig => {
    return {
      id: 'type',
      customRenderConfig: {
        renderCell: row => <TypeSelectionCell rowData={row} />,
      },
    };
  };

  protected createAhoCell = (): TCellConfig => {
    return {
      id: 'aho',
      customRenderConfig: {
        renderCell: row => <ZoneListAhoCell rowData={row} />,
      },
    };
  };

  protected createActionsCell = (): TCellConfig => {
    const hasDraftStatus: boolean =
      this.createExpStore.selectedExp?.status === EExperimentStatus.Draft;

    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: (row: ICultureZoneCell) => {
          if (row.parentZoneId) {
            return <FactZoneActionsCell rowData={row} />;
          }

          if (hasDraftStatus) {
            return <ActionsCell rowData={row} />;
          }

          return <NonDraftStatusActionsCell rowData={row} />;
        },
      },
    };
  };
}

export default ZonesListConfigService;
