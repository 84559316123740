import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { provide } from '../../../../../utils/helpers/mobx';
import { IFormConfig, IFormElement, TFormValue } from '../../../models';
import { ISelectOption } from '../../../../../components/form/Dropdown/Dropdown.types';
import { IDatePickerOptions, IPaginationConfig } from '../../../models/FormConfig/Form.model';

@provide.singleton()
class FormStore {
  private _focusedElementName: string | number | symbol | null = null;

  private _isElementFocused = false;

  private _lastChangedElement: IFormElement<any> | null = null;

  private _formKeyToForm: Map<string, IFormConfig<any>['form']> = new Map<
    string,
    IFormConfig<any>['form']
  >();

  private _formKeyToInitialForm: Map<string, IFormConfig<any>['form']> = new Map<
    string,
    IFormConfig<any>['form']
  >();

  private _formKeyToElements: Map<string, IFormConfig<any>['elements']> = new Map<
    string,
    IFormConfig<any>['elements']
  >();

  private _formKeyToInvalidElements: Map<string, Partial<IFormConfig<any>['elements']>> = new Map<
    string,
    Partial<IFormConfig<any>['elements']>
  >();

  constructor() {
    makeAutoObservable(this);
  }

  get focusedElementName() {
    return this._focusedElementName;
  }

  get isElementFocused() {
    return this._isElementFocused;
  }

  get lastChangedElement() {
    return this._lastChangedElement;
  }

  hasInvalidElements = (formKey: string): boolean => {
    const incorrectElements = this.getInvalidElements(formKey);

    return !_.isEmpty(incorrectElements);
  };

  getIsFormChanged = <F extends Record<keyof F, TFormValue>>(formKey: string): boolean => {
    const form = this.getForm<F>(formKey);
    const initialForm = this.getInitialForm(formKey);

    return !_.isEqual(form, initialForm);
  };

  getForm = <F extends Record<keyof F, TFormValue>>(formKey: string): IFormConfig<F>['form'] => {
    return this._formKeyToForm.get(formKey);
  };

  getInitialForm = <F extends Record<keyof F, TFormValue>>(
    formKey: string
  ): IFormConfig<F>['form'] => {
    return this._formKeyToInitialForm.get(formKey);
  };

  getElement = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F
  ): IFormElement<F> => {
    return this._formKeyToElements.get(formKey)?.[elementName];
  };

  getElements = <F extends Record<keyof F, TFormValue>>(
    formKey: string
  ): IFormConfig<F>['elements'] | void => {
    return this._formKeyToElements.get(formKey);
  };

  getInvalidElements = <F extends Record<keyof F, TFormValue>>(
    formKey: string
  ): Partial<IFormConfig<F>['elements']> => {
    const invalidElements = this._formKeyToInvalidElements.get(formKey);

    return invalidElements || {};
  };

  getFormValue = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F
  ): TFormValue => {
    return this.getForm<F>(formKey)?.[elementName];
  };

  setFocusedElementName = <F extends Record<keyof F, TFormValue>>(name: keyof F): void => {
    this._focusedElementName = name;
  };

  setIsElementFocused = (value: boolean): void => {
    this._isElementFocused = value;
  };

  setLastChangedElement = <F extends Record<keyof F, TFormValue>>(
    element: IFormElement<F>
  ): void => {
    this._lastChangedElement = element;
  };

  setForm = <F extends Record<keyof F, TFormValue>>(formKey: string, form: F): void => {
    this._formKeyToForm.set(formKey, form);
  };

  setInitialForm = <F extends Record<keyof F, TFormValue>>(formKey: string, form: F): void => {
    this._formKeyToInitialForm.set(formKey, form);
  };

  deleteForm = (formKey: string): void => {
    this._formKeyToForm.delete(formKey);
  };

  setFormValue = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    partialValue: Partial<F>
  ): void => {
    const form = this.getForm<F>(formKey);

    if (!form) {
      return;
    }

    this.setForm<F>(formKey, { ...form, ...partialValue });
  };

  setInitialFormValue = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    partialValue: Partial<F>
  ): void => {
    const form = this.getForm<F>(formKey);

    if (!form) {
      return;
    }

    this.setInitialForm<F>(formKey, { ...form, ...partialValue });
  };

  setElements = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elements: IFormConfig<F>['elements']
  ): void => {
    this._formKeyToElements.set(formKey, elements);
  };

  setElementLabel = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    label: string
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, {
      ...element,
      label,
    });
  };

  deleteFormElements = (formKey: string): void => {
    this._formKeyToElements.delete(formKey);
  };

  setInvalidElements = <F extends Record<keyof F, TFormValue>>(
    formKey,
    invalidElements: Partial<IFormConfig<F>['elements']>
  ): void => {
    this._formKeyToInvalidElements.set(formKey, invalidElements);
  };

  setElement = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    element: IFormElement<F>
  ): void => {
    const elements = this.getElements<F>(formKey);

    this._formKeyToElements.set(formKey, { ...elements, [elementName]: element });
  };

  setElementData = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    partialData: Partial<IFormElement<F>>
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, { ...element, ...partialData });
  };

  setElementOptionList = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    optionList: ISelectOption[]
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, {
      ...element,
      selectOptions: { ...(element.selectOptions || {}), optionList },
    });
  };

  setElementDatePickerParams = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    params: IDatePickerOptions
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, {
      ...element,
      datePickerOptions: { ...(element.datePickerOptions || {}), ...params },
    });
  };

  setElementPaginationConfig = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    paginationConfig: IPaginationConfig
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, {
      ...element,
      paginationConfig: { ...(element.paginationConfig || {}), ...paginationConfig },
    });
  };

  setElementIsBlocked = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    isBlocked: boolean
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, {
      ...element,
      isBlocked,
    });
  };

  setElementError = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    isShowError: boolean,
    errorMessage: string
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, {
      ...element,
      schema: {
        ...(element.schema || {}),
        isShownError: isShowError,
        errorTitle: errorMessage ? errorMessage : element.schema?.errorTitle,
      },
    });
  };

  setElementSearchQueryHandler = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F,
    handler: (v: string) => Promise<ISelectOption[]>
  ): void => {
    const element = this.getElement<F>(formKey, elementName);

    if (!element) {
      return;
    }

    this.setElement<F>(formKey, elementName, {
      ...element,
      selectOptions: {
        ...(element.selectOptions || {}),
        searchQuery: { ...(element?.selectOptions?.searchQuery || {}), handler },
      },
    });
  };

  deleteInvalidElement = <F extends Record<keyof F, TFormValue>>(
    formKey: string,
    elementName: keyof F
  ): void => {
    const invalidElements = this.getInvalidElements<F>(formKey);

    const omittedElements = _.omit(invalidElements, [elementName]) as Partial<
      IFormConfig<F>['elements']
    >;

    this.setInvalidElements<F>(formKey, omittedElements);
  };

  deleteFormInvalidElements = (formKey: string): void => {
    this._formKeyToInvalidElements.delete(formKey);
  };

  clearFocusedElementName = (): void => {
    this._focusedElementName = null;
  };

  clearIsElementFocused = (): void => {
    this._isElementFocused = false;
  };

  clearLastChangedElement = (): void => {
    this._lastChangedElement = null;
  };

  clearFormKeyToForm = (): void => {
    this._formKeyToForm.clear();
  };

  clearFormKeyToElements = (): void => {
    this._formKeyToElements.clear();
  };

  clearInvalidElements = (): void => {
    this._formKeyToInvalidElements.clear();
  };

  clearStore = (): void => {
    this.clearFocusedElementName();
    this.clearIsElementFocused();
    this.clearLastChangedElement();

    this.clearFormKeyToForm();
    this.clearFormKeyToElements();
    this.clearInvalidElements();
  };
}

export default FormStore;
