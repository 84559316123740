import { observer } from 'mobx-react';
import { FC, memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AuditInfoController } from '../mobx/controllers';
import { AuditInfoStore } from '../mobx/stores';
import { useForm } from '../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../common/utils/helpers/mobx';
import { TAuditsParams } from '../../../../../../../models/params/AuditsParams.model';
import { AuditInfo } from '../../../modules/auditInfo';
import { NavigationTabsController } from '../../../../../../../../../../common/features/NavigationTabs/mobx';
import { AuditStore } from '../../../../../mobx/store';

import {
  AUDIT_INFO_FORM_KEY,
  createAuditInfoFormConfig,
  IAuditInfoForm,
} from './forms/config/auditInfoFormConfig';

const ShowAuditInfo: FC = observer(() => {
  const auditInfoStore = useStore(AuditInfoStore);

  const { auditId } = useParams<TAuditsParams>();

  const { auditInfo, setAuditStartDate, setAuditName, clearStore } = auditInfoStore;

  const { selectedAudit } = useStore(AuditStore);

  const { elements, registerForm, addOptionList, changeListOfFormValue, isSubmitDisabled } =
    useForm<IAuditInfoForm>(AUDIT_INFO_FORM_KEY);

  const { getAuditInfo } = useStore(AuditInfoController);

  const { goToNextTab } = useStore(NavigationTabsController);

  const isPermissionToEditAudit = selectedAudit?.canUpdateStatus;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAuditInfo(auditId);
    registerForm(createAuditInfoFormConfig());

    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    if (auditInfo && auditId) {
      const {
        organization,
        experiment,
        techOperationType,
        assignee,
        name,
        parentStep,
        planStartDate,
        planEndDate,
      } = auditInfo;

      changeListOfFormValue({
        organizationId: organization?.id,
        experimentId: experiment?.id,
        planStartDate,
        planEndDate,
        parentStepId: parentStep?.id,
        techOperationTypeId: techOperationType?.id,
        assigneeId: assignee?.id,
        name,
      });

      addOptionList('organizationId', [{ label: organization?.name, value: organization?.id }]);
      addOptionList('experimentId', [{ label: experiment?.code, value: experiment?.id }]);
      addOptionList('parentStepId', [{ label: parentStep?.name, value: parentStep?.id }]);
      addOptionList('techOperationTypeId', [
        { label: techOperationType?.name, value: techOperationType?.id },
      ]);
      addOptionList('assigneeId', [{ label: assignee?.fullName, value: assignee?.id }]);

      setAuditStartDate(planStartDate);
      setAuditName(name);

      setIsLoading(false);
    }
  }, [auditInfo]);

  return (
    <AuditInfo
      formElements={elements}
      handleReadyClick={goToNextTab}
      titleName={'Общие данные'}
      buttonName={'Продолжить'}
      isSubmitDisabled={isSubmitDisabled}
      isShowEditIcon={isPermissionToEditAudit}
      isShowSubmit={false}
      isLoading={isLoading}
      mode={'View'}
    />
  );
});

export default memo(ShowAuditInfo);
