import { observer } from 'mobx-react';
import { forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';
import _ from 'lodash';

import { useStore } from '../../../../../utils/helpers/mobx';
import { FormController } from '../../../mobx/controllers';
import { FormStore } from '../../../mobx/stores';

import { ISelectContainerProps } from './SelectContainer.types';
import { SelectContainerHelpers } from './utils/helpers';

const SelectContainer = forwardRef<HTMLInputElement, ISelectContainerProps>(
  ({ formKey, elementName, id, element }, elementRef) => {
    const formStore = useStore(FormStore);
    const { getFormValue } = formStore;

    const value = getFormValue(formKey, elementName) as string | number;

    const formController = useStore(FormController);
    const { onFormValueChange, addOptionList } = formController;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getDefaultValue } = SelectContainerHelpers;

    const { schema, selectOptions } = element;

    const dependencyName = element?.dependencyName as never;
    const dependencyValue = getFormValue(formKey, dependencyName);
    const isDependencyEmpty = !dependencyName ? false : !Boolean(dependencyValue);

    const handleChange = useCallback(newValue => {
      onFormValueChange(formKey, elementName, { [elementName]: newValue });
    }, []);

    const debounceSearchQuery = useRef(
      _.debounce(async (searchQueryValue: string): Promise<void> => {
        const searchQueryHandler = element?.selectOptions?.searchQuery?.handler;

        if (searchQueryHandler) {
          const optionList = await searchQueryHandler(searchQueryValue);

          if (_.isArray(optionList)) {
            addOptionList(formKey, elementName, optionList);
            setIsLoading(false);
          }
        }
      }, 500)
    );

    const handleSearchQuery = useCallback(
      async (searchQueryValue: string) => {
        setIsLoading(true);

        await debounceSearchQuery.current(searchQueryValue?.trim());
      },
      [debounceSearchQuery]
    );

    const defaultValue = useMemo(() => {
      return getDefaultValue(value, selectOptions?.optionList, selectOptions?.defaultValue);
    }, [selectOptions?.optionList, selectOptions?.defaultValue, value]);

    const dropdownConfig: TNewDropdownProps['config'] = {
      field: {
        isRequired: element?.isRequired,
        id,
        onChange: handleChange,
        defaultValue,
        emptyOption: element?.selectOptions?.emptyOption,
        isDisabled: element?.isDisabled,
        placeholder: element?.placeholder,
        icons: {
          clear: {
            options: {
              valueAfterCleaning: element?.selectOptions?.valueAfterCleaning,
            },
          },
        },
        type: {
          search: element?.selectOptions?.search
            ? {
                options: {
                  ...element?.selectOptions?.search?.options,
                  searchQueryHandler: element?.selectOptions?.searchQuery
                    ? handleSearchQuery
                    : null,
                },
              }
            : null,
        },
      },
      body: {
        optionList: element?.selectOptions?.optionList || [],
        zIndex: '10002',
      },
      visual: {
        isBlocked: element?.isBlocked || isDependencyEmpty,
        label: element?.label,
        isLoading,
        bodyPlacement: element?.selectOptions?.visual?.bodyPlacement,
      },
      validation: {
        error: {
          errorList: !schema?.isShownError ? [] : schema?.errorTitle && [schema?.errorTitle],
        },
      },
      other: {
        dataTestId: id,
      },
    };

    return (
      <>
        <NewDropdown
          config={dropdownConfig}
          paginationConfig={element.paginationConfig}
          ref={elementRef}
          data-test-id="select-dropdown"
          isLoading={isLoading}
        />
      </>
    );
  }
);

SelectContainer.displayName = 'SelectContainer';

export default memo(observer(SelectContainer));
