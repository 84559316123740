import React, { FC, memo } from 'react';

import { TDropdownFactoryBody as TBody } from '../../types';

import { DropdownFactoryBodyDefault as BodyDefault } from './components';

const DropdownFactoryBody: FC<TBody> = props => {
  switch (props.type) {
    case 'default':
      return <BodyDefault {...props} />;

    default:
      return <></>;
  }
};

DropdownFactoryBody.displayName = 'DropdownFactoryBody';

export default memo(DropdownFactoryBody);
