import React, { FC, memo } from 'react';

import { TDropdownFactoryField as TField } from '../../types';

import { DropdownFactoryFieldCustom as FieldCustom } from './components';

const DropdownFactoryField: FC<TField> = props => {
  switch (props.type) {
    case 'custom':
      return <FieldCustom {...props} />;

    default:
      return <></>;
  }
};

DropdownFactoryField.displayName = 'DropdownFactoryField';

export default memo(DropdownFactoryField);
