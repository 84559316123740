import { FC, useMemo, useState } from 'react';
import {
  AutoTooltip,
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  NotificatorProps,
  useNotificator,
  useShowTooltip,
} from '@farmlink/farmik-ui';
import { generatePath, useNavigate } from 'react-router';
import { observer } from 'mobx-react';

import { IExperimentFactTableAudit } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { useDataTestIdV2 } from '../../../../../../../../../../../common/utils/hooks/locators';
import { EDashboardRoute } from '../../../../../../../../../../routes/DashboardRoute';
import { EAuditsRoute } from '../../../../../../../../../observations/routes';
import { EAuditRoute } from '../../../../../../../../../observations/modules/Audit/routes';
import { SliderAuditStatus } from '../SliderAuditStatus';
import { SliderTaskStatus } from '../SliderTaskStatus';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionController as Controller } from '../../../../mobx/controllers';
import { SimpleLoader } from '../../../../../../../../../../../common/features/UI/loaders/SimpleLoader';
import { EExperimentStatus } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { CreateExperimentStore } from '../../../../../../mobx/stores';
import { ETaskStatus } from '../../../../../../../../../../../common/utils/constants/task';

import Styled from './SliderItem.styles';
import {
  ExecutionAuditCardIconCopy as IconCopy,
  ExecutionAuditCardIconFullscreen as IconFullscreen,
} from './components';

interface IProps {
  stepId: string;
  audit: IExperimentFactTableAudit;
  dataTestId: string;
}

const DISABLED_STATUS_LIST = [
  EExperimentStatus.Canceled,
  EExperimentStatus.Completed,
  EExperimentStatus.FactAppruved,
  EExperimentStatus.FullCompleted,
];

const SliderItem: FC<IProps> = ({ stepId, audit, dataTestId }) => {
  const { id, name, status, taskStatus } = audit;

  const createExpStore = useStore(CreateExperimentStore);
  const controller = useStore(Controller);

  const notificator = useNotificator();

  const { ref: titleRef, isShowTooltip: isShowTitleTooltip } = useShowTooltip<HTMLDivElement>();
  const [isCopyLoading, setIsCopyLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleCopyIconClick = (): void => {
    controller.copyFromAudit(
      id,
      {
        handleSuccess: () => {
          const notification: NotificatorProps = {
            message: 'Данные из наблюдения сохранились на всех участках',
            style: {
              type: ENotificationType.Success,
              height: ENotificationHeight.BIG,
              placement: 'top-center',
              autoClose: 10000,
              theme: ENotificatorTheme.Dark,
            },
          };

          notificator.setNotification(notification);
        },
        handleFailure: () => {
          const notification: NotificatorProps = {
            message: 'Ошибка переноса',
            style: {
              type: ENotificationType.Warning,
              height: ENotificationHeight.BIG,
              placement: 'top-center',
              autoClose: 10000,
              theme: ENotificatorTheme.Dark,
            },
          };

          notificator.setNotification(notification);
        },
        handleLoading: setIsCopyLoading,
      },
      stepId
    );
  };

  const handleFullscreenIconClick = (): void => {
    navigate(
      generatePath(`/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.Report}`, {
        auditId: id,
      })
    );
  };

  const checkIfButtonIsDisabled = (): boolean => {
    return DISABLED_STATUS_LIST.includes(createExpStore.selectedExp?.status);
  };

  const isCopyDisabled = audit.taskStatus !== ETaskStatus.Completed;

  const getDataTestId = useDataTestIdV2(`${dataTestId}__audit-card`);

  const CopyLoader = useMemo(() => {
    return <SimpleLoader dataTestId={getDataTestId()['data-test-id']} size={16} />;
  }, []);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Header {...getDataTestId('header')}>
        <AutoTooltip content={name} position={'bottom'} disabled={!isShowTitleTooltip}>
          <Styled.Title {...getDataTestId('title')} ref={titleRef}>
            {name}
          </Styled.Title>
        </AutoTooltip>

        <Styled.Icons {...getDataTestId('icons')}>
          {checkIfButtonIsDisabled() ? null : (
            <>
              {isCopyLoading ? (
                CopyLoader
              ) : (
                <IconCopy
                  dataTestId={getDataTestId()['data-test-id']}
                  onClick={handleCopyIconClick}
                  isDisabled={isCopyDisabled}
                />
              )}
            </>
          )}

          <IconFullscreen
            dataTestId={getDataTestId('copy-icon')['data-test-id']}
            onClick={handleFullscreenIconClick}
          />
        </Styled.Icons>
      </Styled.Header>

      <Styled.Body {...getDataTestId('body')}>
        <Styled.Description {...getDataTestId('description')}>
          Отчёт по наблюдению
        </Styled.Description>

        <SliderAuditStatus status={status} />
      </Styled.Body>

      <Styled.Footer {...getDataTestId('footer')}>
        <Styled.Description {...getDataTestId('description')}>Задача</Styled.Description>
        <SliderTaskStatus status={taskStatus} />
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

SliderItem.displayName = 'SliderItem';

export default observer(SliderItem);
