import { FC, MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Popover } from 'react-tiny-popover';

import {
  ILinearDropdownChange,
  ILinearDropdownOption,
  ILinearDropdownProps,
} from './LineralDropdown.types';
import Styled from './LinearDropdown.styles';
import { LinearDropdownBody, LinearDropdownField } from './components';

const LinearDropdown: FC<ILinearDropdownProps> = ({
  optionList: _optionList,
  selectedOptionValue,
  onChange,
  isDisabled,
  styles,
  removeSelectedOptionFromList,
  renderField,
}) => {
  const [_selectedOption, setSelectedOption] = useState<ILinearDropdownOption | null>(null);
  const [isDropped, setIsDropped] = useState<boolean>(false);

  const optionList = useMemo(() => {
    if (removeSelectedOptionFromList && selectedOptionValue) {
      return _optionList.filter(option => option?.value !== selectedOptionValue);
    }

    return _optionList;
  }, [_optionList, removeSelectedOptionFromList, selectedOptionValue]);

  useEffect(() => {
    const alreadySelected = _optionList?.find(option => option?.value === selectedOptionValue);
    const defaultOption = _optionList?.find(option => option?.isDefault);

    setSelectedOption(alreadySelected || defaultOption || null);
  }, [selectedOptionValue, optionList, _optionList]);

  const handleChange = useCallback<ILinearDropdownChange>(
    (value, option) => {
      if (isDisabled) {
        return;
      }

      setSelectedOption(option);
      setIsDropped(false);

      onChange?.(value, option);
    },
    [onChange, isDisabled]
  );

  const handleClickOutside = useCallback(() => {
    if (isDisabled) {
      return;
    }
    setIsDropped(false);
  }, [isDisabled]);

  const handleFieldClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (isDisabled) {
        return;
      }

      setIsDropped(!isDropped);
    },
    [isDropped, isDisabled]
  );

  return (
    <Styled.Wrapper $width={styles?.width} $isDisabled={isDisabled}>
      <Popover
        isOpen={isDropped}
        onClickOutside={handleClickOutside}
        containerStyle={{ zIndex: '9999' }}
        content={
          <LinearDropdownBody
            optionList={optionList}
            onOptionCLick={handleChange}
            styles={styles?.dropdownBody}
          />
        }
      >
        <LinearDropdownField
          option={_selectedOption}
          isDropped={isDropped}
          onClick={handleFieldClick}
          styles={styles?.dropdownField}
          renderField={renderField}
        />
      </Popover>
    </Styled.Wrapper>
  );
};

LinearDropdown.displayName = 'LinearDropdown';

export default memo(LinearDropdown);
