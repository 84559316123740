import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $borderType: string; $isNeedToAddBordersToRows: boolean }>`
  height: 100%;

  ${({ $isNeedToAddBordersToRows, $borderType }) => {
    if (!$isNeedToAddBordersToRows) {
      return css`
        display: flex;
        flex-direction: column;
        gap: 12px;
      `;
    }

    return css`
      & > :not(:last-child) {
        border-bottom: ${`1px ${$borderType ?? 'solid'} #e9e6f0`};
      }
    `;
  }};
`;

const StyledTableBuilderContent = {
  Wrapper,
};

export default StyledTableBuilderContent;
