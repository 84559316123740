import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, Icon, useModal, useShowTooltip } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CalculationStore } from '../../../../../../../../calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../common/utils/hooks/locators';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { IZonesCopyAhoModalPayload } from '../ZonesCopyAhoModalBody/ZonesCopyAhoModalBody';

import Styled from './ZonesCopyAhoModalDescription.styles';

const ZonesCopyAhoModalDescription: FC = () => {
  const calculationStore = useStore(CalculationStore);

  const modal = useModal();

  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  const modalPayload =
    (modal.getModalPayload() as IZonesCopyAhoModalPayload) ??
    ({
      formattedZoneName: '',
    } as IZonesCopyAhoModalPayload);

  const iconType = useMemo(() => {
    return calculationStore.activeCultureZone.type === EExperimentCultureZoneType.Control
      ? 'controlField'
      : 'experimentField';
  }, [calculationStore.activeCultureZone.type]);

  const getDataTestId = useDataTestIdV2('zones__copy-aho-modal__description');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Icon dataTestId={getDataTestId('icon')['data-test-id']} icon={iconType} size={'medium'} />

      <AutoTooltip
        dataTestId={getDataTestId('tooltip')['data-test-id']}
        content={modalPayload.formattedZoneName}
        disabled={!isShowTooltip}
      >
        <Styled.Name {...getDataTestId('name')} ref={ref}>
          {`На: ${modalPayload.formattedZoneName}`}
        </Styled.Name>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

ZonesCopyAhoModalDescription.displayName = 'ZonesCopyAhoModalDescription';

export default observer(ZonesCopyAhoModalDescription);
