import styled from 'styled-components';

const Wrapper = styled.svg<{}>`
  transition: all 0.15s ease-in-out;

  & circle {
    fill: #F3F4F8;
  }

  & path {
    stroke: #7E8B9D;
  }

  &:hover {
    transition: all 0.15s ease-in-out;

    & circle {
      fill: #E9ECF0;
    }

    & path {
      stroke: #7E8B9D;
    }
`;

const StyledZonesBlockedTypeIcon = {
  Wrapper,
};

export default StyledZonesBlockedTypeIcon;
