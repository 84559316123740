import styled, { css, CSSObject } from 'styled-components';

const Wrapper = styled.div<{ $styles: CSSObject }>`
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;

  ${({ $styles }) => css($styles ?? {})};
`;

const StyledDropdownFactoryLabelDefault = {
  Wrapper,
};

export default StyledDropdownFactoryLabelDefault;
