import styled from 'styled-components';

const Wrapper = styled.svg`
  cursor: pointer;

  &:hover {
    transition: all 0.15s ease-in-out;

    & rect {
      fill: #e9ecf0;
    }
  }
`;

const StyledExecutionAuditCardIconFullscreen = {
  Wrapper,
};

export default StyledExecutionAuditCardIconFullscreen;
