import useDataTestIdV2 from '../../../../../utils/hooks/locators/useDataTestIdV2/useDataTestIdV2';
import { useDropdownFactoryContext as useContext } from '../../context/DropdownFactoryContext/hooks';

const useDropdownFactoryDataTestId = (
  initialDataTestId?: string
): ReturnType<typeof useDataTestIdV2> => {
  const { dataTestId } = useContext();

  const getDataTestId = useDataTestIdV2(`${initialDataTestId ?? dataTestId}__dropdown-factory`);

  return getDataTestId;
};

export default useDropdownFactoryDataTestId;
