import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { ModalFooter, Typography, useModal } from '@farmlink/farmik-ui';

import { useForm } from '../../../../../../../../../../../../../../common/features/form/utils/hooks';
import {
  copyFieldFormConfig,
  ICopyFieldForm,
} from '../../../../../../../../calculation/containers/Calculation/config/forms/copyFieldForm';
import { useStore } from '../../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CalculationStore } from '../../../../../../../../calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import { CalculationController } from '../../../../../../../../calculation/containers/Calculation/mobx/controllers/Calculation/Calculation.controller';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../common/utils/hooks/locators';
import { ISelectOption } from '../../../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { createExpZoneName } from '../../../../../components/ZonesList/utils/hooks/zonesListTableHooks/zonesListTable.hooks';

import Styled from './ZonesCopyAhoModalBody.styles';

export interface IZonesCopyAhoModalPayload {
  successHandler?: () => void;
  formattedZoneName?: string;
}

const ZonesCopyAhoModalBody: FC = () => {
  const calculationStore = useStore(CalculationStore);
  const calculationController = useStore(CalculationController);

  const modal = useModal();

  const form = useForm<ICopyFieldForm>(copyFieldFormConfig.formKey);

  const { elements, registerForm, addOptionList, submitForm } = form;
  const { fieldId: FieldComponent } = elements;

  const modalPayload = modal.getModalPayload() as IZonesCopyAhoModalPayload;

  const optionList = useMemo(() => {
    const availableZoneList = calculationStore.cultureZones.filter(zone => {
      return (
        zone.id !== calculationStore.activeCultureZone.id &&
        zone.cultureZone.culture.id === calculationStore.activeCultureZone.cultureZone.culture.id &&
        zone.aho
      );
    });

    return availableZoneList.map<ISelectOption>(expZone => ({
      value: expZone.id,
      label: createExpZoneName(expZone.cultureZone),
    }));
  }, [calculationStore.cultureZones, calculationStore.activeCultureZone]);

  useEffect(() => {
    registerForm(copyFieldFormConfig);
  }, []);

  useEffect(() => {
    addOptionList('fieldId', optionList);
  }, [optionList]);

  const handleSubmit = () => {
    submitForm(async forms => {
      await calculationController.copyAho(forms, calculationStore.activeCultureZone);
      modalPayload?.successHandler?.();
      modal.closeModal();
    });
  };

  const successButton = useMemo(
    () => ({
      title: 'Скопировать',
      handler: handleSubmit,
    }),
    [handleSubmit]
  );

  const denyButton = useMemo(
    () => ({
      title: 'Отменить',
      handler: modal.closeModal,
    }),
    []
  );

  const getDataTestId = useDataTestIdV2('zones__copy-aho-modal__body');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Content {...getDataTestId('content')}>
        {FieldComponent ? <FieldComponent /> : null}

        <Typography dataTestId={getDataTestId('description')['data-test-id']} variant={'body'}>
          Если на участок, на который производится копирование данных, ранее вносилась информация по
          АХО, она будет потеряна
        </Typography>
      </Styled.Content>

      <ModalFooter
        dataTestId={getDataTestId('footer')['data-test-id']}
        successButton={successButton}
        denyButton={denyButton}
      />
    </Styled.Wrapper>
  );
};

ZonesCopyAhoModalBody.displayName = 'ZonesCopyAhoModalBody';

export default observer(ZonesCopyAhoModalBody);
