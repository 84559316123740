import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';

import { useForm } from '../../../../../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../../../../../common/utils/helpers/mobx';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../common/utils/hooks/locators';
import { ISelectOption } from '../../../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import {
  IZonesAddFactZoneForm,
  createZonesAddFactZoneFormConfig,
  ZONES_ADD_FACT_ZONE_FORM_KEY,
} from '../../../../forms';
import { ICultureZone } from '../../../../../../../../../../../../../../../api/models/as-fields/fields/CultureZone';
import { ZonesController } from '../../../../../mobx/controllers';
import { ZonesStore } from '../../../../../mobx/stores';
import { CalculationStore } from '../../../../../../../../calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import { useMap } from '../../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { IFWExperimentCultureZone } from '../../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { createExpZoneName } from '../../../../../components/ZonesList/utils/hooks/zonesListTableHooks/zonesListTable.hooks';

import Styled from './ZonesAddFactZoneModalBody.styles';

export interface IZonesAddFactZoneModalPayload {
  parentFwZone: IFWExperimentCultureZone;
  formattedParenZoneName: string;
  fwZone?: IFWExperimentCultureZone;
}

interface IProps {
  mode: 'create' | 'edit';
}

const ZonesAddFactZoneModalBody: FC<IProps> = ({ mode }) => {
  const calculationStore = useStore(CalculationStore);
  const zonesStore = useStore(ZonesStore);

  const zonesController = useStore(ZonesController);

  const [zoneOptionList, setZoneOptionList] = useState<ISelectOption<ICultureZone>[]>([]);

  const map = useMap();
  const modal = useModal();

  const modalPayload = modal.getModalPayload() as IZonesAddFactZoneModalPayload;

  const form = useForm<IZonesAddFactZoneForm>(ZONES_ADD_FACT_ZONE_FORM_KEY);
  const {
    formData,
    elements,
    registerForm,
    addOptionList,
    submitForm,
    changeListOfFormValue,
    addPaginationConfig,
  } = form;

  const {
    cultureZoneId: CultureZoneComponent,
    cultureZoneName: NameComponent,
    previousCultureIds: PreviousCultureComponent,
  } = elements;

  useEffect(() => {
    registerForm(
      createZonesAddFactZoneFormConfig({
        culturesSearchQueryHandler: zonesController.culturesSearchQueryHandler,
      })
    );
  }, []);

  useEffect(() => {
    (async () => {
      const optionList = await zonesController.getFactZoneOptionList();

      const formattedOptionList: ISelectOption<ICultureZone>[] = [];
      formattedOptionList.push(...optionList);

      if (mode === 'edit') {
        formattedOptionList.push({
          value: modalPayload.fwZone.cultureZone.id,
          label: createExpZoneName(modalPayload.fwZone.cultureZone),
          initialModel: modalPayload.fwZone.cultureZone,
        });
      }

      setZoneOptionList(formattedOptionList);
      addOptionList('cultureZoneId', formattedOptionList);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (mode !== 'create') return;

      const prevCulture = modalPayload.parentFwZone.expZone.previousCultures?.[0];
      zonesStore.setPreviousCultureSearchQuery(prevCulture?.name ?? '');

      const prevCultureSelectOptionList = await zonesController.culturesSearchQueryHandler(
        prevCulture?.name ?? ''
      );
      addOptionList('previousCultureIds', prevCultureSelectOptionList);

      changeListOfFormValue({
        previousCultureIds: prevCulture?.id ?? '',
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (mode !== 'edit') return;

      const prevCulture = modalPayload.parentFwZone.expZone.previousCulturesFact?.[0];
      zonesStore.setPreviousCultureSearchQuery(prevCulture?.name ?? '');

      const prevCultureSelectOptionList = await zonesController.culturesSearchQueryHandler(
        prevCulture?.name ?? ''
      );
      addOptionList('previousCultureIds', prevCultureSelectOptionList);

      changeListOfFormValue({
        cultureZoneId: modalPayload.fwZone.cultureZone.id,
        cultureZoneName: modalPayload.fwZone.cultureZone.name,
        previousCultureIds: prevCulture?.id ?? '',
      });
    })();
  }, []);

  useEffect(() => {
    if (!formData?.cultureZoneId) {
      changeListOfFormValue({ cultureZoneName: '' });

      return;
    }

    const selectedZone = zoneOptionList.find(
      ({ initialModel }) => initialModel.id === formData.cultureZoneId
    )?.initialModel;

    if (!selectedZone) return;

    changeListOfFormValue({
      cultureZoneName: selectedZone.name,
    });
  }, [formData?.cultureZoneId]);

  const onPreviousCultureScroll = async () => {
    const newPreviousCultureOptionList = await zonesController.onPreviousCultureListScroll(
      zonesStore.previousCultureSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('previousCultureIds', [
      ...zonesStore.previousCultureOptionList,
      ...newPreviousCultureOptionList,
    ]);

    zonesStore.setPreviousCultureOptionList([
      ...zonesStore.previousCultureOptionList,
      ...newPreviousCultureOptionList,
    ]);
  };

  useEffect(() => {
    addPaginationConfig('previousCultureIds', {
      currentPage: zonesStore.previousCultureCurrentPage,
      totalPages: zonesStore.previousCultureTotalPages,
      onScroll: onPreviousCultureScroll,
      onPageChange: zonesController.changePreviousCulturePageNumber,
    });
  }, [
    zonesStore.previousCultureCurrentPage,
    zonesStore.previousCultureTotalPages,
    onPreviousCultureScroll,
    zonesController.changePreviousCulturePageNumber,
  ]);

  const handleSubmit = () => {
    submitForm(async forms => {
      await zonesController.saveFactZone(
        calculationStore.activeCultureZone.id,
        forms,
        (fwZone: IFWExperimentCultureZone) => {
          if (mode === 'edit') {
            map.removePolygon(modalPayload.fwZone.polyId);
          }

          return map.drawCultureZone(fwZone);
        }
      );
      modal.closeModal();
    });
  };

  const successButton = useMemo(
    () => ({
      title: 'Сохранить',
      handler: handleSubmit,
    }),
    [handleSubmit]
  );

  const denyButton = useMemo(
    () => ({
      title: 'Отменить',
      handler: modal.closeModal,
    }),
    []
  );

  const getDataTestId = useDataTestIdV2('zones__add-fact-zone__body');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Content {...getDataTestId('content')}>
        {CultureZoneComponent ? <CultureZoneComponent /> : null}

        {NameComponent ? <NameComponent /> : null}

        {PreviousCultureComponent ? <PreviousCultureComponent /> : null}
      </Styled.Content>

      <ModalFooter
        dataTestId={getDataTestId('footer')['data-test-id']}
        successButton={successButton}
        denyButton={denyButton}
      />
    </Styled.Wrapper>
  );
};

ZonesAddFactZoneModalBody.displayName = 'ZonesAddFactZoneModalBody';

export default observer(ZonesAddFactZoneModalBody);
