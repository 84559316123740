import React, { FC, MouseEvent, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ZonesControlTypeIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__zones__control-type-icon.
   */
  dataTestId: string;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
}

const ZonesControlTypeIcon: FC<IProps> = ({
  dataTestId,
  onClick,
  isDisabled = false,
  isActive = false,
}) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__zones__control-type-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (isDisabled) return;

    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onClick={handleClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isDisabled={isDisabled}
      $isActive={isActive}
    >
      <circle cx="12" cy="12" r="12" fill="#F3F4F8" />
      <path
        d="M13.472 7H15.696L12.16 11.797L16 17H13.696L10.016 11.9262V17H8V7H10.016V11.8524L13.472 7Z"
        fill="#7E8B9D"
      />
    </Styled.Wrapper>
  );
};

ZonesControlTypeIcon.displayName = 'ZonesControlTypeIcon';

export default memo(ZonesControlTypeIcon);
