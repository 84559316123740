import React, { FC, MouseEvent, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ToolsCheckedIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__tools__checked-icon.
   */
  dataTestId: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

const ToolsCheckedIcon: FC<IProps> = ({ dataTestId, onClick, isDisabled = false }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__tools__checked-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (isDisabled) return;

    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onClick={handleClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isDisabled={isDisabled}
    >
      <rect width="24" height="24" rx="8" fill="#ECF8EF" />
      <path
        d="M18.807 7.02409C18.5385 6.83635 18.1647 6.86164 17.9245 7.09996L17.9245 7.1L9.66618 15.3034L6.07506 11.7632C6.07503 11.7631 6.075 11.7631 6.07497 11.7631C5.8063 11.4965 5.37043 11.4966 5.10181 11.7632C4.83278 12.0301 4.83265 12.4629 5.1019 12.7294L5.10205 12.7296L9.18163 16.7509C9.44794 17.0145 9.88897 17.0145 10.1553 16.7509L10.1554 16.7508L18.8975 8.06634C18.8976 8.06627 18.8977 8.0662 18.8977 8.06612L18.8977 8.06612L18.8273 7.99517C19.0182 7.80665 19.0509 7.52033 18.9251 7.29841C18.962 7.26293 18.9731 7.21408 18.9744 7.18986M18.807 7.02409L18.9451 7.15175C18.9554 7.16419 18.9651 7.1769 18.9744 7.18986M18.807 7.02409C18.8146 7.02364 18.8197 7.02418 18.8213 7.02525L18.8271 7.02995L18.8295 7.03212L18.8314 7.034L18.8328 7.03533L18.8979 7.09965L18.9629 7.16409L18.9628 7.16411M18.9744 7.18986C18.9748 7.18225 18.9742 7.17708 18.9731 7.1755L18.9683 7.16976L18.9661 7.16738L18.9642 7.16544L18.9628 7.16411M18.9744 7.18986L18.9628 7.16405C18.963 7.16429 18.9631 7.16437 18.9628 7.16411"
        fill="#3FB65F"
        stroke="#3FB65F"
        strokeWidth="0.2"
      />
    </Styled.Wrapper>
  );
};

ToolsCheckedIcon.displayName = 'ToolsCheckedIcon';

export default memo(ToolsCheckedIcon);
