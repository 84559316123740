import React, { FC, MouseEvent } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ExecutionAuditCardIconFullscreen.styles';

interface IProps {
  dataTestId: string;
  onClick: () => void;
}

const ExecutionAuditCardIconFullscreen: FC<IProps> = ({ dataTestId, onClick }) => {
  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    event.stopPropagation();
    onClick();
  };

  const getDataTestId = useDataTestIdV2(`${dataTestId}__icon-fullscreen`);

  return (
    <AutoTooltip content={'Перейти в наблюдение'} position={'bottom'}>
      <div>
        <Styled.Wrapper
          {...getDataTestId()}
          onClick={handleClick}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="8" fill="#F3F4F8" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4999 10.4284L17.4999 7.67845H17.5V6.49988H17.4999H16.3213H13.5715V7.67845L15.4405 7.67845L10.3809 12.738L11.2143 13.5714L16.3213 8.46434V10.4284H17.4999ZM8.5 6.49998H11.2144V7.49998H8.5C7.94772 7.49998 7.5 7.9477 7.5 8.49998V15.5C7.5 16.0523 7.94772 16.5 8.5 16.5H15.5C16.0523 16.5 16.5 16.0523 16.5 15.5V12.7856H17.5V15.5C17.5 16.6046 16.6046 17.5 15.5 17.5H8.5C7.39543 17.5 6.5 16.6046 6.5 15.5V8.49998C6.5 7.39541 7.39543 6.49998 8.5 6.49998Z"
            fill="#151F32"
          />
        </Styled.Wrapper>
      </div>
    </AutoTooltip>
  );
};

ExecutionAuditCardIconFullscreen.displayName = 'ExecutionAuditCardIconFullscreen';

export default ExecutionAuditCardIconFullscreen;
