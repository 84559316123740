import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { PageAndFieldInfo } from '../../../../../../../../../../../common/components/modal/modalDescriptions/components';
import { CopyAhoForm } from '../../components/CopyAhoForm';
import { CreateAgrochemicalModal } from '../../components/CreateAgrochemicalModal';
import { CreateAhoModalTitle } from '../../components';

export const agrochemicalModalConfig: TModalConfig[] = [
  {
    name: 'createAxoModal',
    type: EModalType.Custom,
    title: <CreateAhoModalTitle />,
    description: {
      value: () => <PageAndFieldInfo />,
    },
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 6px 0',
      },
    },
    styledProps: {
      $size: '600px',
      $height: 'none',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    id: 'createAxoModal',
    children: () => <CreateAgrochemicalModal formType="add" />,
  },
  {
    name: 'editAxoModal',
    type: EModalType.Custom,
    title: <CreateAhoModalTitle />,
    description: {
      value: () => <PageAndFieldInfo />,
    },
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 6px 0',
      },
    },
    styledProps: {
      $size: '600px',
      $height: 'none',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    id: 'editAxoModal',
    children: () => <CreateAgrochemicalModal formType="edit" />,
  },
  {
    name: 'copyAhoModal',
    type: EModalType.Custom,
    title: '',
    id: 'copyAhoModal',
    children: CopyAhoForm,
    dataTestId: 'calculation-copy-aho-modal',
  },
];
