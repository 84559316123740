import { FC } from 'react';
import { observer } from 'mobx-react';
import { Icon, useModal } from '@farmlink/farmik-ui';

import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { zonesModalConfig } from '../../../../config/modals';
import { ZonesStore } from '../../../../mobx/stores';
import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ZoneListActionsCell.styles';

interface IProps {
  rowData: ICultureZoneCell;
}

const ZoneListActionsCell: FC<IProps> = ({ rowData }) => {
  const { setEditableZoneId } = useStore(ZonesStore);
  const { selectedExp } = useStore(CreateExperimentStore);

  const { openModalByModalId } = useModal();

  const isDisabled =
    !rowData?.fwExpCultureZone?.isSelected || selectedExp?.status !== EExperimentStatus.Draft;

  const openEditZoneModal = () => {
    if (isDisabled) {
      return;
    }

    setEditableZoneId(rowData?.fwExpCultureZone?.cultureZoneId);
    openModalByModalId(zonesModalConfig.name.editZoneInfo, { isSaveChanges: true });
  };

  const getDataTestId = useDataTestIdV2('zone-list__actions-cell');

  return (
    <Styled.Wrapper {...getDataTestId()} $disabled={isDisabled}>
      <Icon
        icon="edit"
        size="medium"
        onClick={openEditZoneModal}
        data-test-id={getDataTestId('icon-edit')['data-test-id']}
      />
    </Styled.Wrapper>
  );
};

ZoneListActionsCell.displayName = 'ZoneListActionsCell';

export default observer(ZoneListActionsCell);
