import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Wrapper = styled.div`
  width: 280px;
  height: 94px;

  display: flex;
  flex-direction: column;

  padding: 12px;

  border-radius: 12px;
  border: 1px solid ${Colors.technicalBorderGrey};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;

  margin-bottom: 8px;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;

  margin-top: 4px;
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${Colors.generalBlack};

  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
`;

const Description = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  color: ${Colors.generalBlack};
`;

const StyledSliderItem = {
  Description,
  Title,
  Icons,
  Header,
  Body,
  Footer,
  Wrapper,
};

export default StyledSliderItem;
