import React, { FC, MouseEvent, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ToolsAddIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__tools__add-icon.
   */
  dataTestId: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

const ToolsAddIcon: FC<IProps> = ({ dataTestId, onClick, isDisabled = false }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__tools__add-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (isDisabled) return;

    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onClick={handleClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isDisabled={isDisabled}
    >
      <path
        d="M8 3H4C3.44771 3 3 3.44771 3 4V8"
        stroke="#151F32"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 21H4C3.44771 21 3 20.5523 3 20V16"
        stroke="#151F32"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21H20C20.5523 21 21 20.5523 21 20V16"
        stroke="#151F32"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3H20C20.5523 3 21 3.44771 21 4V8"
        stroke="#151F32"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12H8"
        stroke="#151F32"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V8"
        stroke="#151F32"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Styled.Wrapper>
  );
};

ToolsAddIcon.displayName = 'ToolsAddIcon';

export default memo(ToolsAddIcon);
