import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  border-top: 1px solid #e9e6f0;
  padding-top: 24px;
`;

const StyledZonesCopyAhoModalBody = {
  Content,
  Wrapper,
};

export default StyledZonesCopyAhoModalBody;
