import { IExaminationAttributeValue } from '../../../models/aho/examination-attribute-values/ExaminationAttributeValue.model';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetExaminationAttributeValuesReq = {
  experimentZoneId: string;
  isFact?: boolean;
};

type TGetExaminationAttributeValuesRes = IExaminationAttributeValue[];

const getExaminationAttributeValues: TApiRoute & {
  request: TGetExaminationAttributeValuesReq;
  response: TGetExaminationAttributeValuesRes;
} = {
  url: ({ experimentZoneId }) =>
    `/api/as-fields/aho/examination-attribute-values/latestByCultureZoneExperiment/${experimentZoneId}`,
  method: 'GET',
  request: {} as TGetExaminationAttributeValuesReq,
  response: {} as TGetExaminationAttributeValuesRes,
  headers: {},
};

export type { TGetExaminationAttributeValuesReq, TGetExaminationAttributeValuesRes };

export { getExaminationAttributeValues };
