import { IFile } from '../../../common';
import { IMonitoringReportData } from '../MonitoringReportData/MonitoringReportData';
import { IExperimentShortInfo } from '../ExperimentShortInfo/ExperimentShortInfo';
import { IOperation } from '../../operation/Operation/Operation.model';
import { ETaskStatus } from '../../../../../modules/common/utils/constants/task';

enum EExperimentStepStatus {
  Draft = 'DRAFT',
  InApproval = 'IN_APPROVAL',
  Approved = 'APPROVED',
}

enum EExperimentStepType {
  NutritionHistory = 'NUTRITION_HISTORY',
  Plan = 'PLAN',
  NonPlan = 'NON_PLAN',
  Audit = 'AUDIT',
}

export enum EInventoryUsageMethod {
  None = 'NONE',
  UndergroundSolid = 'UNDERGROUND_SOLID',
  UndergroundLiquid = 'UNDERGROUND_LIQUID',
  GroundSolid = 'GROUND_SOLID',
  GroundLiquid = 'GROUND_LIQUID',
  Spraying = 'SPRAYING',
}

interface ICreateExperimentStepDto {
  name?: string;
  experimentId?: string;
  phenophaseId?: string;
  phenophaseEndId?: string;
  status?: EExperimentStepStatus;
  techOperationTypeId?: string;
  factStartDate?: string;
  factEndDate?: string;
  planStartDate?: string;
  planEndDate?: string;
  type?: string;
  servicePricePerArea?: number;
  cultureZoneExperimentId?: string;
  report?: IMonitoringReportData['imagesConfig'];
  attachedFiles?: string[];
  usageMethodTypeId?: string;
}

interface IExperimentStepChangeStatusAvailability {
  canApprovedToInApproval?: boolean;
  canDelete?: boolean;
  canDraftToInApproval?: boolean;
  canInApprovalToApproved?: boolean;
  canInApprovalToDraft?: boolean;
}

interface IExperimentStep extends IExperimentStepChangeStatusAvailability {
  id: string;
  name: string;
  experiment?: IExperimentShortInfo;
  techOperationType?: {
    id: string;
    name: string;
  };
  culture?: {
    id: string;
    name: string;
  };
  status?: EExperimentStepStatus;
  order?: number;
  endDate?: string;
  startDate?: string;
  planStartDate?: string;
  planEndDate?: string;
  factStartDate?: string;
  factEndDate?: string;
  type?: EExperimentStepType;
  phenophase?: {
    id: string;
    name?: string;
    level?: number;
    description?: string;
    code?: string;
    parent?: {
      id: string;
      name: string;
      level: number;
      description: string;
    };
  };
  organization?: {
    id: string;
    name: string;
    orgType: {
      orgTypeId: number;
      OrgType: string;
    };
  };
  attachedFiles?: IFile[];
  canUpdateStatus?: boolean;
  operation?: IOperation;
  specialNotes?: string;
  technologicalDeviation?: string;
  generalComment?: string;
  assignee?: {
    id: string;
    fullName: string;
  };
  phenophaseEnd?: {
    id: string;
    name: string;
    description: string;
    code?: string;
    level?: number;
    parent?: {
      id: string;
      name: string;
      level: number;
      description: string;
    };
  };
  servicePricePerArea?: number;
  usageMethodType?: {
    id: string;
    name: string;
  };
  checkListType?: string[];
  taskStatus?: ETaskStatus;
}

export type { IExperimentStep, ICreateExperimentStepDto };
export { EExperimentStepStatus, EExperimentStepType };
