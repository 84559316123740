import { top } from '@popperjs/core';

import { IFormConfig } from '../../../../../../../common/features/form/models';
import { ISelectOption } from '../../../../../../../common/components/form/Dropdown/Dropdown.types';

const CONTRACT_FORM_KEY = 'ContractForm';
const REQUIRED_TEXT = 'Это поле не может быть пустым';

interface IContractForm {
  contractDate: string;
  name: string;
  organizationId: string;
}

interface ISearchQueryHandlers {
  organizationNameSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
  isAllowToEditContracts: boolean;
}

const createContractFormConfig = ({
  organizationNameSearchQueryHandler,
  isAllowToEditContracts,
}: ISearchQueryHandlers): IFormConfig<IContractForm> => {
  const createEditContractFormConfig: IFormConfig<IContractForm> = {
    formKey: CONTRACT_FORM_KEY,
    form: {
      contractDate: '',
      name: '',
      organizationId: '',
    },
    elements: {
      contractDate: {
        name: 'contractDate',
        order: 1,
        type: 'date',
        isRequired: true,
        isLoading: true,
        label: 'Дата договора',
        placeholder: 'Внесите значение',
        datePickerOptions: {
          placement: 'right',
        },
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        isBlocked: !isAllowToEditContracts,
      },
      name: {
        name: 'name',
        order: 2,
        type: 'input',
        isLoading: true,
        label: 'Номер договора',
        placeholder: 'Внесите значение',
        isBlocked: !isAllowToEditContracts,
      },
      organizationId: {
        name: 'organizationId',
        order: 3,
        type: 'select',
        isRequired: true,
        isLoading: true,
        label: 'Контрагент',
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
        placeholder: 'Выберите из списка',
        selectOptions: {
          search: {},
          searchQuery: {
            handler: organizationNameSearchQueryHandler,
          },
        },
        isBlocked: !isAllowToEditContracts,
      },
    },
  };

  return createEditContractFormConfig;
};

export type { IContractForm };

export { CONTRACT_FORM_KEY, createContractFormConfig };
