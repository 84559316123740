import styled from 'styled-components';

const Wrapper = styled.span`
  width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledZonesCopyAhoModalTitle = {
  Wrapper,
};

export default StyledZonesCopyAhoModalTitle;
