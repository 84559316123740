import styled from 'styled-components';

const Name = styled.div`
  width: 456px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const Wrapper = styled.span`
  padding-bottom: 12px;
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
`;

const StyledZonesCopyAhoModalDescription = {
  Name,
  Wrapper,
};

export default StyledZonesCopyAhoModalDescription;
