import styled from 'styled-components';

const Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const PageName = styled.div`
  white-space: nowrap;
`;

const FieldInfoWrapper = styled.div<{ $fieldInfoWidth: string }>`
  padding-bottom: 12px;
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: ${({ $fieldInfoWidth }) => $fieldInfoWidth};
`;

const NameWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const StyledPageAndFieldInfo = {
  FieldInfoWrapper,
  Wrapper,
  NameWrapper,
  PageName,
};

export default StyledPageAndFieldInfo;
