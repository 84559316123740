import { flatten, merge, uniqBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { NutritionHistoryService } from '../../../../../../../../../../../common/mobx/services/as-fields';
import NutritionHistoryItemService from '../../../../../../../../../../../common/mobx/services/as-fields/NutritionHistoryItem/NutritionHistoryItem.service';
import { DictionaryService } from '../../../../../../../../../../../common/mobx/services/da-dictionary';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { toNumber } from '../../../../../../../../../../../common/utils/helpers/numbers';
import { createDictionaryEntitySelectOptionList } from '../../../../../../../../../../../common/utils/helpers/selectOptions';
import { IInventoryValuesForm } from '../../configs/forms/inventoryValuesForm';
import { HistoryStore } from '../stores/History.store';
import { InventoryValueStore } from '../../../../../calculation/containers/Calculation/mobx/store/Calculation/InventoryValue.store';
import { EExperimentStepType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { ICreateNutritionHistoryItemDto } from '../../../../../../../../../../../../api/models/as-fields/plan/NutrationHistory';

import { HistoryController } from './History.contorller';

@provide.singleton()
export class InventoryValueController {
  @lazyInject(DictionaryService)
  dictionaryService: DictionaryService;

  @lazyInject(NutritionHistoryService)
  nutritionHistoryService: NutritionHistoryService;

  @lazyInject(NutritionHistoryItemService)
  nutritionHistoryItemService: NutritionHistoryItemService;

  @lazyInject(HistoryController)
  historyController: HistoryController;

  @lazyInject(HistoryStore)
  historyStore: HistoryStore;

  @lazyInject(InventoryValueStore)
  inventoryValueStore: InventoryValueStore;

  constructor() {
    makeAutoObservable(this);
  }

  fetchFertilizers = async (searchQuery: string, page?: number) => {
    const fertilizers = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'fertilizers',
        attrs: {
          forAgriculturalUse: true,
        },
        nameFilter: searchQuery,
      },
      { size: 20, page }
    );

    return fertilizers;
  };

  fetchProtections = async (searchQuery: string, page?: number) => {
    const protections = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'protection',
        nameFilter: searchQuery,
      },
      { size: 20, page }
    );

    return protections;
  };

  fetchProtectionMeasure = async (protectionId: string) => {
    const protectionMeasure = await this.dictionaryService.getDictionaryEntityList({
      remoteName: 'measure',
      dependencyName: 'protection',
      dependencyRecordId: protectionId,
    });

    this.inventoryValueStore.setMeasureInfo(protectionMeasure?.content?.[0]);

    return protectionMeasure?.content?.[0];
  };

  fetchFertilizersPrices = async (experimentId: string) => {
    const fertilizerPrices = await this.nutritionHistoryService.getFertilizerPrices({
      experimentId,
    });

    this.inventoryValueStore.setFertilizerPrices(fertilizerPrices);
  };

  fetchProtectionsPrices = async (experimentId: string) => {
    const protectionPrices = await this.nutritionHistoryService.getProtectionPrices({
      experimentId,
    });

    this.inventoryValueStore.setProtectionPrices(protectionPrices);
  };

  getInventoryValueOptionListFromItemsList = async (searchQuery: string, page?: number) => {
    const { previousNameValue, formType } = this.inventoryValueStore;

    const { content, totalPages } = await this.fetchFertilizers(searchQuery, page);

    const currentExperimentStep = this.historyStore.currentTechOperation;
    const currentNutritionHistory = this.historyStore.getNutritionHistoryByExperimentStepId(
      currentExperimentStep?.id
    );

    const currentFertilizerIds =
      currentNutritionHistory?.items?.map(item => item.fertilizer.id) ?? [];

    const currentFertilizers = content.filter(
      item =>
        !currentFertilizerIds.includes(item.id) ||
        (currentFertilizerIds.includes(item.id) &&
          previousNameValue === item.id &&
          formType === 'edit')
    );

    return {
      fertilizerList: [createDictionaryEntitySelectOptionList(currentFertilizers)],
      totalPages,
    };
  };

  getInventoryValueOptionListFromProtectionsList = async (searchQuery: string, page?: number) => {
    const { previousNameValue, formType } = this.inventoryValueStore;

    const { content, totalPages } = await this.fetchProtections(searchQuery, page);

    const currentExperimentStep = this.historyStore.currentTechOperation;
    const currentNutritionHistory = this.historyStore.getNutritionHistoryByExperimentStepId(
      currentExperimentStep?.id
    );

    const currentProtectionsIds =
      currentNutritionHistory?.protectionItems?.map(item => item.protection.id) ?? [];

    const currentProtections = content.filter(
      item =>
        !currentProtectionsIds.includes(item.id) ||
        (currentProtectionsIds.includes(item.id) &&
          previousNameValue === item.id &&
          formType === 'edit')
    );

    return {
      protectionList: [createDictionaryEntitySelectOptionList(currentProtections)],
      totalPages,
    };
  };

  createInventoryValue = async (
    form: IInventoryValuesForm,
    experimentStep: IExperimentStep,
    cultureZone: IExperimentCultureZone,
    unitOfMeasureId: string,
    experimentId: string
  ) => {
    const inventoryInfo: ICreateNutritionHistoryItemDto = {
      concentration: toNumber(form.dosage),
      cultureZoneExperimentId: cultureZone.id,
      experimentStepId: experimentStep.id,
      isFact: true,
      unitOfMeasureId,
    };

    if (form.typeValue === 'fertilizer') {
      inventoryInfo.fertilizerId = form.nameValue;
    } else if (form.typeValue === 'protection') {
      inventoryInfo.protectionId = form.nameValue;
    }

    if (form.typeValue === 'fertilizer') {
      await this.nutritionHistoryItemService.createNutritionItemHistory(inventoryInfo);
    } else if (form.typeValue === 'protection') {
      await this.nutritionHistoryItemService.createNutritionProtectionItemHistory(inventoryInfo);
    }

    await this.historyController.fetchNutritionHistories({
      experimentId,
      cultureZoneExperimentId: cultureZone.id,
      experimentStepType: EExperimentStepType.NutritionHistory,
    });
  };

  fertilizersSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const {
      setFertilizerCurrentPage,
      setFertilizerTotalPages,
      setFertilizeSearchQuery,
      setFertilizeOptionList,
      fertilizeOptionList: previousFertilizerOptionList,
      fertilizerCurrentPage,
    } = this.inventoryValueStore;

    const { fertilizerList, totalPages } = await this.getInventoryValueOptionListFromItemsList(
      searchQuery,
      0
    );

    const [fertilizers] = fertilizerList;

    const noSearchQuery = !searchQuery || searchQuery === '';

    /**
     * Если запрашиваем список без query и при срабатывании query на onChange,
     * то склеиваем с предыдущими культурами, чтобы при рейсинге не потерять id
     */
    const fertilizerOptionList = noSearchQuery
      ? uniqBy(merge(fertilizers, previousFertilizerOptionList), 'value')
      : fertilizers;

    const sortedFertilizerOptionList = fertilizerOptionList.sort((a, b) =>
      a.label.localeCompare(b.label, 'ru', { sensitivity: 'base' })
    );
    /**
     * Изменение параметров для работы пагинации в дропдауне
     */
    setFertilizerCurrentPage(noSearchQuery ? fertilizerCurrentPage : 0);

    setFertilizerTotalPages(totalPages);

    setFertilizeOptionList(sortedFertilizerOptionList);

    setFertilizeSearchQuery(searchQuery);

    return flatten(fertilizerList);
  };

  onFertilizeListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { fertilizerCurrentPage } = this.inventoryValueStore;

    const { fertilizerList } = await this.getInventoryValueOptionListFromItemsList(
      searchQuery,
      fertilizerCurrentPage
    );

    return flatten(fertilizerList);
  };

  protectionsSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const {
      setProtectionCurrentPage,
      setProtectionTotalPages,
      setProtectionSearchQuery,
      setProtectionOptionList,
      protectionOptionList: previousProtectionOptionList,
      protectionCurrentPage,
    } = this.inventoryValueStore;

    const { protectionList, totalPages } =
      await this.getInventoryValueOptionListFromProtectionsList(searchQuery, 0);

    const [protections] = protectionList;

    const noSearchQuery = !searchQuery || searchQuery === '';

    /**
     * Если запрашиваем список без query и при срабатывании query на onChange,
     * то склеиваем с предыдущими культурами, чтобы при рейсинге не потерять id
     */
    const protectionOptionList = noSearchQuery
      ? uniqBy(merge(protections, previousProtectionOptionList), 'value')
      : protections;

    const sortedProtectionOptionList = protectionOptionList.sort((a, b) =>
      a.label.localeCompare(b.label, 'ru', { sensitivity: 'base' })
    );

    /**
     * Изменение параметров для работы пагинации в дропдауне
     */
    setProtectionCurrentPage(noSearchQuery ? protectionCurrentPage : 0);

    setProtectionTotalPages(totalPages);

    setProtectionOptionList(sortedProtectionOptionList);

    setProtectionSearchQuery(searchQuery);

    return flatten(protectionList);
  };

  onProtectionListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { protectionCurrentPage } = this.inventoryValueStore;

    const { protectionList } = await this.getInventoryValueOptionListFromProtectionsList(
      searchQuery,
      protectionCurrentPage
    );

    return flatten(protectionList);
  };

  updateFertilizerInventoryValue = async (
    id: string,
    form: IInventoryValuesForm,
    cultureZone: IExperimentCultureZone,
    experimentId: string,
    isNeedToDeleteOldAndCreateNew: boolean,
    previousTypeValue: string,
    previousNameValue: string
  ) => {
    if (isNeedToDeleteOldAndCreateNew) {
      const currentExperimentStep = this.historyStore.currentTechOperation;

      if (previousTypeValue === 'fertilizer') {
        this.historyController.deleteFertilizerInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      } else {
        this.historyController.deleteProtectionInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      }

      const defaultMeasureKG = 'f357cb40-6d3a-11eb-8d42-dd19d7aaf478';

      this.createInventoryValue(
        form,
        currentExperimentStep,
        cultureZone,
        defaultMeasureKG,
        experimentId
      );
    } else {
      await this.nutritionHistoryItemService.updateNutritionItemHistory({
        nutritionHistoryItemId: id,
        concentration: toNumber(form.dosage),
        fertilizerId: form?.nameValue,
      });

      this.historyController.fetchNutritionHistories({
        experimentId,
        cultureZoneExperimentId: cultureZone.id,
        experimentStepType: EExperimentStepType.NutritionHistory,
      });
    }
  };

  updateProtectionInventoryValue = async (
    id: string,
    form: IInventoryValuesForm,
    cultureZone: IExperimentCultureZone,
    experimentId: string,
    isNeedToDeleteOldAndCreateNew: boolean,
    previousTypeValue: string,
    previousNameValue: string
  ) => {
    if (isNeedToDeleteOldAndCreateNew) {
      const currentExperimentStep = this.historyStore.currentTechOperation;

      if (previousTypeValue === 'fertilizer') {
        this.historyController.deleteFertilizerInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      } else {
        this.historyController.deleteProtectionInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      }

      const { measureInfo } = this.inventoryValueStore;

      this.createInventoryValue(
        form,
        currentExperimentStep,
        cultureZone,
        measureInfo?.id,
        experimentId
      );
    } else {
      await this.nutritionHistoryItemService.updateNutritionProtectionItemHistory({
        nutritionHistoryProtectionItemId: id,
        concentration: toNumber(form.dosage),
        fertilizerId: form?.nameValue,
      });

      this.historyController.fetchNutritionHistories({
        experimentId,
        cultureZoneExperimentId: cultureZone.id,
        experimentStepType: EExperimentStepType.NutritionHistory,
      });
    }
  };

  changeFertilizerPageNumber = (): void => {
    const { fertilizerCurrentPage, setFertilizerCurrentPage } = this.inventoryValueStore;

    setFertilizerCurrentPage(fertilizerCurrentPage + 1);
  };

  changeProtectionPageNumber = (): void => {
    const { protectionCurrentPage, setProtectionCurrentPage } = this.inventoryValueStore;

    setProtectionCurrentPage(protectionCurrentPage + 1);
  };
}
