import { Colors } from '@farmlink/farmik-ui';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .executionCalculationCopy-label-title {
    text-align: left;
  }
`;

const Form = styled.form`
  width: 320px;

  margin-top: 18px;
  padding-top: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  border-top: 1px solid ${Colors.technicalBorderGrey};
`;

const Styled = {
  Form,
  GlobalStyles,
};

export default Styled;
