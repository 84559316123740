import { AxiosError } from 'axios';

import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { AxiosService } from '../../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import {
  TChangeCultureZoneReq,
  TGetCultureZoneExperimentListReq,
  TGetCultureZoneExperimentListRes,
  TGetCultureZoneListReq,
} from '../../../../../../../../../../../../../api';
import { IShowErrorModal } from '../../../../../../../../../../../../common/utils/hooks/useErrorModal';
import { IExperimentCultureZone } from '../../../../../../../../../../../../../api/models/as-fields/experiments';
import { ICultureZone } from '../../../../../../../../../../../../../api/models/as-fields/fields/CultureZone';

@provide.singleton()
class ZonesService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  public getExpCultureZone = async (id: string): Promise<IExperimentCultureZone> => {
    try {
      const zone = await this.axiosService.api.getExperimentCultureZone({ id }, { omit: ['id'] });

      return zone;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getAllCultureZoneList = async (
    expPayload: TGetCultureZoneExperimentListReq,
    cultureZoneListPayload: TGetCultureZoneListReq
  ): Promise<[IExperimentCultureZone[], ICultureZone[]]> => {
    try {
      const data = await Promise.all([
        this.getCultureZoneExperimentList(expPayload),
        this.getCultureZoneList(cultureZoneListPayload),
      ]);

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getCultureZoneList = async (payload: TGetCultureZoneListReq): Promise<ICultureZone[]> => {
    const { getCultureZoneList } = this.axiosService.api;

    try {
      const { content } = await getCultureZoneList(payload);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getCultureZoneExperimentList = async (
    payload: TGetCultureZoneExperimentListReq
  ): Promise<TGetCultureZoneExperimentListRes> => {
    const { getCultureZoneExperimentList } = this.axiosService.api;

    try {
      const fetchedCultureZoneList = await getCultureZoneExperimentList(payload, {
        omit: ['experimentId'],
      });

      return fetchedCultureZoneList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  changeCultureZone = async (
    payload: TChangeCultureZoneReq,
    showErrorHandler?: IShowErrorModal
  ): Promise<boolean> => {
    try {
      await this.axiosService.api.changeCultureZone(payload, {
        omit: ['id', 'seasonYear'],
        query: {
          seasonYear: payload?.seasonYear,
        },
      });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      showErrorHandler?.(e as AxiosError);
    }
  };
}

export default ZonesService;
