import { FC, MouseEvent, ReactElement } from 'react';
import { AutoTooltip, Icon } from '@farmlink/farmik-ui';

import { useShowTooltip } from '../../../utils/hooks';

import Styled from './Header.styles';

interface IProps {
  title: string;
  backButtonText?: string;
  onClickBackButton: (e?: MouseEvent<HTMLDivElement>) => void;
  leftContent?: ReactElement;
  rightContent?: ReactElement;
  isHideAdditionalContent?: boolean;
  backButtonContent?: ReactElement | null;
}

export const Header: FC<IProps> = ({
  title,
  backButtonText = 'Вернуться назад',
  onClickBackButton,
  leftContent,
  rightContent,
  isHideAdditionalContent,
  backButtonContent,
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>(title);

  return (
    <Styled.Wrapper data-test-id={`header-wrapper`} className="header-wrapper">
      <Styled.Container data-test-id={`header-container`}>
        <Styled.LeftSide data-test-id={`header-left-side`}>
          <Styled.BackButton onClick={onClickBackButton} data-test-id={`header-back-button`}>
            <Styled.IconWrapper data-test-id={`header-icon-wrapper`}>
              <Icon icon="arrow_back" size="13.5" data-test-id={`header-icon`} />
            </Styled.IconWrapper>
            {backButtonText}
          </Styled.BackButton>
          {backButtonContent ? (
            backButtonContent
          ) : (
            <Styled.TitleWrapper data-test-id={`header-title-wrapper`}>
              <AutoTooltip
                content={title}
                position="bottom"
                disabled={!showTooltip}
                data-test-id={`header-autoTooltip`}
              >
                <Styled.Title ref={ref} data-test-id={`header-title`}>
                  {title}
                </Styled.Title>
              </AutoTooltip>
              {leftContent && !isHideAdditionalContent ? (
                <Styled.LeftContent data-test-id={`header-left-side-content`}>
                  {leftContent}
                </Styled.LeftContent>
              ) : null}
            </Styled.TitleWrapper>
          )}
        </Styled.LeftSide>
        {rightContent ? (
          <Styled.RightSide data-test-id={`header-right-side`}>{rightContent}</Styled.RightSide>
        ) : null}
      </Styled.Container>
    </Styled.Wrapper>
  );
};
