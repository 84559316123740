import { useMemo } from 'react';

import {
  EExperimentCultureZoneType,
  IFWExperimentCultureZone,
} from '../../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { formatDoubleNum } from '../../../../../../../../../../../../../../common/utils/helpers/numbers';
import { ITableBuilderRowsGroupConfig } from '../../../../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { ICultureZone } from '../../../../../../../../../../../../../../../api/models/as-fields/fields/CultureZone';
import { useStore } from '../../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../../../../../../mobx/stores';
import { EExperimentStatus } from '../../../../../../../../../../../../../../../api/models/as-fields/experiments';
import { IExamination } from '../../../../../../../../../../../../../../../api/models/aho/examination/Examination.model';

interface ICultureZoneCell {
  id: number;
  name: string;
  type: EExperimentCultureZoneType;
  culture: string;
  predecessor: string;
  fwExpCultureZone: IFWExperimentCultureZone;
  isDisabledControlTypeSelection: boolean;
  parentZoneId: string;
  aho: IExamination | null;
}

export const createExpZoneName = (zone: ICultureZone): string => {
  const nameList: string[] = [];

  if (zone?.fieldName) nameList.push(zone.fieldName);
  if (zone?.name) nameList.push(zone.name);

  nameList.push(`${formatDoubleNum(zone.area)} га`);

  return nameList.join(' / ');
};

const createZoneCell = ({
  fwZone,
  hasControlType,
  parentZoneId,
  aho,
}: {
  fwZone: IFWExperimentCultureZone;
  hasControlType: boolean;
  parentZoneId?: string;
  aho?: IExamination;
}) => {
  const isControlType = fwZone.type === EExperimentCultureZoneType.Control;

  return {
    id: String(fwZone?.polyId),
    name: createExpZoneName({ ...fwZone.cultureZone, name: fwZone.name }),
    type: fwZone.type,
    culture: fwZone.cultureZone.culture.name,
    predecessor: '',
    fwExpCultureZone: fwZone,
    isDisabledControlTypeSelection: hasControlType ? !isControlType : false,
    parentZoneId,
    aho,
    autoRenderConfig: {
      preset: 'default' as 'default',
    },
  };
};

const useFormatData = (
  fwExpCultureZoneList: IFWExperimentCultureZone[]
): ITableBuilderRowsGroupConfig[] => {
  const createExperimentStore = useStore(CreateExperimentStore);

  const hasDraftStatus: boolean =
    createExperimentStore.selectedExp?.status === EExperimentStatus.Draft;

  return useMemo<ITableBuilderRowsGroupConfig[]>(() => {
    const hasControlType = fwExpCultureZoneList.some(
      zone => zone.type === EExperimentCultureZoneType.Control
    );

    const selectedRowsGroupList: ITableBuilderRowsGroupConfig[] = [];
    const notsSelectedRowsGroupList: ITableBuilderRowsGroupConfig[] = [];

    fwExpCultureZoneList.forEach(fwZone => {
      if (!fwZone?.type) return;
      if (!fwZone.isSelected && !hasDraftStatus) return;

      const zoneCell = createZoneCell({ fwZone, hasControlType, aho: fwZone?.expZone?.aho });

      const rowsGroup: ITableBuilderRowsGroupConfig = {
        id: fwZone.cultureZone.id,
        autoRenderConfig: {
          preset: 'cleared',
        },
        rowConfigList: [zoneCell],
      };

      if (fwZone?.factFwZone) {
        const factZoneCell = createZoneCell({
          fwZone: fwZone.factFwZone,
          hasControlType,
          parentZoneId: fwZone.expZone.id,
          aho: fwZone?.expZone?.ahoFact,
        });

        rowsGroup.rowConfigList = [...rowsGroup.rowConfigList, factZoneCell];
      }

      if (fwZone.isSelected) {
        selectedRowsGroupList.push(rowsGroup);
      } else {
        notsSelectedRowsGroupList.push(rowsGroup);
      }
    });

    return [...selectedRowsGroupList, ...notsSelectedRowsGroupList];
  }, [fwExpCultureZoneList, hasDraftStatus]);
};

const ZonesListTableHooks = {
  useFormatData,
};

export default ZonesListTableHooks;

export type { ICultureZoneCell };
