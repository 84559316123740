import React, { FC, memo } from 'react';

import { TDropdownFactoryOptionDefault as TOptionDefault } from '../../../../types';
import { useDropdownFactoryDataTestId as useDataTestId } from '../../../../hooks';
import { DropdownFactoryIcon as Icon } from '../../../DropdownFactoryIcon';
import { DropdownFactoryLabel as Label } from '../../../DropdownFactoryLabel';

import Styled from './DropdownFactoryOptionDefault.styles';

const DropdownFactoryOptionDefault: FC<TOptionDefault> = ({ icon, label, styles }) => {
  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper {...getDataTestId('option-default')} $styles={styles}>
      {icon ? <Icon {...icon} /> : null}
      {label ? <Label {...label} /> : null}
    </Styled.Wrapper>
  );
};

DropdownFactoryOptionDefault.displayName = 'DropdownFactoryOptionDefault';

export default memo(DropdownFactoryOptionDefault);
