import React, { FC, memo, useCallback, useState } from 'react';
import { Popover, PopoverProps } from 'react-tiny-popover';

import {
  TDropdownFactoryField as TField,
  TDropdownFactoryBody as TBody,
  TDropdownFactoryOption as TOption,
  TDropdownFactoryOnChange as TOnChange,
} from './types';
import { DropdownFactoryContext as Context } from './context';
import { DropdownFactoryField as Field, DropdownFactoryBody as Body } from './containers';
import { useDropdownFactoryDataTestId as useDataTestId } from './hooks';
import Styled from './DropdownFactory.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__dropdown-factory.
   */
  dataTestId: string;
  field: TField;
  body: TBody;
  optionList: TOption[];
  popoverProps?: Partial<PopoverProps>;
  isDisabled?: boolean;
  onChange?: TOnChange;
}

const DropdownFactory: FC<IProps> = ({
  dataTestId,
  field,
  body,
  optionList,
  popoverProps,
  isDisabled,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleFieldClick = useCallback(() => {
    if (isDisabled) return;

    setIsOpen(prev => !prev);
  }, [isDisabled]);

  const getDataTestId = useDataTestId(dataTestId);

  return (
    <Context.Provider value={{ dataTestId, optionList, onChange }}>
      <Popover
        isOpen={isOpen}
        onClickOutside={handleClickOutside}
        containerStyle={{ zIndex: '9999' }}
        content={<Body {...body} />}
        {...popoverProps}
      >
        <Styled.FieldWrapper {...getDataTestId('field-wrapper')} onClick={handleFieldClick}>
          <Field {...field} />
        </Styled.FieldWrapper>
      </Popover>
    </Context.Provider>
  );
};

DropdownFactory.displayName = 'DropdownFactory';

export default memo(DropdownFactory);
