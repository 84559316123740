import React, { FC, memo } from 'react';

import { TDropdownFactoryLabel as TLabel } from '../../types';

import { DropdownFactoryLabelDefault as LabelDefault } from './components';

const DropdownFactoryLabel: FC<TLabel> = props => {
  switch (props.type) {
    case 'default':
      return <LabelDefault {...props} />;

    default:
      return <></>;
  }
};

DropdownFactoryLabel.displayName = 'DropdownFactoryLabel';

export default memo(DropdownFactoryLabel);
