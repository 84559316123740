import styled, { css } from 'styled-components';

const checkWidth = ({ $formWidthNumber }) => {
  return `calc(${$formWidthNumber})`;
};

type TStyledContentWrapperProps = {
  $formWidthNumber: string;
};

const Fullscreen = styled.div<{ $marginBottom?: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 912px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 24px;

  & > :not(:last-child) {
    margin-bottom: ${({ $marginBottom = 24 }) => `${$marginBottom}px`};
  }
`;

const ActionButton = styled.div`
  width: 160px;
  height: 40px;
  border: 1px solid #3fb65f;
  border-radius: 12px;
  background: #3fb65f;
  text-align: center;
  size: 14px;
  line-height: 20px;
  padding-top: 8px;
  color: #ffffff;
`;

const FullScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 72px;
  padding: 14px 24px;
  background: ${({ theme }) => theme?.Colors?.generalLight ?? '#ffffff'};
  z-index: 100;
`;

const FullscreenFooter = styled.div`
  position: relative;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    width: 100%;
  }
  overflow-y: auto;
  scrollbar-gutter: stable;
  margin: 0 24px;
  margin-right: 12px;
  z-index: 1006;
`;

const FullscreenWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme?.Colors?.secondaryDefault ?? '#FAF9FD'};
  display: grid;
  flex-direction: column;
  position: relative;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  grid-template-columns: minmax(1px, 1fr);
  grid-template-rows: auto minmax(1px, 1fr);
  transition: 1s;
  &.header_hidden {
    transform: translateY(-72px);
    height: calc(100vh + 72px);
    padding-bottom: 96px;
  }
`;

const HeaderContent = styled.div<{ $isShowHeaderContent: boolean }>`
  margin: auto 0 auto auto;
  ${({ $isShowHeaderContent }) =>
    $isShowHeaderContent
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

const ContentWrapper = styled.div<TStyledContentWrapperProps>`
  display: flex;
  justify-content: space-between;
  width: ${checkWidth};
  margin: 0 auto;
`;

const StyledFullScreen = {
  Fullscreen,
  ActionButton,
  FullScreenHeader,
  FullscreenFooter,
  FullscreenWrapper,
  HeaderContent,
  ContentWrapper,
};

export default StyledFullScreen;
