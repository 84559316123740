import styled, { css } from 'styled-components';

const Wrapper = styled.svg<{ isDisabled?: boolean }>`
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
        `
      : css`
          &:hover {
            transition: all 0.15s ease-in-out;

            & rect {
              fill: #e9ecf0;
            }
          }
        `}
`;

const StyledExecutionAuditCardIconCopy = {
  Wrapper,
};

export default StyledExecutionAuditCardIconCopy;
