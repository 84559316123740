import { createContext } from 'react';

import {
  TDropdownFactoryOnChange as TOnChange,
  TDropdownFactoryOption as TOption,
} from '../../types';

export interface IDropdownFactoryContext {
  dataTestId: string;
  optionList: TOption[];
  onChange: TOnChange | null;
}

const defaultValue: IDropdownFactoryContext = {
  dataTestId: '',
  optionList: [],
  onChange: null,
};

export const DropdownFactoryContext = createContext<IDropdownFactoryContext>(defaultValue);
