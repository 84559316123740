import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
class AuditPhotosStore {
  private _isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isPageLoading: boolean): void => {
    this._isLoading = isPageLoading;
  };

  clearIsPageLoading = (): void => {
    this._isLoading = true;
  };

  clearAuditPhotosStore = (): void => {
    this.clearIsPageLoading();
  };
}

export default AuditPhotosStore;
