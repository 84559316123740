import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import {
  IExperimentCultureZone,
  IFactCultureZonePUT,
} from '../../../../models/as-fields/experiments/ExperimentCultureZone/ExperimentCultureZone.model';

type TRequest = {
  zoneList: IFactCultureZonePUT[];
};

type TResponse = IExperimentCultureZone[];

export const changeFactCultureZoneList: TApiRoute & {
  request: TRequest | ((req: TRequest) => IFactCultureZonePUT[]);
  response: TResponse;
} = {
  url: () => `/api/as-fields/experiment`,
  method: 'PUT',
  request: req => req.zoneList,
  response: {} as TResponse,
  headers: {},
};
