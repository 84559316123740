import styled, { css } from 'styled-components';

const Wrapper = styled.svg<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return css`
        transition: all 0.15s ease-in-out;

        & path {
          stroke: #e6e9ee;
        }
      `;
    }

    return css`
      cursor: pointer;

      transition: all 0.15s ease-in-out;

      & path {
        stroke: #151f32;
      }

      &:hover {
        transition: all 0.15s ease-in-out;

        & path {
          stroke: #3fb65f;
        }
      }
    `;
  }}
`;

const StyledToolsAddIcon = {
  Wrapper,
};

export default StyledToolsAddIcon;
