import React, { FC, MouseEvent, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ToolsPlusIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__tools__plus-icon.
   */
  dataTestId: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

const ToolsPlusIcon: FC<IProps> = ({ dataTestId, onClick, isDisabled = false }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__tools__plus-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (isDisabled) return;

    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onClick={handleClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isDisabled={isDisabled}
    >
      <rect width="24" height="24" rx="9" fill="#F3F4F8" />
      <path
        d="M6 12C6 12.6628 6.46224 12.75 7.125 12.75H11.25V16.8C11.25 17.4628 11.3372 18 12 18C12.6628 18 12.75 17.4628 12.75 16.8V12.75H16.8C17.4628 12.75 18 12.6628 18 12C18 11.3372 17.4628 11.25 16.8 11.25H12.75V7.2C12.75 6.53724 12.6628 6 12 6C11.3372 6 11.25 6.53724 11.25 7.2V11.25H7.125C6.375 11.25 6 11.478 6 12Z"
        fill="#151F32"
      />
    </Styled.Wrapper>
  );
};

ToolsPlusIcon.displayName = 'ToolsPlusIcon';

export default memo(ToolsPlusIcon);
