import { IFormConfig } from '../../../../../../../../../../../common/features/form/models';

const EXPERIMENTS_COPY_FIELD_FORM_KEY = 'experimentsCopyField';

const REQUIRED_TEXT = 'Это поле не может быть пустым';

interface ICopyFieldForm {
  fieldId: string;
}

const copyFieldFormConfig: IFormConfig<ICopyFieldForm> = {
  formKey: EXPERIMENTS_COPY_FIELD_FORM_KEY,
  form: {
    fieldId: '',
  },
  elements: {
    fieldId: {
      name: 'fieldId',
      type: 'select',
      order: 1,
      isRequired: true,
      label: 'Название участка, с которого будут скопированы данные',
      selectOptions: {
        optionList: [],
        defaultValue: { label: 'Выберите из списка', value: '' },
        valueAfterCleaning: { label: 'Выберите из списка', value: '' },
        search: {},
      },
      schema: {
        isShownError: false,
        errorTitle: REQUIRED_TEXT,
      },
    },
  },
};

export type { ICopyFieldForm };

export { copyFieldFormConfig };
