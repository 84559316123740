import React, { FC, MouseEvent, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ToolsDotsIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__tools__dots-icon.
   */
  dataTestId: string;
  onClick?: () => void;
}

const ToolsDotsIcon: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__tools__dots-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (!onClick) return;

    event.stopPropagation();

    onClick();
  };

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onClick={handleClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="12" r="1.5" fill="#151F32" />
      <circle cx="12" cy="12" r="1.5" fill="#151F32" />
      <circle cx="17.5" cy="12" r="1.5" fill="#151F32" />
    </Styled.Wrapper>
  );
};

ToolsDotsIcon.displayName = 'ToolsDotsIcon';

export default memo(ToolsDotsIcon);
