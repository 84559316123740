import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Input, NewDropdown, TNewDropdownProps, useModal } from '@farmlink/farmik-ui';

import {
  EExpZoneStyle,
  EExperimentCultureZoneType,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { RadioButton } from '../../../components/ZonesList/components/RadioButton';
import { ZonesController } from '../../../mobx/controllers';
import { ZonesStore } from '../../../mobx/stores';
import { useMap } from '../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';

import Styled from './EditZoneInfoModal.styles';

type TEditZoneModalProps = {
  isSaveChanges: boolean;
};

const EditZonesModal: FC = () => {
  const {
    editableZoneId,
    getZone,
    fwExpCultureZoneList,
    previousCultureCurrentPage,
    previousCultureTotalPages,
    previousCultureOptionList,
    setPreviousCultureOptionList,
    previousCultureSearchQuery,
    setPreviousCultureSearchQuery,
    setTableHasChanged,
  } = useStore(ZonesStore);

  const {
    culturesSearchQueryHandler,
    onPreviousCultureListScroll,
    changePreviousCulturePageNumber,
    updateZoneInfo,
    changeZoneType,
  } = useStore(ZonesController);

  const { changeCultureZoneName, changeCultureZoneStyle } = useMap();

  const zone = getZone(editableZoneId);

  const [name, setName] = useState(zone?.name || '');
  const [type, setType] = useState<EExperimentCultureZoneType>(
    zone?.type || EExperimentCultureZoneType.Experiment
  );
  const [previousCulture, setPreviousCulture] = useState<ISelectOption | null>(
    zone?.prevCultureSelectOptionList?.[0] || null
  );

  const { closeModal, getModalPayload } = useModal();

  const modalPayload = (getModalPayload() || {}) as TEditZoneModalProps;

  const handleCloseClick = () => {
    if (!modalPayload?.isSaveChanges) {
      changeZoneType(
        {
          ...zone,
          isSelected: false,
          styleType: EExpZoneStyle.ReadyToExperiment,
        },
        changeCultureZoneStyle
      );
      setTableHasChanged(false);
    }

    closeModal();
  };

  const handleSaveClick = () => {
    const validateFieldName = name.trim();

    updateZoneInfo(
      {
        name: validateFieldName,
        type,
        previousCulture,
        isSelected: Boolean(validateFieldName),
        predecessorId: zone?.cultureZone?.predecessorList?.[0]?.culture?.id,
      },
      closeModal,
      !modalPayload?.isSaveChanges
    );

    changeCultureZoneName(zone?.polyId, validateFieldName);

    changeCultureZoneStyle({
      ...zone,
      styleType: type as any,
    });

    setTableHasChanged(true);
  };

  const handleChangePreviousCulture = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (_, otherData) => {
      setPreviousCulture(otherData?.option ?? null);
    },
    []
  );

  const onPreviousCultureScroll = useCallback(async () => {
    const newPreviousCultureOptionList = await onPreviousCultureListScroll(
      previousCultureSearchQuery
    );

    setPreviousCultureOptionList([...previousCultureOptionList, ...newPreviousCultureOptionList]);
  }, [previousCultureSearchQuery, previousCultureOptionList, setPreviousCultureOptionList]);

  useEffect(() => {
    (async () => {
      const prevCultureSelectOptionList = await culturesSearchQueryHandler(
        zone?.prevCultureSelectOptionList?.[0]?.label || ''
      );

      setPreviousCultureSearchQuery(zone?.prevCultureSelectOptionList?.[0]?.label || '');

      setPreviousCultureOptionList(prevCultureSelectOptionList);
    })();
  }, []);

  const hasControlType = useMemo(
    () => fwExpCultureZoneList.some(z => z.type === EExperimentCultureZoneType.Control),
    [fwExpCultureZoneList]
  );

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      placeholder: 'Выберите или укажите значение',
      onChange: handleChangePreviousCulture,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
            searchQueryHandler: searchQuery => {
              setPreviousCultureSearchQuery(searchQuery);
              culturesSearchQueryHandler(searchQuery);
            },
          },
        },
      },
      defaultValue: previousCulture,
    },
    body: {
      optionList: previousCultureOptionList,
      zIndex: '10002',
    },
    visual: {
      label: 'Культура-предшественник',
      isRounded: true,
    },
    styles: {
      fieldPadding: '10px 12px',
    },
  };

  const dropdownPaginationConfig: TNewDropdownProps['paginationConfig'] = useMemo(
    () => ({
      currentPage: previousCultureCurrentPage,
      totalPages: previousCultureTotalPages,
      onScroll: onPreviousCultureScroll,
      onPageChange: changePreviousCulturePageNumber,
    }),
    [
      previousCultureCurrentPage,
      previousCultureTotalPages,
      onPreviousCultureScroll,
      changePreviousCulturePageNumber,
    ]
  );

  const dataTestId = 'zone-modal';

  return (
    <Styled.Wrapper data-test-id={`${dataTestId}-main-wrapper`}>
      <Styled.Header data-test-id={`${dataTestId}-header`}>
        <Styled.Title data-test-id={`${dataTestId}-title`}>
          {modalPayload?.isSaveChanges ? 'Изменить данные по участку' : 'Внесите данные по участку'}
        </Styled.Title>
      </Styled.Header>

      <Styled.Body data-test-id={`${dataTestId}-body-wrapper`}>
        <Styled.Container data-test-id={`${dataTestId}-name-wrapper`}>
          <Input
            placeholder="Укажите значение"
            value={name}
            onChange={setName}
            maxLength={50}
            required
            label="Название участка"
            dataTestId={`${dataTestId}-name`}
          />
        </Styled.Container>
        <Styled.Container data-test-id={`${dataTestId}-field-type-wrapper`}>
          <Styled.Label data-test-id={`${dataTestId}-field-type-label`}>
            Укажите тип участка
          </Styled.Label>
          <Styled.RadioButtonWrapper data-test-id={`${dataTestId}-field-type-buttons-wrapper`}>
            <RadioButton
              name="type"
              color="blue"
              icon={'controlField'}
              iconName="Контрольный"
              checked={type === EExperimentCultureZoneType.Control}
              onChange={() => setType(EExperimentCultureZoneType.Control)}
              disabled={
                (hasControlType && zone.type !== EExperimentCultureZoneType.Control) ||
                !zone.isSelected
              }
              fullWidth
              data-test-id={`${dataTestId}-control-field`}
            >
              Контроль
            </RadioButton>
            <RadioButton
              name="type"
              color="red"
              icon="experimentField"
              iconName="Опытный"
              checked={type === EExperimentCultureZoneType.Experiment}
              onChange={() => setType(EExperimentCultureZoneType.Experiment)}
              disabled={!zone?.isSelected}
              fullWidth
              data-test-id={`${dataTestId}-experiment-field`}
            >
              Опыт
            </RadioButton>
          </Styled.RadioButtonWrapper>
        </Styled.Container>
        <Styled.Container data-test-id={`${dataTestId}-main-wrapper`}>
          <NewDropdown
            config={dropdownConfig}
            paginationConfig={dropdownPaginationConfig}
            data-test-id={`${dataTestId}-previous-culture`}
          />
        </Styled.Container>
      </Styled.Body>
      <Styled.Footer data-test-id={`${dataTestId}-footer-wrapper`}>
        <Button
          type="button"
          color="default"
          onClick={handleCloseClick}
          dataTestId={`${dataTestId}-deny-button`}
        >
          Отменить
        </Button>
        <Button
          color="primary"
          onClick={handleSaveClick}
          disabled={!Boolean(name.trim())}
          dataTestId={`${dataTestId}-success-button`}
        >
          Сохранить
        </Button>
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

EditZonesModal.displayName = 'EditZonesModal';

export default observer(EditZonesModal);
