import { FC, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, useModal } from '@farmlink/farmik-ui';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../common/utils/hooks/locators';
import {
  ToolsCheckedIcon,
  ToolsPlusIcon,
} from '../../../../../../../../../../../../../common/components/ui/icons/tools';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CalculationStore } from '../../../../../../../calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import { CalculationController } from '../../../../../../../calculation/containers/Calculation/mobx/controllers/Calculation/Calculation.controller';
import { SimpleLoader } from '../../../../../../../../../../../../../common/features/UI/loaders/SimpleLoader';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import {
  EExperimentStatus,
  IExperimentCultureZone,
} from '../../../../../../../../../../../../../../api/models/as-fields/experiments';
import { ICreateAgrochemicalModalPayload } from '../../../../../../../calculation/containers/Calculation/components/CreateAgrochemicalModal/CreateAgrochemicalModal';
import { ZonesController } from '../../../../mobx/controllers';
import { ZonesStore } from '../../../../mobx/stores';

const ENABLED_STATUS_LIST = [
  EExperimentStatus.InProgress,
  EExperimentStatus.Approved,
  EExperimentStatus.InApproval,
];

interface IProps {
  rowData?: ICultureZoneCell;
}

const ZoneListAhoCell: FC<IProps> = ({ rowData }) => {
  const createExperimentStore = useStore(CreateExperimentStore);
  const calculationStore = useStore(CalculationStore);
  const zonesStore = useStore(ZonesStore);

  const calculationController = useStore(CalculationController);
  const zonesController = useStore(ZonesController);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const modal = useModal();

  const isZoneSelected: boolean = rowData?.fwExpCultureZone?.isSelected;
  const hasExpZone = Boolean(rowData.fwExpCultureZone?.expZone);
  const hasAho = Boolean(rowData.aho);

  const hasDraftStatus: boolean =
    createExperimentStore.selectedExp?.status === EExperimentStatus.Draft;

  const tooltipContent: string = hasAho ? 'Посмотреть данные АХО' : 'Добавить данные АХО';

  const checkIfCheckedIconIsBlocked = (): boolean => {
    if (rowData.parentZoneId) {
      return !ENABLED_STATUS_LIST.includes(createExperimentStore.selectedExp?.status);
    }

    if (!hasDraftStatus) return true;

    return !isZoneSelected;
  };

  const checkIfCheckedIconIsDisabled = (): boolean => {
    if (rowData.parentZoneId) return false;

    return !isZoneSelected;
  };

  const checkIfPlusIconIsDisabled = (): boolean => {
    if (rowData.parentZoneId) {
      return !ENABLED_STATUS_LIST.includes(createExperimentStore.selectedExp?.status);
    }

    if (!hasExpZone) return true;
    if (!hasDraftStatus) return true;

    return !isZoneSelected;
  };

  const initiateZones = (): void => {
    /**
     * Нужны для модального окна копирования.
     */
    const expZoneList = zonesStore.fwExpCultureZoneList.reduce<IExperimentCultureZone[]>(
      (list, fwZone) => {
        if (fwZone.expZone) {
          list.push(fwZone.expZone);
        }

        return list;
      },
      []
    );

    const activeZone = zonesStore.fwExpCultureZoneList.find(
      fwZone =>
        fwZone?.expZone.id === (rowData.parentZoneId ?? rowData.fwExpCultureZone?.expZone?.id)
    ).expZone;

    calculationStore.setActiveCultureZone(activeZone);
    calculationStore.setCultureZones(expZoneList);
  };

  const handleSuccessAho = (): void => {
    (async () => {
      setIsLoading(true);

      await zonesController.updateAhoOfFwZoneByNewRequest(
        rowData.parentZoneId ?? rowData.fwExpCultureZone.expZone.id,
        Boolean(rowData.parentZoneId)
      );

      setIsLoading(false);
    })();
  };

  const handleCheckedIconClick = () => {
    (async () => {
      setIsLoading(true);
      initiateZones();

      await calculationController.fetchExaminationAttributeValues(
        rowData?.parentZoneId ?? rowData.fwExpCultureZone.expZone.id,
        Boolean(rowData.parentZoneId)
      );

      const payload: ICreateAgrochemicalModalPayload = {
        formattedZoneName: rowData.name,
        isBlocked: checkIfCheckedIconIsBlocked(),
        successHandler: handleSuccessAho,
        isFact: Boolean(rowData.parentZoneId),
      };

      modal.openModalByModalId('editAxoModal', payload);
      setIsLoading(false);
    })();
  };

  const handlePlusIconClick = () => {
    initiateZones();

    const payload: ICreateAgrochemicalModalPayload = {
      formattedZoneName: rowData.name,
      isBlocked: false,
      successHandler: handleSuccessAho,
      isFact: Boolean(rowData.parentZoneId),
    };

    modal.openModalByModalId('createAxoModal', payload);
  };

  const getDataTestId = useDataTestIdV2('zone-list__aho-cell');

  const Loader = useMemo(() => {
    return <SimpleLoader dataTestId={getDataTestId()['data-test-id']} size={24} />;
  }, []);

  return (
    <>
      {isLoading ? (
        <div>{Loader}</div>
      ) : (
        <AutoTooltip content={tooltipContent}>
          <div>
            {hasAho ? (
              <ToolsCheckedIcon
                dataTestId={getDataTestId()['data-test-id']}
                isDisabled={checkIfCheckedIconIsDisabled()}
                onClick={handleCheckedIconClick}
              />
            ) : (
              <ToolsPlusIcon
                dataTestId={getDataTestId()['data-test-id']}
                isDisabled={checkIfPlusIconIsDisabled()}
                onClick={handlePlusIconClick}
              />
            )}
          </div>
        </AutoTooltip>
      )}
    </>
  );
};

ZoneListAhoCell.displayName = 'ZoneListAhoCell';

export default observer(ZoneListAhoCell);
