import { observer } from 'mobx-react';
import {
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  ModalFooter,
  NewDropdown,
  NotificatorProps,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { useMemo, useState } from 'react';

import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionStore } from '../../../../mobx/stores';
import { ExecutionController } from '../../../../mobx/controllers';

import Styled from './ExecutionCalculationCopyForm.styles';

const ExecutionCalculationCopyForm = () => {
  const store = useStore(ExecutionStore);
  const controller = useStore(ExecutionController);

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [isCopyLoading, setIsCopyLoading] = useState<boolean>(false);

  const { closeModal } = useModal();
  const notificator = useNotificator();

  const onChangeValue = (value: string) => {
    setSelectedValue(value);
  };

  const successButton = useMemo(() => {
    const handler = () => {
      if (isCopyLoading) return;

      controller.copyFromAudit(selectedValue, {
        handleSuccess: () => {
          const notification: NotificatorProps = {
            message: 'Данные из наблюдения сохранились',
            style: {
              type: ENotificationType.Success,
              height: ENotificationHeight.BIG,
              placement: 'top-center',
              autoClose: 10000,
              theme: ENotificatorTheme.Dark,
            },
          };

          notificator.setNotification(notification);
          closeModal();
        },
        handleFailure: () => {
          const notification: NotificatorProps = {
            message: 'Ошибка переноса',
            style: {
              type: ENotificationType.Warning,
              height: ENotificationHeight.BIG,
              placement: 'top-center',
              autoClose: 10000,
              theme: ENotificatorTheme.Dark,
            },
          };

          notificator.setNotification(notification);
          closeModal();
        },
        handleLoading: setIsCopyLoading,
      });
    };

    return {
      title: 'Скопировать',
      handler,
      disabled: !selectedValue,
    };
  }, [selectedValue, isCopyLoading]);

  const denyButton = useMemo(() => {
    return { title: 'Отменить', handler: closeModal };
  }, [closeModal]);

  return (
    <Styled.Form>
      <Styled.GlobalStyles />
      <NewDropdown
        config={{
          body: {
            optionList: store.completedAuditOptionList,
            zIndex: '15000',
          },
          field: {
            onChange: onChangeValue,
            placeholder: 'Выберите наблюдение',
            icons: {
              clear: {},
            },
          },
          visual: {
            label: 'Доступны для копирования наблюдения',
          },
        }}
      />
      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </Styled.Form>
  );
};

export default observer(ExecutionCalculationCopyForm);
