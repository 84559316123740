import { TCarouselItem } from '@farmlink/farmik-ui/dist/Carousel/utils/types';
import { flatMapDeep, omit } from 'lodash';
import { AxiosError } from 'axios';
import { v4 as uuid } from 'uuid';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionInputsService } from '../../services';
import { ComparisonTableBuilderController } from '../../../../../../../../../../common/features/ComparisonTableBuilder/mobx/controllers';
import {
  IExperimentFactTable,
  IExperimentFactTableStage,
  IExperimentFactTableZone,
  EExperimentFactTableStepAttributeId as StepAttributeId,
  IExperimentFactTableValue,
  EExperimentFactTableCalculationsAttributeId as CalculationsAttributeId,
} from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import {
  ExperimentService,
  ExperimentStepsService,
} from '../../../../../../../../../../common/mobx/services/as-fields';
import ExecutionStore from '../../stores/ExecutionStore/Execution.store';
import { ISelectOption } from '../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import NutritionHistoryItemService from '../../../../../../../../../../common/mobx/services/as-fields/NutritionHistoryItem/NutritionHistoryItem.service';
import {
  formatDoubleNum,
  toNumber,
} from '../../../../../../../../../../common/utils/helpers/numbers';
import { IInventoryValuesForm } from '../../../../calculation/containers/Calculation/config/forms/inventoryValuesForm';
import {
  TApiRequest,
  TApiResponse,
} from '../../../../../../../../../../common/mobx/services/axios/AxiosService/Axios.service.types';
import {
  createExecutionTableBuilderId as createTableBuilderId,
  getExecutionTableRowsGroupIdByRowId as getTableRowsGroupIdByRowId,
} from '../../../helpers';
import { EComparisonTableName as ETableName } from '../../../../../../../../../constants/features';
import {
  ExecutionCalculationsCellsService,
  ExecutionCalculationsConfigsService,
} from '../../../containers/ExecutionCalculations/mobx/services';
import { TExecutionZone as TZone } from '../../../types';
import {
  ExecutionStepsCellsService,
  ExecutionStepsConfigsService,
} from '../../../containers/ExecutionSteps/mobx/services';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { ITechOperationForm } from '../../../../calculation/containers/Calculation/config/forms/techOperationForm';
import { formatDateToISO } from '../../../../../../../../../../common/utils/helpers/dates';
import {
  IExperimentFactDataNutritionHistoryItemUpdate as NutritionHistoryItemUpdate,
  IExperimentFactDataNutritionHistoryProtectionItemUpdate as NutritionHistoryProtectionItemUpdate,
  IExperimentStep,
} from '../../../../../../../../../../../api/models/as-fields/experiments';
import { EExperimentStepType } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { DictionaryService } from '../../../../../../../../../../common/mobx/services/da-dictionary';
import { InventoryValueController } from '../../../../calculation/containers/Calculation/mobx/controllers/InventoryValue.controller';
import { TGetExperimentStepListReq } from '../../../../../../../../../../../api';
import { ETaskStatus } from '../../../../../../../../../../common/utils/constants/task';

@provide.transient()
class ExecutionController {
  @lazyInject(ExecutionCalculationsConfigsService)
  protected calculationsConfigsService: ExecutionCalculationsConfigsService;

  @lazyInject(ExecutionStepsConfigsService)
  protected stepsConfigsService: ExecutionStepsConfigsService;

  @lazyInject(ExecutionInputsService)
  protected inputsService: ExecutionInputsService;

  @lazyInject(ExperimentService)
  protected experimentsService: ExperimentService;

  @lazyInject(ComparisonTableBuilderController)
  protected comparisonTableBuilderController: ComparisonTableBuilderController;

  @lazyInject(ExecutionStore)
  protected executionStore: ExecutionStore;

  @lazyInject(NutritionHistoryItemService)
  protected nutritionHistoryItemsService: NutritionHistoryItemService;

  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  @lazyInject(ExecutionStepsCellsService)
  protected stepsCellsService: ExecutionStepsCellsService;

  @lazyInject(ExecutionCalculationsCellsService)
  protected calculationsCellsService: ExecutionCalculationsCellsService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(InventoryValueController)
  protected inventoryValueController: InventoryValueController;

  get hasInvalidAttributes() {
    return Boolean(this.executionStore.invalidRowIdList.length);
  }

  get carouselItemList(): TCarouselItem[] {
    return this.executionStore.zoneList.map(this.createCarouselItem);
  }

  get isZonesChanged(): boolean {
    return Boolean(this.executionStore.zoneForUpdateList.length);
  }

  get hasInvalidAttributesInSelectedZone() {
    return (
      [
        ...(this.executionStore.invalidRowIdsByZoneId
          .get(this.executionStore.selectedZoneId)
          ?.keys() || []),
      ].length > 0
    );
  }

  get invalidRowIdBySelectedZone() {
    const [firstInvalidRowId] = [
      ...this.executionStore.invalidRowIdsByZoneId.get(this.executionStore.selectedZoneId).values(),
    ];

    return firstInvalidRowId;
  }

  getStepsDeviationsConditionalValue: ExecutionStepsCellsService['getDeviationsConditionalValue'] =
    (builderId, rowId) => {
      return this.stepsCellsService.getDeviationsConditionalValue(builderId, rowId);
    };

  getInvalidAttributesInGroup(groupId: string, zoneId: string) {
    return [...(this.executionStore.invalidRowIdsByZoneId.get(zoneId)?.values() || [])].filter(
      invalidRowId => {
        return getTableRowsGroupIdByRowId(invalidRowId) === groupId;
      }
    );
  }

  getZoneIdByInvalidRowId = (rowId: string): string => {
    for (const [key, value] of this.executionStore.invalidRowIdsByZoneId.entries()) {
      for (const id of value.values()) {
        if (rowId === id) {
          return key;
        }
      }
    }

    return null;
  };

  getStepsDeviationsCalculatingValue: ExecutionStepsCellsService['getDeviationsCalculatingValue'] =
    (builderId, rowId, options) => {
      return this.stepsCellsService.getDeviationsCalculatingValue(builderId, rowId, options);
    };

  getStepsPlanEndDateError: ExecutionStepsCellsService['getPlanEndDateError'] = (
    builderId,
    rowId
  ) => {
    return this.stepsCellsService.getPlanEndDateError(builderId, rowId);
  };

  getStepsPhenophaseEndErrorList: ExecutionStepsCellsService['getPhenophaseEndErrorList'] = (
    builderId,
    rowId
  ) => {
    return this.stepsCellsService.getPhenophaseEndErrorList(builderId, rowId);
  };

  getCalculationsDeviationsValue: ExecutionCalculationsCellsService['getDeviationsValue'] = (
    builderId,
    rowId
  ) => {
    return this.calculationsCellsService.getDeviationsValue(builderId, rowId);
  };

  getCalculationsFactRevenueValue: ExecutionCalculationsCellsService['getFactRevenueValue'] = (
    builderId,
    rowId
  ) => {
    return this.calculationsCellsService.getFactRevenueValue(builderId, rowId);
  };

  getCalculationsFactTotalFertilizerPriceValue: ExecutionCalculationsCellsService['getFactTotalFertilizerPriceValue'] =
    () => {
      return this.calculationsCellsService.getFactTotalFertilizerPriceValue();
    };

  getCalculationsFactTotalServicePriceValue: ExecutionCalculationsCellsService['getFactTotalServicePriceValue'] =
    () => {
      return this.calculationsCellsService.getFactTotalServicePriceValue();
    };

  getCalculationsFactTotalPriceValue: ExecutionCalculationsCellsService['getFactTotalPriceValue'] =
    (builderId, rowId) => {
      return this.calculationsCellsService.getFactTotalPriceValue(builderId, rowId);
    };

  addInvalidRowId = (rowId: string): void => {
    this.executionStore.setInvalidRowId(this.executionStore.selectedZoneId, rowId);
  };

  removeInvalidRowId = (rowId: string): void => {
    this.executionStore.deleteInvalidRowId(this.executionStore.selectedZoneId, rowId);
  };

  addSelectedOption = (rowId: string, option: ISelectOption<IDictionaryEntity>): void => {
    this.executionStore.setSelectedDictionaryOption(
      this.executionStore.selectedZoneId,
      rowId,
      option
    );
  };

  removeSelectedOption = (rowId: string): void => {
    this.executionStore.deleteSelectedDictionaryOption(this.executionStore.selectedZoneId, rowId);
  };

  updateCell: ComparisonTableBuilderController['updateCell'] = <M = any>(
    builderId,
    rowId,
    columnId,
    cellData
  ) => {
    this.comparisonTableBuilderController.updateCell<M>(builderId, rowId, columnId, cellData);
  };

  createNutritionHistoryItem = (
    form: IInventoryValuesForm,
    experimentStepId: string,
    rootRowId: string,
    selectedItem: ISelectOption,
    unitOfMeasureId: string
  ): string => {
    const payload: TApiRequest<'createNutritionHistoryItem'> = {
      cultureZoneExperimentId: this.executionStore.selectedZoneId,
      experimentStepId,
      fertilizerId: form.nameValue,
      concentration: toNumber(form.dosage),
      pricePerUnit: toNumber(form.price),
      isFact: true,
      unitOfMeasureId,
    };

    this.executionStore.setFertiliserId(payload.experimentStepId, selectedItem.value);

    this.executionStore.zoneList.forEach(zone => {
      const builderId = createTableBuilderId(zone.id, ETableName.ExecutionSteps);

      const newNutritionItemConfig = this.stepsConfigsService.createNewNutritionItemConfig({
        itemId: form.nameValue,
        selectedItem,
        isSkipValues: !(this.executionStore.selectedZoneId === zone.id),
        ...payload,
      });

      this.comparisonTableBuilderController.addRowsGroupList(
        builderId,
        [newNutritionItemConfig.rowsGroupConfig],
        {
          rootRowId,
        }
      );

      Object.entries(newNutritionItemConfig.cellConfigs).forEach(([rowId, cellConfigList]) => {
        this.comparisonTableBuilderController.addCellList(builderId, rowId, cellConfigList);
      });

      if (this.executionStore.selectedZoneId === zone.id) {
        this.executionStore.setItemForUpdate(zone.id, experimentStepId, {
          fertilizerId: form.nameValue,
          concentration: toNumber(form.dosage),
          pricePerUnit: toNumber(form.price),
        });
      } else {
        this.executionStore.setItemForUpdate(zone.id, experimentStepId, {
          fertilizerId: form.nameValue,
        });
      }
    });

    return selectedItem.value;
  };

  createNutritionHistoryProtectionItem = (
    form: IInventoryValuesForm,
    experimentStepId: string,
    rootRowId: string,
    selectedItem: ISelectOption,
    unitOfMeasureId: string
  ): string => {
    const payload: TApiRequest<'createNutritionHistoryProtectionItem'> = {
      cultureZoneExperimentId: this.executionStore.selectedZoneId,
      experimentStepId,
      protectionId: form.nameValue,
      concentration: toNumber(form.dosage),
      pricePerUnit: toNumber(form.price),
      isFact: true,
      unitOfMeasureId,
    };

    this.executionStore.setProtectionId(payload.experimentStepId, selectedItem.value);

    this.executionStore.zoneList.forEach(zone => {
      const builderId = createTableBuilderId(zone.id, ETableName.ExecutionSteps);

      const newNutritionProtectionItemConfig =
        this.stepsConfigsService.createNewNutritionProtectionItemConfig({
          itemId: form.nameValue,
          selectedItem,
          unitOfMeasureId,
          isSkipValues: !(this.executionStore.selectedZoneId === zone.id),
          ...payload,
        });

      this.comparisonTableBuilderController.addRowsGroupList(
        builderId,
        [newNutritionProtectionItemConfig.rowsGroupConfig],
        {
          rootRowId,
        }
      );

      Object.entries(newNutritionProtectionItemConfig.cellConfigs).forEach(
        ([rowId, cellConfigList]) => {
          this.comparisonTableBuilderController.addCellList(builderId, rowId, cellConfigList);
        }
      );

      if (this.executionStore.selectedZoneId === zone.id) {
        this.executionStore.setProtectionItemForUpdate(zone.id, experimentStepId, {
          protectionId: form.nameValue,
          unitOfMeasureId,
          concentration: toNumber(form.dosage),
          pricePerUnit: toNumber(form.price),
        });
      } else {
        this.executionStore.setProtectionItemForUpdate(zone.id, experimentStepId, {
          protectionId: form.nameValue,
          unitOfMeasureId,
        });
      }
    });

    return selectedItem.value;
  };

  createExperimentStep = (form: ITechOperationForm, positionNumber: number): string => {
    const stepId = `${uuid()}-new_item`;

    const currentBbchFrom = this.executionStore.bbchList.find(bbch => bbch.value === form.bbshFrom);

    const bbchNameFrom = currentBbchFrom?.initialModel?.name;
    const bbchCodeFrom = currentBbchFrom?.initialModel?.code;
    const bbchDescriptionFrom = currentBbchFrom?.initialModel?.description;

    const currentBbchTo = this.executionStore.bbchList.find(bbch => bbch.value === form.bbshTo);

    const bbchNameTo = currentBbchTo?.initialModel?.name;
    const bbchCodeTo = currentBbchTo?.initialModel?.code;
    const bbchDescriptionTo = currentBbchTo?.initialModel?.description;

    const createdExperimentStep: IExperimentStep = {
      id: stepId,
      name: form.name,
      phenophase: {
        code: bbchCodeFrom,
        description: bbchDescriptionFrom,
        name: bbchNameFrom,
        id: form.bbshFrom,
      },
      phenophaseEnd: {
        code: bbchCodeTo,
        name: bbchNameTo,
        description: bbchDescriptionTo,
        id: form.bbshTo,
      },
      factStartDate: formatDateToISO(new Date(form.startDate)),
      factEndDate: formatDateToISO(new Date(form.endDate)),
      servicePricePerArea: toNumber(form.price),
    };

    this.executionStore.zoneList.forEach(zone => {
      const builderId = createTableBuilderId(zone.id, ETableName.ExecutionSteps);

      const newExperimentStepConfig = this.stepsConfigsService.createStepConfig(
        {
          positionNumber,
          isSkipValues: !(this.executionStore.selectedZoneId === zone.id),
          ...createdExperimentStep,
        },
        builderId
      );

      this.comparisonTableBuilderController.addRowsGroupList(builderId, [
        newExperimentStepConfig.rowsGroupConfig,
      ]);

      Object.entries(newExperimentStepConfig.cellConfigs).forEach(([rowId, cellConfigList]) => {
        this.comparisonTableBuilderController.addCellList(builderId, rowId, cellConfigList);
      });

      if (zone.id === this.executionStore.selectedZoneId) {
        this.executionStore.setStepForUpdate(zone.id, {
          id: stepId,
          phenophaseId: form.bbshFrom,
          phenophaseEndId: form.bbshTo,
          servicePricePerArea: toNumber(form.price),
          startDate: formatDateToISO(new Date(form.startDate)),
          endDate: formatDateToISO(new Date(form.endDate)),
          name: form.name,
          type: EExperimentStepType.NonPlan,
          techOperationTypeId: form.typeOperation,
          usageMethodTypeId: form.usageMethodType,
        });
      } else {
        this.executionStore.setStepForUpdate(zone.id, {
          id: stepId,
          name: form.name,
          type: EExperimentStepType.NonPlan,
          techOperationTypeId: form.typeOperation,
        });
      }
    });

    return stepId;
  };

  deleteNutritionHistoryItem = (
    rowsGroupId: string,
    stepId: string,
    fertilizerId: string
  ): void => {
    this.definePageUpdated();

    this.executionStore.deleteFertilizerId(stepId, fertilizerId);

    this.executionStore.zoneList.forEach(zone => {
      this.executionStore.deleteNutritionHistoryItemFromUpdate(zone.id, stepId, fertilizerId);

      const builderId = createTableBuilderId(zone.id, ETableName.ExecutionSteps);

      this.comparisonTableBuilderController.removeRowsGroup(builderId, rowsGroupId);
    });
  };

  deleteNutritionHistoryProtectionItem = (
    rowsGroupId: string,
    stepId: string,
    protectionId: string
  ): void => {
    this.definePageUpdated();

    this.executionStore.deleteProtectionId(stepId, protectionId);

    this.executionStore.zoneList.forEach(zone => {
      this.executionStore.deleteNutritionHistoryProtectionItemFromUpdate(
        zone.id,
        stepId,
        protectionId
      );

      const builderId = createTableBuilderId(zone.id, ETableName.ExecutionSteps);

      this.comparisonTableBuilderController.removeRowsGroup(builderId, rowsGroupId);
    });
  };

  deleteExperimentStep = (rowsGroupId: string): void => {
    this.executionStore.deleteFertilizersByStepId(rowsGroupId);
    this.executionStore.deleteProtectionsByStepId(rowsGroupId);

    this.executionStore.zoneList.forEach(zone => {
      this.executionStore.deleteStepFromUpdate(zone.id, rowsGroupId);

      const builderId = createTableBuilderId(zone.id, ETableName.ExecutionSteps);

      this.comparisonTableBuilderController.removeRowsGroup(builderId, rowsGroupId);
    });
  };

  initiateMeasureInfo = async (): Promise<void> => {
    const protectionMeasureList = await this.dictionaryService.getDictionaryEntityList({
      remoteName: 'measure',
      dependencyName: 'protection',
    });

    this.executionStore.setMeasureInfoList(protectionMeasureList?.content);
  };

  initiateTables = async (experimentId: string): Promise<void> => {
    const { zones } = await this.fetchFactTable(experimentId);

    if (zones?.length) {
      await this.drawTables(zones);
    }

    this.executionStore.setIsEdited(false);
    this.disablePageLoading();
  };

  initiateTablesValues = (zoneList: IExperimentFactTableZone[]): void => {
    this.initiateZonesValues(zoneList);
  };

  public copyFromAudit = async (
    auditId: string,
    actions: Parameters<ExperimentStepsService['getFactTableByCopiedAudit']>[1]['actions'],
    stepId?: string
  ): Promise<void> => {
    await this.experimentStepsService.getFactTableByCopiedAudit(
      {
        stepId,
        auditId,
      },
      {
        actions: {
          ...actions,
          handleSuccess: async response => {
            this.clearStore();
            await this.drawTables(response.zones);
            this.disablePageLoading();

            this.executionStore.setIsEdited(true);
            actions.handleSuccess(response);
          },
        },
      }
    );
  };

  protected drawTables = async (expZoneList: IExperimentFactTableZone[]): Promise<void> => {
    await Promise.all(expZoneList.map(zone => this.fetchCultureZone(zone.id)));

    this.initiateTablesValues(expZoneList);

    const stepIdList = expZoneList[0].steps.map(({ id }) => id);
    this.executionStore.setStepIdList(stepIdList);

    expZoneList.forEach(zone => {
      this.initiateStepsTable(zone);
      this.initiateCalculationsTable(zone);
    });

    const zoneList = this.createZoneList(expZoneList);

    this.executionStore.setSelectedZoneId(expZoneList[0].id);
    this.executionStore.setZoneList(zoneList);
  };

  protected definePageUpdated = (): void => {
    this.executionStore.setIsEdited(true);
  };

  protected initiateZonesValues = (zoneList: IExperimentFactTableZone[]): void => {
    zoneList.forEach(zone => {
      const calculationsAttributeList = zone.calculations?.[0]?.attributes;

      const cropPrice = calculationsAttributeList.find(
        attribute => attribute.id === CalculationsAttributeId.CropPrice
      )?.values?.[1]?.doubleValue;

      const yieldValue = calculationsAttributeList.find(
        attribute => attribute.id === CalculationsAttributeId.Yield
      )?.values?.[1]?.doubleValue;

      this.executionStore.setZoneForUpdate(
        {
          cultureZoneExperimentId: zone.id,
          cropPrice,
          yield: yieldValue,
        },
        {
          isUpdate: true,
        }
      );

      this.initiateStepsValues(zone.id, zone.steps);
    });
  };

  protected initiateStepsValues = (zoneId: string, stepList: IExperimentFactTableStage[]): void => {
    stepList.forEach(step => {
      const startDate = step.attributes.find(
        attribute => attribute.id === StepAttributeId.StartDate
      )?.values?.[1]?.dateValue?.[0];

      const endDate = step.attributes.find(attribute => attribute.id === StepAttributeId.EndDate)
        ?.values?.[1]?.dateValue?.[0];

      const phenophaseId = step.attributes.find(
        attribute => attribute.id === StepAttributeId.Phenophase
      )?.values?.[1]?.dictionaryValues?.[0]?.id;

      const phenophaseEndId = step.attributes.find(
        attribute => attribute.id === StepAttributeId.PhenophaseEnd
      )?.values?.[1]?.dictionaryValues?.[0]?.id;

      const servicePricePerArea = step.attributes.find(
        attribute => attribute.id === StepAttributeId.ServicePricePerArea
      )?.values?.[1]?.doubleValue;

      const fertilizerList = step.attributes.find(
        attribute => attribute.id === StepAttributeId.Fertilizers
      )?.values;

      const protectionList = step.attributes.find(
        attribute => attribute.id === StepAttributeId.Protections
      )?.values;

      this.executionStore.setStepForUpdate(
        zoneId,
        {
          id: step.id,
          startDate,
          endDate,
          phenophaseId,
          phenophaseEndId,
          servicePricePerArea,
        },
        {
          isUpdate: true,
        }
      );

      this.initiateItemValues(zoneId, step.id, fertilizerList);
      this.initiateProtectionItemValues(zoneId, step.id, protectionList);
    });
  };

  protected initiateItemValues = (
    zoneId: string,
    stepId: string,
    fertilizerList: IExperimentFactTableValue[]
  ): void => {
    fertilizerList.forEach(fertilizer => {
      const fertilizerAttributesList = fertilizer.stages?.[0]?.attributes;

      const planFertilizerInfo = fertilizerAttributesList.find(
        fertilizerAttribute => fertilizerAttribute.id === StepAttributeId.Fertilizer
      )?.values?.[0];

      const factFertilizerInfo = fertilizerAttributesList.find(
        fertilizerAttribute => fertilizerAttribute.id === StepAttributeId.Fertilizer
      )?.values?.[1];

      const fertilizerId =
        factFertilizerInfo?.dictionaryValues?.[0]?.id ||
        planFertilizerInfo?.dictionaryValues?.[0]?.id;

      const entityId = factFertilizerInfo?.entityId;

      const concentration = fertilizerAttributesList.find(
        fertilizerAttribute => fertilizerAttribute.id === StepAttributeId.Concentration
      )?.values?.[1]?.doubleValue;

      const pricePerUnit = fertilizerAttributesList.find(
        fertilizerAttribute => fertilizerAttribute.id === StepAttributeId.PricePerUnit
      )?.values?.[1]?.doubleValue;

      const fertilizerValues: NutritionHistoryItemUpdate = {
        fertilizerId,
        concentration,
        id: entityId,
        pricePerUnit,
      };

      this.executionStore.setItemForUpdate(zoneId, stepId, fertilizerValues, {
        isUpdate: true,
        isInitialise: true,
      });
    });

    this.executionStore.setIsEdited(false);
  };

  protected initiateProtectionItemValues = (
    zoneId: string,
    stepId: string,
    protectionList: IExperimentFactTableValue[]
  ): void => {
    protectionList.forEach(async protection => {
      const protectionAttributesList = protection.stages?.[0]?.attributes;

      const planProtectionInfo = protectionAttributesList.find(
        protectionAttribute => protectionAttribute.id === StepAttributeId.Protection
      )?.values?.[0];

      const factProtectionInfo = protectionAttributesList.find(
        protectionAttribute => protectionAttribute.id === StepAttributeId.Protection
      )?.values?.[1];

      const protectionId =
        factProtectionInfo?.dictionaryValues?.[0]?.id ||
        planProtectionInfo?.dictionaryValues?.[0]?.id;

      const entityId = factProtectionInfo?.entityId;

      const concentration = protectionAttributesList.find(
        protectionAttribute => protectionAttribute.id === StepAttributeId.Concentration
      )?.values?.[1]?.doubleValue;

      const pricePerUnit = protectionAttributesList.find(
        protectionAttribute => protectionAttribute.id === StepAttributeId.PricePerUnit
      )?.values?.[1]?.doubleValue;

      const unitOfMeasureInfo = await this.inventoryValueController.fetchProtectionMeasure(
        protectionId
      );

      const protectionValues: NutritionHistoryProtectionItemUpdate = {
        protectionId,
        concentration,
        id: entityId,
        pricePerUnit,
        unitOfMeasureId: unitOfMeasureInfo.id,
      };

      this.executionStore.setProtectionItemForUpdate(zoneId, stepId, protectionValues, {
        isUpdate: true,
        isInitialise: true,
      });
    });
  };

  changeFertilizer: ExecutionInputsService['changeFertilizer'] = (stepId, changedFertilizer) => {
    this.inputsService.changeFertilizer(stepId, changedFertilizer);
  };

  changeProtection: ExecutionInputsService['changeProtection'] = (stepId, changedProtection) => {
    this.inputsService.changeProtection(stepId, changedProtection);
  };

  changeStep: ExecutionInputsService['changeStep'] = (stepId, stepData) => {
    this.inputsService.changeStep(stepId, stepData);
  };

  changeZone: ExecutionInputsService['changeZone'] = zoneData => {
    this.inputsService.changeZone(zoneData);
  };

  saveFactTableData = async (
    experimentId: string,
    actions: {
      success?: (response: TApiResponse<'updateExperimentFactTable'>) => void;
      rejected?: (e: AxiosError) => void;
    }
  ): Promise<boolean> => {
    const formattedZoneList = this.inputsService.getFormattedZoneList();

    const response = await this.experimentsService.updateExperimentFactTable(
      {
        id: experimentId,
        zoneList: formattedZoneList,
      },
      {
        actions,
      }
    );

    if (response) {
      this.executionStore.clearZonesForUpdateByZoneId();
      this.executionStore.clearStepsForUpdateByStepIdByZoneId();
      this.executionStore.clearItemsForUpdateByItemIdByStepIdByZoneId();

      this.executionStore.clearProtectionItemsForUpdateByItemIdByStepIdByZoneId();

      return true;
    }
  };

  fetchCultureZone = async (id: string): Promise<void> => {
    const response = await this.experimentsService.getCultureZone({
      id,
    });

    if (response) {
      this.executionStore.setExperimentZone(response);
    }
  };

  enablePageLoading = (): void => {
    this.executionStore.setIsPageLoading(true);
  };

  disablePageLoading = (): void => {
    this.executionStore.setIsPageLoading(false);
  };

  clearStore = (): void => {
    this.executionStore.zoneList.forEach(zone => {
      this.comparisonTableBuilderController.clearStore(
        createTableBuilderId(zone.id, ETableName.ExecutionSteps)
      );

      this.comparisonTableBuilderController.clearStore(
        createTableBuilderId(zone.id, ETableName.ExecutionCalculations)
      );
    });

    this.executionStore.clearIsPageLoading();
    this.executionStore.clearIsNeedToRefreshPage();
    this.executionStore.clearIsEdited();

    this.executionStore.clearSelectedZoneId();
    this.executionStore.clearZoneList();
    this.executionStore.clearBbchList();
    this.executionStore.clearExperimentZonesById();

    this.executionStore.clearStepIds();
    this.executionStore.clearFertilizerIdsByStepId();
    this.executionStore.clearProtectionIdsByStepId();

    this.executionStore.clearZonesForUpdateByZoneId();
    this.executionStore.clearStepsForUpdateByStepIdByZoneId();
    this.executionStore.clearItemsForUpdateByItemIdByStepIdByZoneId();

    this.executionStore.clearProtectionItemsForUpdateByItemIdByStepIdByZoneId();

    this.executionStore.clearCompletedAuditList();

    this.executionStore.clearInvalidRowIdsByZoneId();
    this.executionStore.clearSelectedDictionaryOptionsByRowIdByZoneId();
  };

  protected createCarouselItem = (zone: TZone): TCarouselItem => {
    const experimentType = {
      EXPERIMENT: 'Опытный',
      CONTROL: 'Контрольный',
    };

    return {
      id: zone.id,
      description: `${experimentType[zone.type]} - ${formatDoubleNum(zone.area)} га`,
      name: zone.name,
      img: '',
    };
  };

  protected createZoneList = (factTableZoneList: IExperimentFactTableZone[]): TZone[] => {
    return factTableZoneList.map(zone => omit(zone, ['calculations', 'steps']));
  };

  protected fetchFactTable = async (experimentId: string): Promise<IExperimentFactTable> => {
    const response = await this.experimentsService.getExperimentFactTable({ id: experimentId });

    if (response) {
      return response;
    }

    return {} as IExperimentFactTable;
  };

  protected initiateStepsTable = (zone: IExperimentFactTableZone): void => {
    const config = this.stepsConfigsService.createTableConfig(
      createTableBuilderId(zone.id, ETableName.ExecutionSteps),
      zone.steps
    );

    this.comparisonTableBuilderController.initiateTable(config);
  };

  protected initiateCalculationsTable = (zone: IExperimentFactTableZone): void => {
    const config = this.calculationsConfigsService.createTableConfig(
      createTableBuilderId(zone.id, ETableName.ExecutionCalculations),
      zone.calculations
    );

    this.comparisonTableBuilderController.initiateTable(config);
  };

  fetchExperimentSteps = async (experimentId: string, cultureZoneExperimentId: string) => {
    const experimentStepList = await this.experimentStepsService.getExperimentStepList({
      experimentId,
      cultureZoneExperimentId,
      type: EExperimentStepType.Audit,
    });

    this.prepareValidAuditList(experimentStepList);
  };

  private prepareValidAuditList = (experimentStepList: IExperimentStep[]): void => {
    const completedAuditList = experimentStepList
      .filter(item => item.taskStatus === ETaskStatus.Completed)
      .map(item => ({
        label: item.name,
        value: item.id,
        initialModel: item,
      }));

    this.executionStore.setCompletedAuditOptionList(completedAuditList);
  };
}

export default ExecutionController;
