import { FC } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import {
  EExperimentCultureZoneType as ZoneType,
  EExpZoneStyle,
} from '../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesController } from '../../../../mobx/controllers';
import { useMap } from '../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { ZonesStore } from '../../../../mobx/stores';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';
import {
  ZonesBlockedTypeIcon,
  ZonesControlTypeIcon,
  ZonesExperimentTypeIcon,
} from '../../../../../../../../../../../../../common/components/ui/icons/zones';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ZoneListTypeSelectionCell.styles';

interface IProps {
  rowData?: ICultureZoneCell;
}

const ZoneListTypeSelectionCell: FC<IProps> = ({ rowData }) => {
  const { setTableHasChanged } = useStore(ZonesStore);
  const { changeZoneType } = useStore(ZonesController);
  const { selectedExp } = useStore(CreateExperimentStore);

  const { changeCultureZoneStyle } = useMap();

  const handleChange = (value: ZoneType) => {
    setTableHasChanged(true);

    const isControlValue = value === ZoneType.Control;

    const styleType: EExpZoneStyle = isControlValue
      ? EExpZoneStyle.Control
      : EExpZoneStyle.Experiment;

    changeZoneType({ ...rowData.fwExpCultureZone, type: value, styleType }, changeCultureZoneStyle);
  };

  const hasDraftStatus: boolean = selectedExp?.status === EExperimentStatus.Draft;
  const isZoneSelected: boolean = rowData?.fwExpCultureZone?.isSelected;

  const isControlDisabled: boolean =
    rowData?.isDisabledControlTypeSelection || !isZoneSelected || !hasDraftStatus;

  const isExperimentDisabled: boolean = !isZoneSelected || !hasDraftStatus;

  const isControlActive: boolean = rowData.type === ZoneType.Control && isZoneSelected;
  const isExperimentActive: boolean = rowData.type === ZoneType.Experiment && isZoneSelected;

  const checkIfNeedToShowControl = (): boolean => {
    if (hasDraftStatus) return true;

    return isControlActive;
  };

  const checkIfNeedToShowExperiment = (): boolean => {
    if (hasDraftStatus) return true;

    return isExperimentActive;
  };

  const getDataTestId = useDataTestIdV2('zone-list__type-selection-cell');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {checkIfNeedToShowControl() ? (
        <AutoTooltip content={'Контрольный'}>
          <div>
            <ZonesControlTypeIcon
              dataTestId={getDataTestId()['data-test-id']}
              onClick={() => handleChange(ZoneType.Control)}
              isDisabled={isControlDisabled}
              isActive={isControlActive}
            />
          </div>
        </AutoTooltip>
      ) : (
        <div>
          <ZonesBlockedTypeIcon dataTestId={getDataTestId()['data-test-id']} />
        </div>
      )}

      {checkIfNeedToShowExperiment() ? (
        <AutoTooltip content={'Опытный'}>
          <div>
            <ZonesExperimentTypeIcon
              dataTestId={getDataTestId()['data-test-id']}
              onClick={() => handleChange(ZoneType.Experiment)}
              isDisabled={isExperimentDisabled}
              isActive={isExperimentActive}
            />
          </div>
        </AutoTooltip>
      ) : (
        <div>
          <ZonesBlockedTypeIcon dataTestId={getDataTestId()['data-test-id']} />
        </div>
      )}
    </Styled.Wrapper>
  );
};

ZoneListTypeSelectionCell.displayName = 'ZoneListTypeSelectionCell';

export default observer(ZoneListTypeSelectionCell);
