import styled from 'styled-components';
import { Colors, IconButton } from '@farmlink/farmik-ui';

const InputRowWrapper = styled.form`
  position: relative;
  z-index: 901;
  top: auto;
  bottom: -30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  & > div {
    width: 100%;
  }
  textarea {
    position: absolute;
    top: auto;
    bottom: -40px;
    max-height: 100px;
  }
  @media (max-height: 1035px) {
    bottom: 0;
  }
  @media (max-height: 766px) {
    bottom: 4%;
  }
  @media (max-height: 640px) {
    bottom: 7%;
  }
  @media (max-height: 600px) {
    bottom: 9%;
  }
`;

const InputBackgound = styled.span<{ $inputHeight: number }>`
  position: absolute;
  z-index: 900;
  width: 100%;
  height: ${({ $inputHeight }) => `${35 + $inputHeight}px`};
  top: ${({ $inputHeight }) => `-${$inputHeight - 20}px`};
  background-color: ${Colors.generalWhite};
  @media (max-height: 850px) {
    height: ${({ $inputHeight }) => `${20 + $inputHeight}px`};
  }
`;

const InputButton = styled(IconButton)`
  position: relative;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
  z-index: 901;
`;

const Styled = {
  InputRowWrapper,
  InputBackgound,
  InputButton,
};

export default Styled;
