import { IFormConfig } from '../../../../../../../../../../../../common/features/form/models';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';

const REQUIRED_TEXT = 'Это поле не может быть пустым';

export const ZONES_ADD_FACT_ZONE_FORM_KEY = 'zones__add-fact-zone';

export interface IZonesAddFactZoneForm {
  cultureZoneId: string;
  cultureZoneName: string;
  previousCultureIds: string;
}

export const createZonesAddFactZoneFormConfig = ({
  culturesSearchQueryHandler,
}: {
  culturesSearchQueryHandler: (v: string) => Promise<ISelectOption[]>;
}): IFormConfig<IZonesAddFactZoneForm> => {
  return {
    formKey: ZONES_ADD_FACT_ZONE_FORM_KEY,
    form: {
      cultureZoneId: '',
      cultureZoneName: '',
      previousCultureIds: '',
    },
    elements: {
      cultureZoneId: {
        name: 'cultureZoneId',
        order: 1,
        type: 'select',
        label: 'Фактический участок',
        placeholder: 'Выберите или укажите значение',
        isRequired: true,
        schema: {
          isShownError: false,
          errorTitle: REQUIRED_TEXT,
        },
      },
      cultureZoneName: {
        name: 'cultureZoneName',
        order: 2,
        type: 'input',
        label: 'Название участка',
        placeholder: 'Укажите значение',
        inputOptions: {
          maxLength: 50,
        },
      },
      previousCultureIds: {
        name: 'previousCultureIds',
        order: 3,
        type: 'select',
        label: 'Культура-предшественник',
        placeholder: 'Выберите или укажите значение',
        selectOptions: {
          search: {},
          searchQuery: {
            handler: culturesSearchQueryHandler,
          },
        },
      },
    },
  };
};
