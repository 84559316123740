import { agrochemicalModalConfig } from '../../../../../calculation/containers/Calculation/config/modals/createAgrochemicalModal';

import { changeCultureZoneNameModalConfig } from './changeCultureZoneName';
import { addPreviousCultureModalConfig } from './addPreviousCulture';
import { editZoneInfoModalConfig } from './EditZoneInfoModal';
import { ZonesAddFactZoneModalConfig } from './ZonesAddFactZoneModal';
import { ZonesEditFactZoneModalConfig } from './ZonesEditFactZoneModel';
import { ZonesCopyAhoModalConfig } from './ZonesCopyAhoModal';

const KEY = 'createExperimentZones';

const NAME = {
  changeCultureZoneName: changeCultureZoneNameModalConfig.name,
  addPreviousCulture: addPreviousCultureModalConfig.name,
  editZoneInfo: editZoneInfoModalConfig.name,
};

const CONFIG_LIST = [
  changeCultureZoneNameModalConfig,
  addPreviousCultureModalConfig,
  editZoneInfoModalConfig,
  ZonesAddFactZoneModalConfig,
  ZonesEditFactZoneModalConfig,
  ...agrochemicalModalConfig,
  ZonesCopyAhoModalConfig,
];

const zonesModalConfig = {
  key: KEY,
  name: NAME,
  configList: CONFIG_LIST,
};

export default zonesModalConfig;
