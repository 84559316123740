import React, { FC, memo } from 'react';

import { TTDropdownFactoryLabelDefault as TLabelDefault } from '../../../../types';
import { useDropdownFactoryDataTestId as useDataTestId } from '../../../../hooks';

import Styled from './DropdownFactoryLabelDefault.styles';

const DropdownFactoryLabelDefault: FC<TLabelDefault> = ({ value, styles }) => {
  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper {...getDataTestId('label-default')} $styles={styles}>
      {value}
    </Styled.Wrapper>
  );
};

DropdownFactoryLabelDefault.displayName = 'DropdownFactoryLabelDefault';

export default memo(DropdownFactoryLabelDefault);
