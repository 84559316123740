import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import {
  ZonesCopyAhoModalBody,
  ZonesCopyAhoModalDescription,
  ZonesCopyAhoModalTitle,
} from './components';

const ZonesCopyAhoModalConfig: TModalConfig = {
  name: 'zones__copy-aho-modal',
  type: EModalType.Custom,
  title: <ZonesCopyAhoModalTitle />,
  description: {
    value: () => <ZonesCopyAhoModalDescription />,
  },
  styles: {
    title: {
      $size: 'large',
      $margin: '0 auto 6px 0',
    },
  },
  styledProps: {
    $size: '600px',
    $height: 'none',
    $fullHeight: true,
    $modalPaddings: '40px 60px',
  },
  id: 'zones__copy-aho-modal',
  children: () => <ZonesCopyAhoModalBody />,
};

export default ZonesCopyAhoModalConfig;
