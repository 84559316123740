import { FC, memo, ReactNode, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../utils/helpers/mobx';
import { ContentLoader } from '../UI/loaders/ContentLoader';

import { TableBuilderController as Controller } from './mobx/controllers';
import Styled from './TableBuilder.styles';
import { TableBuilderUIContext as UIContext } from './context/UI';
import { TableBuilderHeader as Header } from './containers/header';
import {
  TableBuilderContent as Content,
  TableBuilderPlugContainer as PlugContainer,
} from './containers';
import {
  useTableBuilderDataTestId as useDataTestId,
  useTableBuilderInfiniteScroll as useInfiniteScroll,
} from './hooks';
import { TableBuilderStore as Store } from './mobx/stores';
import { TTableBuilderStylePreset as TStylePreset } from './types/styles';

interface IProps {
  /**
   * Уникальный идентификатор таблицы [билдера].
   */
  builderId: string;
  /**
   * Функция-рендер заглушки по умолчанию.
   * Пример: состояние таблицы, когда не было выполнено ни одного запроса.
   */
  renderDefaultPlug?: () => ReactNode;
  /**
   * Функция-рендер заглушки, когда таблица пустая, т.е. без данных.
   */
  renderNoDataPlug?: () => ReactNode;
  /**
   * Глобальный пресет стилей, где:
   * 'primary' — основной стиль таблицы согласно макетам;
   * 'cleared' — стиль без border и padding.
   */
  stylePreset?: TStylePreset;
  /**
   * Выбор типа разделителя в таблице.
   * Например, borderType = dashed будет делать разделитель штриховкой.
   */
  borderType?: string;
  isNeedToAddBordersToRows?: boolean;
  scrollPadding?: string;
  isHideScroll?: boolean;
  /**
   * Скрывает отображение хедера
   */
  hideHeader?: boolean;
}

const TableBuilder: FC<IProps> = ({
  builderId,
  renderDefaultPlug,
  renderNoDataPlug,
  stylePreset,
  borderType,
  isNeedToAddBordersToRows,
  scrollPadding,
  isHideScroll,
  hideHeader = false,
}) => {
  const getDataTestId = useDataTestId(builderId);

  const store = useStore(Store);
  const controller = useStore(Controller);

  const { tableRef, isShowLoaderByScroll } = useInfiniteScroll(builderId);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  /**
   * Данная логика необходима для того, чтобы явно зафиксировать высоту оверлея загрузки при пагинации.
   * В противном случае, из-за overflow-hidden, корректную высоту контента через ref получить невозможно.
   */
  const loaderOverlayHeight = useMemo<`${number}px`>(() => {
    const headerOffsetHeight = headerRef.current?.offsetHeight ?? 0;
    const contentOffsetHeight = contentRef.current?.offsetHeight ?? 0;

    // Производим расчет скрытого контента.
    const contentHeightWithHiddenOverflow = contentOffsetHeight + headerOffsetHeight;

    /**
     * Если у нас нет скрытого контента (не отображается скролл),
     * то просто возвращаем исходную высоту контента,
     * иначе возвращаем рассчитанную высоту (высота хэдера + видимый контент).
     */
    if (contentOffsetHeight < contentHeightWithHiddenOverflow) {
      return `${contentOffsetHeight}px`;
    }

    return `${contentHeightWithHiddenOverflow}px`;
  }, [headerRef.current?.offsetHeight, contentRef.current?.offsetHeight]);

  const rowList = store.getRowList(builderId);
  const isFetchingElements = store.getIsFetchingElements(builderId);
  const isShowDefaultPlug = store.getIsShowDefaultPlug(builderId);

  const DefaultPlug = useMemo(() => {
    if (isShowDefaultPlug) {
      return renderDefaultPlug?.() || <Styled.DefaultPlug {...getDataTestId('default-plug')} />;
    }
  }, [renderDefaultPlug, isShowDefaultPlug]);

  const NoDataPlug = useMemo(() => {
    if (!rowList.length && renderNoDataPlug) {
      return renderNoDataPlug();
    }
  }, [renderNoDataPlug, rowList]);

  useEffect(() => {
    return () => {
      controller.clearStore(builderId);
    };
  }, []);

  const loaderDataTestId = getDataTestId('content-loader')['data-test-id'];

  if (DefaultPlug || NoDataPlug) {
    return <PlugContainer builderId={builderId}>{DefaultPlug || NoDataPlug}</PlugContainer>;
  }

  return (
    <UIContext.Provider value={{ builderId }}>
      <Styled.Wrapper $preset={stylePreset} {...getDataTestId()}>
        <Styled.HeaderWrapper ref={headerRef} {...getDataTestId('header-wrapper')}>
          {!hideHeader ? <Header borderType={borderType} /> : null}
        </Styled.HeaderWrapper>

        <Styled.ContentWrapper ref={contentRef} {...getDataTestId('content-wrapper')}>
          {isShowLoaderByScroll || isFetchingElements ? (
            <ContentLoader
              parentRef={contentRef}
              overlayStyles={{
                height: loaderOverlayHeight,
                borderRadius: '0 0 16px 16px',
                zIndex: '3',
              }}
              dataTestId={loaderDataTestId}
            />
          ) : null}

          <Content
            borderType={borderType}
            isNeedToAddBordersToRows={isNeedToAddBordersToRows}
            ref={tableRef}
            scrollPadding={scrollPadding}
            isHideScroll={isHideScroll}
          />
        </Styled.ContentWrapper>
      </Styled.Wrapper>
    </UIContext.Provider>
  );
};

TableBuilder.displayName = 'TableBuilder';

export default memo(observer(TableBuilder));
