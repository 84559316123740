import styled from 'styled-components';

const Wrapper = styled.div`
  width: 40px;
  display: flex;
  justify-content: end;
`;

const StyledZoneListNonDraftStatusActionsCell = {
  Wrapper,
};

export default StyledZoneListNonDraftStatusActionsCell;
