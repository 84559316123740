import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, Icon, useModal, useShowTooltip } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CalculationStore } from '../../../../../../../../calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../common/utils/hooks/locators';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { IZonesAddFactZoneModalPayload } from '../ZonesAddFactZoneModalBody/ZonesAddFactZoneModalBody';

import Styled from './ZonesAddFactZoneModalDescription.styles';

const ZonesAddFactZoneModalDescription: FC = () => {
  const calculationStore = useStore(CalculationStore);

  const modal = useModal();

  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  const modalPayload = modal.getModalPayload() as IZonesAddFactZoneModalPayload;

  const iconType = useMemo(() => {
    return calculationStore.activeCultureZone.type === EExperimentCultureZoneType.Control
      ? 'controlField'
      : 'experimentField';
  }, [calculationStore.activeCultureZone.type]);

  const getDataTestId = useDataTestIdV2('zones__add-fact-zone__description');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Icon dataTestId={getDataTestId('icon')['data-test-id']} icon={iconType} size={'medium'} />

      <AutoTooltip
        dataTestId={getDataTestId('tooltip')['data-test-id']}
        content={modalPayload?.formattedParenZoneName}
        disabled={!isShowTooltip}
      >
        <Styled.Name {...getDataTestId('name')} ref={ref}>
          {`Для планового: ${modalPayload?.formattedParenZoneName}`}
        </Styled.Name>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

ZonesAddFactZoneModalDescription.displayName = 'ZonesAddFactZoneModalDescription';

export default observer(ZonesAddFactZoneModalDescription);
