import React, { FC, memo } from 'react';

import { TDropdownFactoryIcon as TIcon } from '../../types';

import { DropdownFactoryIconCustom as IconCustom } from './components';

const DropdownFactoryIcon: FC<TIcon> = props => {
  switch (props.type) {
    case 'custom':
      return <IconCustom {...props} />;

    default:
      return <></>;
  }
};

DropdownFactoryIcon.displayName = 'DropdownFactoryIcon';

export default memo(DropdownFactoryIcon);
