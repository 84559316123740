import { makeAutoObservable } from 'mobx';
import { isArray } from 'lodash';

import { provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { ITask } from '../../../../../../../../../../api/models/as-fields/task/Task';

@provide.singleton()
class AuditTasksStore {
  private _totalPageNumber = 0;

  private _currentPageNumber = 0;

  private _tasksById: Map<string, ITask> = new Map<string, ITask>();

  private _isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get totalPageNumber() {
    return this._totalPageNumber;
  }

  get currentPageNumber() {
    return this._currentPageNumber;
  }

  get taskList() {
    return [...this._tasksById.values()];
  }

  get isLoading() {
    return this._isLoading;
  }

  setTotalPageNumber = (value: number): void => {
    this._totalPageNumber = value;
  };

  setCurrentPageNumber = (value: number): void => {
    this._currentPageNumber = value;
  };

  setTaskList = (
    taskList: ITask[],
    options?: {
      isClearPreviousList?: boolean;
    }
  ): void => {
    const previousEntryList = options?.isClearPreviousList ? [] : [...this._tasksById.entries()];

    const newCollection: Map<string, ITask> = new Map<string, ITask>(previousEntryList);

    if (isArray(taskList)) {
      taskList.forEach(task => newCollection.set(task.id, task));
    }

    this._tasksById = newCollection;
  };

  setIsLoading = (isPageLoading: boolean): void => {
    this._isLoading = isPageLoading;
  };

  clearTotalPageNumber = (): void => {
    this._totalPageNumber = 0;
  };

  clearCurrentPageNumber = (): void => {
    this._currentPageNumber = 0;
  };

  clearTasksById = (): void => {
    this._tasksById.clear();
  };

  clearIsPageLoading = (): void => {
    this._isLoading = true;
  };

  clearAuditTasksStore = (): void => {
    this.clearTotalPageNumber();
    this.clearCurrentPageNumber();
    this.clearIsPageLoading();

    this.clearTasksById();
  };
}

export default AuditTasksStore;
