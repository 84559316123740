import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ButtonRemove.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__tolls__basket-icon.
   */
  dataTestId: string;
  onClick?: () => void;
}

const ToolsBasketIcon: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__tolls__basket-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (!onClick) return;

    event.stopPropagation();
    onClick();
  };

  return (
    <Styled.Wrapper
      onClick={handleClick}
      {...getDataTestId()}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.78564 7.47113H6.6161V13.9164H5.78564V7.47113Z" fill="#DF2E22" />
      <path d="M7.58484 7.47113H8.4153V13.9164H7.58484V7.47113Z" fill="#DF2E22" />
      <path d="M9.38409 7.47113H10.2146V13.9164H9.38409V7.47113Z" fill="#DF2E22" />
      <path
        d="M13.2844 7.13899H12.9907L13.1209 5.74935H14.1039V4.38837C14.1039 3.63793 13.4845 3.02738 12.7233 3.02738H11.1034L10.1588 1.16498H5.84127L4.89663 3.02738H3.27681C2.51554 3.02738 1.89617 3.63793 1.89617 4.38837V5.74935H2.87921L3.00937 7.13899H2.71566C1.95439 7.13899 1.33502 7.74955 1.33502 8.49998C1.33502 9.25041 1.95439 9.86096 2.71566 9.86096H3.26431L3.82386 15.835H12.1762L12.7357 9.86096H13.2844C14.0457 9.86096 14.665 9.25041 14.665 8.49998C14.665 7.74955 14.0457 7.13899 13.2844 7.13899ZM6.38022 2.02455H9.61989L10.1285 3.02738H5.87156L6.38022 2.02455ZM2.76815 4.38837C2.76815 4.1119 2.99635 3.88695 3.27681 3.88695H12.7233C13.0037 3.88695 13.2319 4.1119 13.2319 4.38837V4.88978H2.76815V4.38837ZM2.71566 9.00139C2.4352 9.00139 2.207 8.77644 2.207 8.49998C2.207 8.22351 2.4352 7.99856 2.71566 7.99856H3.08988L3.18382 9.00139H2.71566ZM11.381 14.9754H4.61905L3.75491 5.74935H12.2452L11.381 14.9754ZM13.2844 9.00139H12.8163L12.9102 7.99856H13.2844C13.5649 7.99856 13.7931 8.22351 13.7931 8.49998C13.7931 8.77644 13.5649 9.00139 13.2844 9.00139Z"
        fill="#DF2E22"
      />
    </Styled.Wrapper>
  );
};

ToolsBasketIcon.displayName = 'ToolsBasketIcon';

export default memo(ToolsBasketIcon);
