import React, { FC, MouseEvent } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../common/utils/hooks/locators';

import Styled from './ExecutionAuditCardIconCopy.styles';

interface IProps {
  dataTestId: string;
  onClick: () => void;
  isDisabled?: boolean;
}

const ExecutionAuditCardIconCopy: FC<IProps> = ({ onClick, dataTestId, isDisabled }) => {
  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (isDisabled) return;

    event.stopPropagation();
    onClick();
  };

  const tooltipText = isDisabled
    ? 'Перенос доступен только для\nзавершённых задач'
    : 'Перенести данные из наблюдения';

  const getDataTestId = useDataTestIdV2(`${dataTestId}__icon-copy`);

  return (
    <AutoTooltip content={tooltipText} position={'bottom'}>
      <div>
        <Styled.Wrapper
          {...getDataTestId()}
          onClick={handleClick}
          isDisabled={isDisabled}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="8" fill="#F3F4F8" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.52808 8.24491C6.52808 7.32444 7.27427 6.57825 8.19474 6.57825H14.0281C14.3042 6.57825 14.5281 6.8021 14.5281 7.07825C14.5281 7.35439 14.3042 7.57825 14.0281 7.57825H8.19474C7.82655 7.57825 7.52808 7.87672 7.52808 8.24491V14.0782C7.52808 14.3544 7.30422 14.5782 7.02808 14.5782C6.75193 14.5782 6.52808 14.3544 6.52808 14.0782V8.24491Z"
            fill="#151F32"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4717 9.42175H9.47168L9.47168 16.4218H16.4717V9.42175ZM9.47168 8.42175C8.91939 8.42175 8.47168 8.86947 8.47168 9.42175V16.4218C8.47168 16.974 8.91939 17.4218 9.47168 17.4218H16.4717C17.024 17.4218 17.4717 16.974 17.4717 16.4218V9.42175C17.4717 8.86947 17.024 8.42175 16.4717 8.42175H9.47168Z"
            fill="#151F32"
          />
        </Styled.Wrapper>
      </div>
    </AutoTooltip>
  );
};

ExecutionAuditCardIconCopy.displayName = 'ExecutionAuditCardIconCopy';

export default ExecutionAuditCardIconCopy;
