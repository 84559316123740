import React, { FC, MouseEvent, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ToolsPencilIcon.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__tools__pencil-icon.
   */
  dataTestId: string;
  onClick?: () => void;
}

const ToolsPencilIcon: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__tools__pencil-icon`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    if (!onClick) return;

    event.stopPropagation();
    onClick();
  };

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onClick={handleClick}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4695 1.91204L14.5893 3.0319C14.8521 3.29452 14.9999 3.6508 15 4.02234C15.0001 4.39389 14.8527 4.75027 14.59 5.01308L4.10103 15.5H1V12.3983L11.4883 1.91204C11.751 1.64931 12.1073 1.50171 12.4789 1.50171C12.8504 1.50171 13.2067 1.64931 13.4695 1.91204ZM2.40521 14.0946H3.51944L10.946 6.66939L9.83181 5.55444L2.40521 12.9797V14.0946ZM11.8403 5.7746L10.7261 4.66036L12.4789 2.9082L13.5932 4.02244L11.8403 5.7746ZM13.6457 15.4998H5.55518L6.96027 14.0947H13.6457V15.4998Z"
        fill="#151F32"
      />
    </Styled.Wrapper>
  );
};

ToolsPencilIcon.displayName = 'ToolsPencilIcon';

export default memo(ToolsPencilIcon);
