import { Input } from '@farmlink/farmik-ui';
import { forwardRef, memo, useImperativeHandle, useRef } from 'react';

import Styled from './styles';
import { TInputProps } from './types';

const MAXIMAL_LENGTH_DEFAULT = 100;

const InputContent = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      onChange,
      label,
      isRequired,
      className,
      isDisabled,
      isBlocked,
      value = '',
      placeholder,
      type,
      error,
      isWithoutErrorText,
      maxLength,
      dataTestId,
      id,
      onBlur,
      as = 'input',
      isResizable,
      height,
      hideScrollbar,
      pattern,
      onKeyPress,
      rows,
    },
    ref
  ) => {
    const refElement = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
      return refElement.current;
    });

    const handleChange = (inputValue: string): void => {
      onChange?.(inputValue);
    };

    return (
      <Styled.Wrapper data-test-id="input-wrapper">
        <Styled.TopBlock data-test-id="input-top-block">
          {label && (
            <Styled.LabelWrapper data-test-id="input-label-wrapper">
              <Styled.Label required={isRequired} data-test-id="input-label">
                {label}
              </Styled.Label>
            </Styled.LabelWrapper>
          )}
        </Styled.TopBlock>

        <Input
          className={className}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={handleChange}
          blocked={isDisabled || isBlocked}
          disabled={isDisabled || isBlocked}
          onKeyPress={onKeyPress ? onKeyPress : () => null}
          maxLength={maxLength ? maxLength : MAXIMAL_LENGTH_DEFAULT}
          data-test-id={dataTestId}
          id={id}
          ref={refElement}
          onBlur={onBlur}
          as={as}
          pattern={pattern}
          autoComplete={'off'}
          rows={rows}
          error={error}
          hideScrollbar={hideScrollbar}
          isResizable={isResizable}
          height={height}
        />

        {error && !isWithoutErrorText && (
          <Styled.ErrorMessage data-test-id={`${dataTestId}_errorMessage`}>
            {error}
          </Styled.ErrorMessage>
        )}
      </Styled.Wrapper>
    );
  }
);

InputContent.displayName = 'Input';

export default memo(InputContent);
