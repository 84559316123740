import styled, { css } from 'styled-components';

import { ReactComponent as Copy } from '../../../../../history/containers/History/assets/icons/copy.svg';

const Wrapper = styled.div<{ $isReducePaddingTop: boolean }>`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e9e6f0;

  ${({ $isReducePaddingTop }) => {
    if (!$isReducePaddingTop) {
      return css`
        padding-top: 24px;
      `;
    }

    return css`
      padding-top: 8px;
    `;
  }};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 63vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const FooterWrapper = styled.div<{ $isOverflow: boolean }>`
  width: 100%;
  ${({ $isOverflow }) =>
    $isOverflow &&
    css`
      background: #ffffff;
      padding-top: 16px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    `}
`;

const Attribute = styled.div<{ marginBottom?: string; width?: string; marginRight?: string }>`
  flex: 1 1 ${({ width }) => width || '40%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};
`;

const CopyButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const CopyIcon = styled(Copy)`
  margin: 0 4px -2px;
`;

const StyledOperationForm = {
  CopyIcon,
  CopyButtonWrapper,
  Wrapper,
  Attribute,
  ContentWrapper,
  FooterWrapper,
};

export default StyledOperationForm;
