import { FC } from 'react';
import { Typography, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { useDataTestIdV2 } from '../../../../../../../../../../../common/utils/hooks/locators';
import { ICreateAgrochemicalModalPayload } from '../CreateAgrochemicalModal/CreateAgrochemicalModal';

import { ReactComponent as Counter } from './assets/icons/counter.svg';
import Styled from './CreateAhoModalTitle.styles';

const CreateAhoModalTitle: FC = () => {
  const location = useLocation();
  const isFromZones: boolean = location.pathname.includes('zones');

  const modal = useModal();
  const modalPayload = modal.getModalPayload() as ICreateAgrochemicalModalPayload;

  const isNeedToShowCounter: boolean = isFromZones && !modalPayload?.isBlocked;

  const getDataTestId = useDataTestIdV2('create-aho__title');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Typography dataTestId={getDataTestId()['data-test-id']} variant={'h5'}>
        Параметры почвы
      </Typography>

      {isNeedToShowCounter ? <Counter {...getDataTestId('counter')} /> : null}
    </Styled.Wrapper>
  );
};

CreateAhoModalTitle.displayName = 'CreateAhoModalTitle';

export default observer(CreateAhoModalTitle);
