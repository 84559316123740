import styled from 'styled-components';

const Wrapper = styled.svg`
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  & circle {
    fill: #151f32;
  }

  &:hover {
    transition: all 0.15s ease-in-out;

    & circle {
      fill: #3fb65f;
    }
  }
`;

const StyledToolsPlusIcon = {
  Wrapper,
};

export default StyledToolsPlusIcon;
