import React, { FC, memo } from 'react';

import { TDropdownFactoryBody as TBodyDefault } from '../../../../types';
import { useDropdownFactoryDataTestId as useDataTestId } from '../../../../hooks';
import { useDropdownFactoryContext as useContext } from '../../../../context';
import { DropdownFactoryOption as Option } from '../../../DropdownFactoryOption';

import Styled from './DropdownFactoryBodyDefault.styles';

const DropdownFactoryBodyDefault: FC<TBodyDefault> = ({ styles }) => {
  const { optionList, onChange } = useContext();

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper {...getDataTestId('body-default')} $styles={styles}>
      {optionList.map(option => (
        <div
          {...getDataTestId('body-default__option-wrapper')}
          key={option.value}
          onClick={() => onChange?.(option.value, option)}
        >
          <Option {...option} />
        </div>
      ))}
    </Styled.Wrapper>
  );
};

DropdownFactoryBodyDefault.displayName = 'DropdownFactoryBodyDefault';

export default memo(DropdownFactoryBodyDefault);
