import React, { FC, memo } from 'react';

import { TDropdownFactoryIconCustom as TIconCustom } from '../../../../types';
import { useDropdownFactoryDataTestId as useDataTestId } from '../../../../hooks';

const DropdownFactoryIconCustom: FC<TIconCustom> = ({ render }) => {
  const getDataTestId = useDataTestId();

  return <>{render({ dataTestId: getDataTestId('icon-custom')['data-test-id'] })}</>;
};

DropdownFactoryIconCustom.displayName = 'DropdownFactoryIconCustom';

export default memo(DropdownFactoryIconCustom);
