import _, { merge, sortBy, uniqBy } from 'lodash';

import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesStore } from '../../stores';
import { ZonesListConfigService, ZonesService } from '../../services';
import {
  TGetCultureZoneExperimentListReq,
  TGetFieldReq,
} from '../../../../../../../../../../../../../api';
import {
  IChangeCultureZoneName,
  IChangeCultureZoneStyle,
  IDrawCultureZoneList,
} from '../../../../../../../../../../../../common/features/experimentsMap/hooks/useMap/useMap';
import { IShowErrorModal } from '../../../../../../../../../../../../common/utils/hooks/useErrorModal';
import { IChangeCultureZoneForm } from '../../../config/forms/changeCultureZoneName/changeCultureZoneName.form.config';
import {
  EExperimentCultureZoneType,
  EExpZoneStyle,
  IChangeExperimentCultureZone,
  IExperimentCultureZone,
  IFWExperimentCultureZone,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { ICultureZone } from '../../../../../../../../../../../../../api/models/as-fields/fields/CultureZone';
import { IAddPreviousCultureForm } from '../../../config/forms/addPreviousCulture/addPreviousCulture';
import { DictionaryService } from '../../../../../../../../../../../../common/mobx/services/da-dictionary';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { createCultureSelectOptionList } from '../../../../../../../../../../../../common/utils/helpers/selectOptions';
import { IField } from '../../../../../../../../../../../../../api/models/as-fields/fields/Field.model';
import { FieldsService } from '../../../../../../../../../../../../common/mobx/services/as-fields';
import CultureZoneExperimentService from '../../../../../../../../../../../../common/mobx/services/as-fields/CultureZoneExperiment/CultureZoneExperiment.service';
import { CreateExperimentStore } from '../../../../../../../mobx/stores';
import { TableBuilderController } from '../../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { createExpZoneName } from '../../../components/ZonesList/utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { IZonesAddFactZoneForm } from '../../../config/forms';
import { EExperimentStatus } from '../../../../../../../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
class ZonesController {
  @lazyInject(CreateExperimentStore)
  protected createExperimentStore: CreateExperimentStore;

  @lazyInject(ZonesStore)
  protected zonesStore: ZonesStore;

  @lazyInject(ZonesService)
  protected zonesService: ZonesService;

  @lazyInject(CultureZoneExperimentService)
  protected cultureZoneExperimentService: CultureZoneExperimentService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(FieldsService)
  protected fieldsService: FieldsService;

  @lazyInject(CultureZoneExperimentService)
  protected cultureZoneExpService: CultureZoneExperimentService;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  @lazyInject(ZonesListConfigService)
  protected zonesListConfigService: ZonesListConfigService;

  public updateAhoOfFwZoneByNewRequest = async (
    expZoneId: string,
    isFact: boolean
  ): Promise<void> => {
    const zone = await this.zonesService.getExpCultureZone(expZoneId);

    if (!zone) return;

    const fwZone = this.zonesStore.fwExpCultureZoneList.find(
      ({ expZone }) => expZone.id === expZoneId
    );

    if (!isFact) {
      this.zonesStore.setTableHasChanged(true);
    } else {
      this.zonesStore.clearTableHasChanged();
      this.zonesStore.clearIdToUnchangedFwExpCultureZone();
    }

    this.zonesStore.setFwExpCultureZone({
      ...fwZone,
      expZone: zone,
    });
  };

  fetchField = async (payload: TGetFieldReq): Promise<IField> => {
    if (!payload?.id) {
      return;
    }

    const field = await this.fieldsService.getField(payload);

    return field;
  };

  culturesSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { getDictionaryEntityList } = this.dictionaryService;
    const {
      setCultureList,
      setPreviousCultureCurrentPage,
      setPreviousCultureTotalPages,
      setPreviousCultureOptionList,
      setPreviousCultureSearchQuery,
      previousCultureOptionList,
      previousCultureCurrentPage,
    } = this.zonesStore;

    const { content, totalPages } = await getDictionaryEntityList(
      {
        remoteName: 'culture',
        attrs: { useInAssistance: true },
        latestVersion: true,
        nameFilter: searchQuery,
      },
      { size: 20 }
    );

    const noSearchQuery = !searchQuery || searchQuery === '';

    if (_.isArray(content)) {
      setCultureList(content);

      /**
       * Если запрашиваем культуры без query и при срабатывании query на onChange,
       * то склеиваем с предыдущими культурами, чтобы при рейсинге не потерять id
       */
      const cultureSelectOptionList = noSearchQuery
        ? sortBy(
            uniqBy(
              merge(createCultureSelectOptionList(content), previousCultureOptionList),
              'value'
            ),
            'label'
          )
        : createCultureSelectOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setPreviousCultureCurrentPage(noSearchQuery ? previousCultureCurrentPage : 0);

      setPreviousCultureTotalPages(totalPages);

      setPreviousCultureOptionList(cultureSelectOptionList);

      setPreviousCultureSearchQuery(searchQuery);

      return cultureSelectOptionList;
    }
  };

  createFwExpZoneFromCZ = (zone: ICultureZone): IFWExperimentCultureZone => {
    const cultureList = zone.predecessorList.map(p => p.culture);
    const prevCultureSelectOptionList = createCultureSelectOptionList(cultureList);

    const fwExpZone: IFWExperimentCultureZone = {
      cultureZoneId: zone.id,
      name: zone?.name || 'Безымянный',
      variety: '—',
      polyId: null,
      cultureZone: zone,
      prevCultureSelectOptionList,
      type: zone?.experimentReady ? EExperimentCultureZoneType.Experiment : null,
      styleType: zone?.experimentReady ? EExpZoneStyle.ReadyToExperiment : EExpZoneStyle.Other,
      isSelected: false,
    };

    return fwExpZone;
  };

  createFwExpZoneFromExpZone = (expZone: IExperimentCultureZone): IFWExperimentCultureZone => {
    const prevCultureSelectOptionList = expZone.previousCultures.length
      ? createCultureSelectOptionList(expZone.previousCultures)
      : createCultureSelectOptionList(expZone.cultureZone.predecessorList.map(p => p.culture));

    const fwExpZone: IFWExperimentCultureZone = {
      cultureZoneId: expZone.cultureZone.id,
      name: expZone.cultureZone?.name || 'Безымянный',
      variety: expZone?.name ?? '—',
      polyId: null,
      cultureZone: expZone.cultureZone,
      expZone,
      prevCultureSelectOptionList,
      type: expZone.type,
      styleType:
        expZone.type === EExperimentCultureZoneType.Control
          ? EExpZoneStyle.Control
          : EExpZoneStyle.Experiment,
      isSelected: true,
    };

    const hasFactZone: boolean = expZone && expZone.cultureZone.id !== expZone.cultureZoneFact.id;

    if (hasFactZone) {
      fwExpZone.factFwZone = {
        ...this.createFwExpZoneFromCZ(expZone.cultureZoneFact),
        isSelected: true,
        type: expZone.type,
        styleType:
          expZone.type === EExperimentCultureZoneType.Experiment
            ? EExpZoneStyle.Experiment
            : EExpZoneStyle.Control,
        variety: fwExpZone.variety,
        prevCultureSelectOptionList: createCultureSelectOptionList(
          expZone?.previousCulturesFact ?? []
        ),
      };
    }

    return fwExpZone;
  };

  allowCultureZonesDrawing = (drawCultureZoneList: IDrawCultureZoneList): void => {
    const {
      fwExpCultureZoneListWithoutPolyId,
      setIdToFwExpCultureZone,
      setIdToUnchangedFwExpCultureZone,
    } = this.zonesStore;

    const zoneListWithPolyId = drawCultureZoneList(fwExpCultureZoneListWithoutPolyId);

    /**
     * Сортирует участки, чтобы отображать сначала активные
     */
    const zoneListSortedByIsSelected = zoneListWithPolyId.sort(
      (zone1, zone2) => Number(zone2.isSelected) - Number(zone1.isSelected)
    );

    setIdToFwExpCultureZone(zoneListSortedByIsSelected);
    setIdToUnchangedFwExpCultureZone(zoneListSortedByIsSelected);
  };

  fetchCultureZoneList = async (payload: TGetCultureZoneExperimentListReq): Promise<void> => {
    const { setIsAllowMapAccess, setIdToFwExpCultureZoneWithoutPolyId } = this.zonesStore;
    const { getAllCultureZoneList } = this.zonesService;
    const { selectedExp } = this.createExperimentStore;

    if (!selectedExp) {
      return;
    }

    const { organization, seasonYear, culture } = selectedExp;

    const hasDraftStatus: boolean = selectedExp?.status === EExperimentStatus.Draft;

    const [fetchedExpZoneList, fetchedCultureZoneList] = await getAllCultureZoneList(payload, {
      organizationId: organization.id,
      seasonYear,
      withGeometry: true,
      size: 2000,
      cultureId: culture.id,
      onlyWithoutExperiment: true,
      experimentReady: true,
    });

    if (_.isArray(fetchedExpZoneList) && _.isArray(fetchedCultureZoneList)) {
      const idToFwCultureZone: Map<string, IFWExperimentCultureZone> = new Map<
        string,
        IFWExperimentCultureZone
      >();

      /**
       * Если статус не "черновик", смысла отображать и показывать обычные участки нет.
       */
      if (hasDraftStatus) {
        fetchedCultureZoneList.forEach(zone => {
          const fwExpZone = this.createFwExpZoneFromCZ(zone);

          idToFwCultureZone.set(fwExpZone.cultureZoneId, fwExpZone);
        });
      }

      fetchedExpZoneList.forEach(expZone => {
        const fwExpZone = this.createFwExpZoneFromExpZone(expZone);

        idToFwCultureZone.set(fwExpZone.cultureZoneId, fwExpZone);
      });

      const fwCultureZoneList = Array.from(idToFwCultureZone.values());
      const hasZonesToWork = fwCultureZoneList.some(zone => zone.type);

      if (hasZonesToWork) {
        setIdToFwExpCultureZoneWithoutPolyId(fwCultureZoneList);
        setIsAllowMapAccess(true);
      }
    }

    const config = this.zonesListConfigService.createConfig();
    this.tableBuilderController.initiateTable(config);
  };

  changeZoneType = (
    fwZone: IFWExperimentCultureZone,
    changeCultureZoneStyle: IChangeCultureZoneStyle
  ): void => {
    const { setFwExpCultureZone } = this.zonesStore;

    setFwExpCultureZone(fwZone);

    changeCultureZoneStyle(fwZone);
  };

  saveExpZoneList = async (showErrorHandler?: IShowErrorModal): Promise<boolean> => {
    const { selectedExp } = this.createExperimentStore;
    const { fwExpCultureZoneList, clearIdToUnchangedFwExpCultureZone } = this.zonesStore;
    const { changeCultureZoneExperimentList } = this.cultureZoneExpService;

    clearIdToUnchangedFwExpCultureZone();

    const payload = fwExpCultureZoneList.reduce<IChangeExperimentCultureZone[]>(
      (zoneList, fwZone) => {
        if (fwZone.type && fwZone.isSelected) {
          const previousCultureIds =
            fwZone.prevCultureSelectOptionList.length > 0
              ? fwZone.prevCultureSelectOptionList.map<string>(option => option.value as string)
              : fwZone.predecessorId
              ? [fwZone.predecessorId]
              : null;

          const zone: IChangeExperimentCultureZone = {
            experimentId: selectedExp.id,
            type: fwZone.type,
            cultureZoneId: fwZone.cultureZoneId,
            cultureZoneName: fwZone.name,
            previousCultureIds,
          };

          zoneList.push(zone);
        }

        return zoneList;
      },
      []
    );

    const savedZoneList = await changeCultureZoneExperimentList(
      selectedExp.id,
      { zoneList: payload },
      showErrorHandler
    )
      .then(() => {
        this.fetchCultureZoneList({ experimentId: selectedExp?.id });
      })
      .catch(e => {
        throw e;
      });

    if (_.isArray(savedZoneList)) {
      return true;
    }
  };

  changeCultureZoneName = async (
    formData: IChangeCultureZoneForm,
    closeModal: () => void,
    showErrorHandler: IShowErrorModal,
    changeCultureZoneNameHandler: IChangeCultureZoneName
  ): Promise<void> => {
    const { selectedExp } = this.createExperimentStore;
    const { editableZoneId, getZone, setFwExpCultureZone, clearEditableZoneId } = this.zonesStore;
    const { changeCultureZone } = this.zonesService;

    const zone = getZone(editableZoneId);

    const changedName = formData.name.trim();

    const isSuccess = await changeCultureZone(
      {
        id: zone.cultureZoneId,
        seasonYear: selectedExp.seasonYear,
        name: changedName,
      },
      showErrorHandler
    );

    if (isSuccess) {
      setFwExpCultureZone({
        ...zone,
        name: formData.name,
        cultureZone: { ...zone.cultureZone, name: changedName },
      });

      changeCultureZoneNameHandler?.(zone.polyId, changedName);

      clearEditableZoneId();
      closeModal?.();
    }
  };

  addPreviousCulture = (
    { previousCultureId }: IAddPreviousCultureForm,
    closeModal: () => void
  ): void => {
    const {
      editableZoneId,
      previousCultureOptionList,
      getZone,
      setFwExpCultureZone,
      clearEditableZoneId,
    } = this.zonesStore;

    const zone = getZone(editableZoneId);

    const previousCulture = previousCultureOptionList.find(
      ({ value }) => value === previousCultureId
    );

    setFwExpCultureZone({
      ...zone,
      prevCultureSelectOptionList: previousCultureId ? [previousCulture] : [],
    });

    clearEditableZoneId();
    closeModal?.();
  };

  updateZoneInfo = (
    { previousCulture, name, type, isSelected, predecessorId },
    closeModal: () => void,
    isNew?: boolean
  ): void => {
    const { editableZoneId, getZone, setFwExpCultureZone, clearEditableZoneId } = this.zonesStore;

    const zone = getZone(editableZoneId);

    setFwExpCultureZone({
      ...zone,
      prevCultureSelectOptionList: previousCulture ? [previousCulture] : [],
      predecessorId,
      name,
      type,
      isSelected,
      ...(isNew && { variety: name }),
      expZone: zone?.expZone
        ? { ...zone.expZone, cultureZone: { ...zone.expZone.cultureZone, name } }
        : null,
    });

    clearEditableZoneId();
    closeModal?.();
  };

  clearStore = (): void => {
    const { clearStore } = this.zonesStore;

    clearStore();
  };

  changePreviousCulturePageNumber = (): void => {
    const { previousCultureCurrentPage, setPreviousCultureCurrentPage } = this.zonesStore;

    setPreviousCultureCurrentPage(previousCultureCurrentPage + 1);
  };

  onPreviousCultureListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { previousCultureCurrentPage } = this.zonesStore;
    const { getDictionaryEntityList } = this.dictionaryService;

    const { content } = await getDictionaryEntityList(
      {
        remoteName: 'culture',
        attrs: { useInAssistance: true },
        latestVersion: true,
        nameFilter: searchQuery,
      },
      { size: 20, page: previousCultureCurrentPage }
    );

    const cultureSelectOptionList = createCultureSelectOptionList(content);

    return cultureSelectOptionList;
  };

  public getFactZoneOptionList = async (): Promise<ISelectOption<ICultureZone>[]> => {
    const { organization, seasonYear, culture } = this.createExperimentStore.selectedExp;

    const res = await this.zonesService.getCultureZoneList({
      organizationId: organization.id,
      seasonYear,
      withGeometry: true,
      size: 2000,
      cultureId: culture.id,
      onlyWithoutExperiment: true,
      experimentReady: true,
    });

    if (!res) return [];

    const optionList = res.map<ISelectOption<ICultureZone>>(zone => ({
      value: zone.id,
      label: createExpZoneName(zone),
      initialModel: zone,
    }));

    return optionList;
  };

  public saveFactZone = async (
    expZoneId: string,
    formData: IZonesAddFactZoneForm,
    successAction: (fwZone: IFWExperimentCultureZone) => number
  ): Promise<void> => {
    const updatedZoneList = await this.cultureZoneExperimentService.changeFactCultureZoneList(
      this.createExperimentStore.selectedExp.id,
      {
        zoneList: [
          {
            cultureZoneExperimentId: expZoneId,
            cultureZoneId: formData.cultureZoneId,
            cultureZoneName: formData.cultureZoneName,
            previousCultureIds: [formData.previousCultureIds],
          },
        ],
      }
    );

    if (!updatedZoneList) return;

    const parentUpdatedZone = updatedZoneList.find(({ id }) => id === expZoneId);

    const factFwZone: IFWExperimentCultureZone = {
      ...this.createFwExpZoneFromCZ(parentUpdatedZone.cultureZoneFact),
      isSelected: true,
      type: parentUpdatedZone.type,
      styleType:
        parentUpdatedZone.type === EExperimentCultureZoneType.Experiment
          ? EExpZoneStyle.Experiment
          : EExpZoneStyle.Control,
      variety: parentUpdatedZone.name ?? '—',
      prevCultureSelectOptionList: createCultureSelectOptionList(
        parentUpdatedZone.previousCulturesFact ?? []
      ),
    };

    const polyId = successAction(factFwZone);

    const parentFwZone = this.zonesStore.fwExpCultureZoneList.find(
      ({ expZone }) => expZone.id === expZoneId
    );

    this.zonesStore.setFwExpCultureZone({
      ...parentFwZone,
      factFwZone: { ...factFwZone, polyId },
      expZone: {
        ...parentFwZone.expZone,
        ahoFact: parentUpdatedZone.ahoFact,
        previousCulturesFact: parentUpdatedZone.previousCulturesFact,
      },
    });

    this.zonesStore.clearTableHasChanged();
    this.zonesStore.clearIdToUnchangedFwExpCultureZone();
  };

  public disableFactZone = async (expZoneId: string, successAction: () => void): Promise<void> => {
    const updatedZoneList = await this.cultureZoneExperimentService.changeFactCultureZoneList(
      this.createExperimentStore.selectedExp.id,
      {
        zoneList: [
          {
            cultureZoneExperimentId: expZoneId,
            cultureZoneId: null,
            cultureZoneName: '',
            previousCultureIds: [],
          },
        ],
      }
    );

    if (!updatedZoneList) return;

    successAction();

    const parentFwZone = this.zonesStore.fwExpCultureZoneList.find(
      ({ expZone }) => expZone.id === expZoneId
    );

    this.zonesStore.setFwExpCultureZone({ ...parentFwZone, factFwZone: null });

    this.zonesStore.clearTableHasChanged();
    this.zonesStore.clearIdToUnchangedFwExpCultureZone();
  };
}

export default ZonesController;
