import styled, { css } from 'styled-components';

const Wrapper = styled.svg<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return css`
        transition: all 0.15s ease-in-out;

        & rect {
          fill: #f3f4f8;
        }

        & path {
          fill: #d3d7df;
        }
      `;
    }

    return css`
      cursor: pointer;

      transition: all 0.15s ease-in-out;

      & rect {
        fill: #f3f4f8;
      }

      & path {
        fill: #151f32;
      }

      &:hover {
        transition: all 0.15s ease-in-out;

        & rect {
          fill: #e9ecf0;
        }

        & path {
          fill: #151f32;
        }
      }
    `;
  }}
`;

const StyledToolsPlusIcon = {
  Wrapper,
};

export default StyledToolsPlusIcon;
