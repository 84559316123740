import styled from 'styled-components';

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDropdownFactory = {
  FieldWrapper,
};

export default StyledDropdownFactory;
