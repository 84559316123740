import { FC } from 'react';
import { Typography } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../common/utils/hooks/locators';

import { ReactComponent as Counter } from './assets/icons/counter.svg';
import Styled from './ZonesCopyAhoModalTitle.styles';

const ZonesCopyAhoModalTitle: FC = () => {
  const getDataTestId = useDataTestIdV2('zones__copy-aho-modal__title');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Typography dataTestId={getDataTestId()['data-test-id']} variant={'h5'}>
        Скопировать параметры почвы
      </Typography>

      <Counter {...getDataTestId('counter')} />
    </Styled.Wrapper>
  );
};

ZonesCopyAhoModalTitle.displayName = 'ZonesCopyAhoModalTitle';

export default ZonesCopyAhoModalTitle;
