import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import {
  ZonesAddFactZoneModalBody,
  ZonesAddFactZoneModalDescription,
  ZonesAddFactZoneModalTitle,
} from '../ZonesAddFactZoneModal/components';

const ZonesEditFactZoneModalConfig: TModalConfig = {
  name: 'zones__edit-fact-zone-modal',
  type: EModalType.Custom,
  title: <ZonesAddFactZoneModalTitle mode={'edit'} />,
  description: {
    value: () => <ZonesAddFactZoneModalDescription />,
  },
  styles: {
    title: {
      $size: 'large',
      $margin: '0 auto 6px 0',
    },
  },
  styledProps: {
    $size: '600px',
    $height: 'none',
    $fullHeight: true,
    $modalPaddings: '40px 60px',
  },
  id: 'zones__edit-fact-zone-modal',
  children: () => <ZonesAddFactZoneModalBody mode={'edit'} />,
};

export default ZonesEditFactZoneModalConfig;
