import { FC } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, useModal } from '@farmlink/farmik-ui';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../common/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CalculationStore } from '../../../../../../../calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import { ToolsAddIcon } from '../../../../../../../../../../../../../common/components/ui/icons/tools';
import { IZonesAddFactZoneModalPayload } from '../../../../config/modals/ZonesAddFactZoneModal/components/ZonesAddFactZoneModalBody/ZonesAddFactZoneModalBody';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';

import Styled from './ZoneListNonDraftStatusActionsCell.styles';
const ENABLED_STATUS_LIST = [
  EExperimentStatus.InProgress,
  EExperimentStatus.Approved,
  EExperimentStatus.InApproval,
];

interface IProps {
  rowData?: ICultureZoneCell;
}

const ZoneListNonDraftStatusActionsCell: FC<IProps> = ({ rowData }) => {
  const createExpStore = useStore(CreateExperimentStore);
  const calculationStore = useStore(CalculationStore);

  const modal = useModal();

  const handleAddIconClick = () => {
    calculationStore.setActiveCultureZone(rowData.fwExpCultureZone.expZone);

    const payload: IZonesAddFactZoneModalPayload = {
      formattedParenZoneName: rowData.name,
      parentFwZone: rowData.fwExpCultureZone,
    };

    modal.openModalByModalId('zones__add-fact-zone-modal', payload);
  };

  const checkIfAddButtonIsDisabled = (): boolean => {
    if (rowData?.fwExpCultureZone?.factFwZone) return true;

    return !ENABLED_STATUS_LIST.includes(createExpStore.selectedExp.status);
  };

  const getDataTestId = useDataTestIdV2('zone-list__non-draft-status__actions-cell');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <AutoTooltip
        dataTestId={getDataTestId('tooltip')['data-test-id']}
        disabled={checkIfAddButtonIsDisabled()}
        content={'Подключить фактический участок'}
      >
        <div>
          <ToolsAddIcon
            dataTestId={getDataTestId()['data-test-id']}
            onClick={handleAddIconClick}
            isDisabled={checkIfAddButtonIsDisabled()}
          />
        </div>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

ZoneListNonDraftStatusActionsCell.displayName = 'ZoneListNonDraftStatusActionsCell';

export default observer(ZoneListNonDraftStatusActionsCell);
