import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
  ExecutionContainerHeader as ContainerHeader,
  ExecutionContentLoader,
} from '../../components';
import ComparisonTableBuilder from '../../../../../../../../../common/features/ComparisonTableBuilder/ComparisonTableBuilder';
import { createExecutionTableBuilderId as createTableBuilderId } from '../../helpers';
import { Container } from '../../../../../../../../../common/features/UI';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionStore } from '../../mobx/stores';
import { EComparisonTableName as ETableName } from '../../../../../../../../constants/features';
import { ExecutionController } from '../../mobx/controllers';
import { useSelectedExp } from '../../../../hooks';
import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';

import Styled from './ExecutionCalculations.styles';
import { ExecutionCalculationsCopyButton } from './components';

const ExecutionCalculations: FC = () => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });

  const isInactiveExp =
    selectedExp?.status === EExperimentStatus.Canceled ||
    selectedExp?.status === EExperimentStatus.Completed ||
    selectedExp?.status === EExperimentStatus.Approved ||
    selectedExp?.status === EExperimentStatus.FactAppruved ||
    selectedExp?.status === EExperimentStatus.FullCompleted;

  const isDisplayCopyButton = !isInactiveExp && executionStore.hasCompletedAuditOptionList;

  useEffect(() => {
    if (!selectedExp?.id || !executionStore?.selectedZoneId) return;

    executionController.fetchExperimentSteps(selectedExp.id, executionStore.selectedZoneId);
  }, [selectedExp?.id, executionStore?.selectedZoneId]);

  return (
    <Styled.Wrapper>
      <Container
        headerChildren={
          <ContainerHeader
            title={'Вводные расчёты по текущему участку'}
            descriptionAction={isDisplayCopyButton ? <ExecutionCalculationsCopyButton /> : null}
          />
        }
      >
        {executionStore.isPageLoading ? (
          <ExecutionContentLoader />
        ) : (
          <ComparisonTableBuilder
            builderId={createTableBuilderId(
              executionStore.selectedZoneId,
              ETableName.ExecutionCalculations
            )}
            tableName={ETableName.ExecutionCalculations}
          />
        )}
      </Container>
    </Styled.Wrapper>
  );
};

ExecutionCalculations.displayName = 'ExecutionCalculations';

export default observer(ExecutionCalculations);
