import React, { MouseEvent, forwardRef, memo, ReactNode } from 'react';

import { ILinearDropdownOption, ILinearDropdownProps } from '../../LineralDropdown.types';
import { CustomArrow } from '../CustomArrow';

import Styled from './LinearDropdownField.styles';

interface ILinearDropdownFieldProps {
  option: ILinearDropdownOption | null;
  isDropped: boolean;
  onClick: (event: MouseEvent) => void;
  styles?: ILinearDropdownProps['styles']['dropdownField'];
  renderField?: () => ReactNode;
}

const LinearDropdownField = forwardRef<HTMLDivElement, ILinearDropdownFieldProps>(
  ({ option, isDropped, onClick, styles, renderField }, ref) => {
    const isCustomLabel = typeof option?.label === 'object';

    return (
      <Styled.Wrapper $justifyContent={styles?.justifyContent} onClick={onClick} ref={ref}>
        {renderField ? (
          renderField()
        ) : isCustomLabel ? (
          <option.label isDropped={isDropped} isSelected>
            <CustomArrow $isDropped={isDropped} />
          </option.label>
        ) : (
          <>
            {option?.icon ? <Styled.Icon $url={option?.icon} /> : null}
            <Styled.Label>{option?.label as React.ReactNode}</Styled.Label>
            <Styled.Arrow $isDropped={isDropped} />
          </>
        )}
      </Styled.Wrapper>
    );
  }
);

LinearDropdownField.displayName = 'LinearDropdownField';

export default memo(LinearDropdownField);
