import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Button = styled.button`
  width: 32px;
  height: 32px;

  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;

  background-color: ${Colors.technicalBgGrey};

  transition: all 0.15s ease-in-out;

  &:hover {
    background-color: #e9ecf0;
  }
`;

const StyledExecutionCalculationsCopyButton = {
  Button,
};

export default StyledExecutionCalculationsCopyButton;
