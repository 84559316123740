import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { ExecutionCalculationCopyForm } from './components';

export const executionCalculationCopyConfig: TModalConfig = {
  id: `executionCalculationCopy`,
  styles: {
    title: {
      $size: 'large',
      $margin: '0 auto 6px 0',
      $alignTitle: 'left',
    },
  },
  styledProps: {
    $height: 'none',
    $size: '600px',

    $modalPaddings: '40px 60px',
  },
  className: 'executionCalculationCopy',
  type: EModalType.Custom,
  children: () => <ExecutionCalculationCopyForm />,
  name: 'executionCalculationCopy',
  title: 'Перенести данные из\nнаблюдения',
  closeOnClickOutside: true,
};
