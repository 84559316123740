import React, { FC, MouseEvent, memo, useCallback } from 'react';

import { ILinearDropdownOption, ILinearDropdownProps } from '../../../../LineralDropdown.types';

import Styled from './LinearDropdownOption.styles';

interface ILinearDropdownOptionProps {
  option: ILinearDropdownOption;
  onOptionClick: ILinearDropdownProps['onChange'];
}

const LinearDropdownOption: FC<ILinearDropdownOptionProps> = ({ option, onOptionClick }) => {
  const isCustomLabel = typeof option.label === 'object';

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onOptionClick?.(option.value, option);
  }, []);

  return (
    <Styled.Wrapper onClick={handleClick} $isDisabled={option?.isDisabled}>
      {isCustomLabel ? (
        <option.label />
      ) : (
        <>
          {option?.renderIcon ? (
            option.renderIcon()
          ) : (
            <>
              <Styled.Title>{option.label as React.ReactNode}</Styled.Title>

              {option?.icon ? <Styled.Icon $url={option?.icon} /> : null}
            </>
          )}
        </>
      )}
    </Styled.Wrapper>
  );
};

LinearDropdownOption.displayName = 'LinearDropdownOption';

export default memo(LinearDropdownOption);
