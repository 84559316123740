import styled, { css, CSSObject } from 'styled-components';

const Wrapper = styled.div<{ $styles?: CSSObject }>`
  display: flex;
  flex-direction: column;

  border-radius: 12px;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(177, 173, 173, 0.25);

  & > div:not(:last-child) {
    border-bottom: 1px solid #e6e9ee;
  }

  ${({ $styles }) => css($styles ?? {})};
`;

const StyledDropdownFactoryBodyDefault = {
  Wrapper,
};

export default StyledDropdownFactoryBodyDefault;
