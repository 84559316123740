import styled, { css } from 'styled-components';

const Wrapper = styled.svg<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return css`
        transition: all 0.15s ease-in-out;

        & rect {
          fill: #f6f7f8;
        }

        & path {
          fill: #d3d7df;
          stroke: #d3d7df;
        }
      `;
    }

    return css`
      cursor: pointer;

      transition: all 0.15s ease-in-out;

      & rect {
        fill: #ecf8ef;
      }

      & path {
        fill: #3fb65f;
        stroke: #3fb65f;
      }

      &:hover {
        transition: all 0.15s ease-in-out;

        & rect {
          fill: #e2f4e7;
        }

        & path {
          fill: #259a42;
          stroke: #259a42;
        }
      }
    `;
  }}
`;

const StyledToolsCheckedIcon = {
  Wrapper,
};

export default StyledToolsCheckedIcon;
