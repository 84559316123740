import styled, { css, CSSObject } from 'styled-components';

const Wrapper = styled.div<{ $styles: CSSObject }>`
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;

  padding: 12px 16px;

  &:hover {
    transition: all 0.3s ease-in-out;
    background-color: #f6f7f899;
  }

  ${({ $styles }) => css($styles ?? {})};
`;

const StyledDropdownFactoryOptionDefault = {
  Wrapper,
};

export default StyledDropdownFactoryOptionDefault;
