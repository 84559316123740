import styled from 'styled-components';

import { Colors } from './../../../../../../../../../common/styles/colors';

const { borderGray, secondaryDarkGray, generalDark } = Colors;

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #151f32;
  margin-bottom: 12px;
  letter-spacing: -0.5px;
  display: block;
  max-height: 56px;
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  border: 1px solid ${borderGray};
  padding: 10px 12px 10px 16px;
  border-radius: 12px;
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${generalDark};
`;

const NoDescription = styled.div`
  margin-top: 16px;
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${secondaryDarkGray};
`;

const StyledAuditRemarks = {
  Wrapper,
  TitleWrapper,
  Title,
  Description,
  NoDescription,
};

export default StyledAuditRemarks;
