import styled from 'styled-components';
import { Button as InitialButton } from '@farmlink/farmik-ui';

import { ECommonColor } from '../../../../../../../common/assets/styles/colors';

const { White } = ECommonColor;

interface IRowProps {
  $marginBottom?: string;
}
interface IRowItemProps {
  $width?: string;
  $isAppHeightLow?: boolean;
}

const Wrapper = styled.form`
  width: 912px;
  margin: 0 auto;
`;

const Row = styled.div<IRowProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1 1 40%;
  margin-bottom: ${({ $marginBottom }) => `${$marginBottom || '0'}`};
`;

const RowItem = styled.div<IRowItemProps>`
  width: ${({ $width }) => `${$width || 'unset'}`};
  input {
    border-radius: 12px;
  }
  .react-datepicker-popper {
    margin-top: 80px;
  }

  @media (max-height: 534px) {
    .react-datepicker-popper {
      margin-top: 55px;
    }
  }
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
`;

const Subtitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #151f32;
  margin: 16px 0;
  width: 100%;
`;

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #e9e6f0; ;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: ${White};
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 40px;
`;

const Button = styled(InitialButton)`
  width: 300px;
`;

const StyledCreateEditContract = {
  Wrapper,
  Row,
  RowItem,
  Title,
  Subtitle,
  Header,
  Body,
  Container,
  Button,
};

export default StyledCreateEditContract;
