import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const createZonesEditFactZoneModalConfig = (
  zoneName: string,
  successHandler: () => void
): TModalConfig => {
  return {
    id: `zones__disable-fact-zone`,
    name: `zones__disable-fact-zone`,
    title: `Вы уверены, что хотите отключить фактический участок «${zoneName}»? 
Все внесенные данные будут потеряны`,
    type: EModalType.Warning,
    dataTestId: 'zones__disable-fact-zone',
    denyButton: {
      title: 'Не отключать',
      dataTestId: 'zones__disable-fact-zone__deny-button',
    },
    successButton: {
      title: 'Отключить',
      dataTestId: 'zones__disable-fact-zone__success-button',
      color: 'secondary',
      handler: successHandler,
    },
  };
};

export default createZonesEditFactZoneModalConfig;
